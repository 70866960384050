import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../../context/currentUser/currentUser';
import { toast } from "react-toastify";
import { Link, useLocation } from 'react-router-dom';
// import ProfitBtn from "../../../images/profit-btn.png";
// import HistoryBtn from "../../../images/history-btn.png";
// import SettingBtn from "../../../images/setting-bet.png";
// import ProfileBtn from "../../../images/profile-btn.png";
// import DollarCoinBtn from "../../../images/doller-btn.png";
import { useNavigate } from 'react-router-dom';

const AccountSummary = () => {

  const navigate = useNavigate();
  // const [id , setId] = useState();
  const [data, setDatas] = useState([]);
  const [currency, setCurrency] = useState("");
  const [allData, setAllData] = useState([])
  const [totalExposure, settotalExposure] = useState(0);
  const [availBalance, setavailBalance] = useState(0);
  const [calcBalance, setcalcBalance] = useState(0);
  const [clickedUserBalance, setClickedUserBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let { state } = useLocation();

  console.log('state : ', state);

  // for password change model
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const context = useContext(currentUser);
  const { isLoggedIn, roleId, fullName, name, userStatusId, setIsLoggedIn, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages, allApiUserdata, setAllApiUserdataData } = context;


  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  }


  const changePasswordFunc = async (e) => {
    e.preventDefault();
    if (newPassword.length <= 4) {
      toast.error(`Your password is too short , atleast put more than 4 characters!!`, {
        autoClose: 1500,
        theme: "colored",
      });
      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error(`Confirm password not matched to new Password !`, {
        autoClose: 1500,
        theme: "colored",
      });
      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
      return;
    }

    try {
      let res = await fetch(`https://api.betcare.live/api/changePassword/users/${state.id}`, {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ oldPassword, newPassword })
      });
      let resData = await res.json();

      if (res.status != 200) {
        toast.error(`your password not matched! put correct password!`, {
          autoClose: 1500,
          theme: "colored",
        });
        setNewPassword("");
        setConfirmPassword("");
        setOldPassword("");
        return;
      }

      toast.success(`Your data has been saved!`, {
        autoClose: 1500,
        theme: "colored",
      });

      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
    } catch (error) {
      console.log(error);
    }
  }


  const logoutSetDataFunc = async () => {
    let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
  }


  const fetchChildData = async () => {

    setIsLoading(true)
    try {
      const res = await fetch(`https://api.betcare.live/getUsers/${state?.id}`);
      let resData = await res.json();
      console.log("user data : ", resData)
      if (resData.data.length > 0) {

        let filterUser = allApiUserdata.filter(item => resData.data.some(user => item.Id == user.Id));
        console.log('filterUser data length : ', filterUser.length)
        console.log('filterUser data : ', filterUser)

        // Calculate ResultAmountU and add firstChildBal to each user
        // filterUser = filterUser.map(user => {
        //   const childResultAmounts = fetchExpoData.data
        //     .filter(child => child.ParentId === user.Id)
        //     .map(child => child.ResultAmountU || 0);
        //   const firstChildBal = childResultAmounts.reduce((acc, amount) => acc + amount, 0);
        //   return { ...user, firstChildBal };
        // });
        // // setAllData(filterUser)

        // const startIndex = (page - 1) * pageSize;
        // const endIndex = startIndex + pageSize;
        // const currentPageData = filterUser.slice(startIndex, endIndex);

        // console.log("Add exposure of every data state array element whose Id is == to exposureArr of userId current page : ", currentPageData);
        setAllData(filterUser)
      }

    } catch (error) {
      console.error("Error in get User")
    } finally {
      setIsLoading(false)
    }
  }

  const calcTotal = () => {
    let calctotalExposure = 0;
    let avaiBalance = 0;
    let balance = 0;

    allData.forEach((item) => {
      // Assuming each item has a property named 'currentExposure'
      calctotalExposure += item.exposure != null ? Number(item.exposure) : 0;

      if (item.RoleId !== 8) {
        // Calculate available balance for RoleId other than 8
        avaiBalance += item.ResultAmountU != null ? item.ResultAmountU : 0;
        balance += item.firstChildBal != null ? item.firstChildBal : 0;
      } else {
        // Calculate available balance for RoleId 8
        avaiBalance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
          (item.UserPL != null ? item.UserPL : 0) +
          (item.exposure != null ? Number(item.exposure) : 0);

        balance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
          (item.UserPL != null ? item.UserPL : 0)
      }
    });

    // Now, totalExposure contains the sum of currentExposure of all elements
    console.log("Total Exposure ", calctotalExposure);
    console.log("AvailBalance ", avaiBalance);
    console.log("Balance ", balance);

    if (state.roleId != 8) {

      settotalExposure(calctotalExposure)
      setavailBalance(avaiBalance)
      setcalcBalance(balance)
    } else {
      settotalExposure(state.expo)
      setavailBalance(state.availBal)
      setcalcBalance(balance)
      setClickedUserBalance(state.bal)
    }



  }

  const loginBalance = async () => {
    try {
      console.log("User Id : ", state?.id)
      if (state.roleId != 8) {

        const res = await fetch(`https://api.betcare.live/myBalance/${state?.id}`)
        const resData = await res.json();

        if (resData.isSuccess) {
          console.log("login Balance : ", resData.mainBalance[0].ResultAmountP)
          // console.log('state : ', state);
          setClickedUserBalance(resData.mainBalance[0].ResultAmountP)
        }
      }

    } catch (error) {
      console.error("Error Fetching Login Balance..!")
    }
  }

  useEffect(() => {
    (async () => {
      let res = await fetch(`https://api.betcare.live/api/getUserDetail/users/${state?.id}`);
      let resData = await res.json();
      setDatas(resData);
    })()

      ; (async () => {
        let res = await fetch(`https://api.betcare.live/api/getActiveCurrency/currency`);
        let resData = await res.json();
        setCurrency(resData[0].CurrencyName);
      })()

    fetchChildData();

  }, [])



  // useEffect(() => {

  //   const fetchUserStatusCheck = async () => {
  //     let res = await fetch(`https://api.betcare.live/api/isUserIsLoggedIn/userstatus/${userStatusId}`);
  //     let resdata = await res.json();
  //     let userIsLoggedIn = resdata.message;
  //     console.log(userIsLoggedIn + " hey boyy");

  //     if (userIsLoggedIn == "false") {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('RoleId');
  //       localStorage.removeItem('UserName');
  //       localStorage.removeItem("currentDashboard");
  //       localStorage.removeItem("currentUserPages");
  //       // localStorage.removeItem("CurrentCurrency");
  //       setIsLoggedIn(false);
  //       logoutSetDataFunc();
  //       navigate("/login");
  //     }
  //   };

  //   fetchUserStatusCheck();

  // }, [])

  useEffect(() => {
    calcTotal()
    loginBalance()
  }, [allData])

  return (
    <>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <main role="main" className="pb-3">
        <div className="account-pages">
          <div className="container mxw-100">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <ul id="agentPath" className="agent_path-L mb-0">
                    <li id="path6">
                      <a href="javascript: void(0);">
                        {/* {UserRoleShortName[currentUserPages[lastDataIndex].roleId]} */}
                        <span className="lv_0">{UserRoleShortName[data.length === 0 ? ".." : data[0].RoleId]} </span>
                        <strong> {data.length === 0 ? ".." : data[0].UserName} </strong>
                        {/* currentDashboard.roleId */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 pe-0">
                <div className="match-list-inplay">
                  <div className="vertical-match-list">
                    <ul className="list-unstyled">
                      <li className="menu-title">Position</li>
                      <li id="linkActive" className="d-none">
                        <a
                          className="link"
                          href="/User/AccountStatement?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Account Statement
                        </a>
                      </li>
                      <li id="linkActive" className="active">
                        <a
                          className="link"
                        >
                          Account Summary
                        </a>
                      </li>
                      <li className="menu-title">
                        <a>Performance</a>
                      </li>
                      <li id="linkActiveBetting" className="">
                        <Link
                          className="link"
                          to="/bettinghistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting History
                        </Link>
                      </li>
                      <li id="linkActiveLoss" className="">
                        <Link
                          className="link"
                          to="/bettingprofitloss"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting Profit &amp; Loss
                        </Link>
                      </li>
                      <li id="linkActiveHistory" className="">
                        <Link
                          className="link"
                          to="/transactionhistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          {" "}
                          Transaction History
                        </Link>
                      </li>
                      <li id="Profile" className="d-none">
                        <a
                          className="link"
                          href="/user/Profile?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Profile
                        </a>
                      </li>
                      <li id="linkActiveLog" className="">
                        <Link
                          to="/activitylog"
                          className='link'
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Activity Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="account-profile">
                  <div className="myaccount-page">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagettl"> Account Summary </div>
                      </div>
                      <div className="col-lg-12">
                        <ul className="acc-info">
                          <li>
                            <img src="../../../images/profile-btn.png" />
                            <span className="mx-1">{data.length === 0 ? ".." : data[0].UserName}</span>
                          </li>
                        </ul>
                        <div className="profile-section">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Wallet</th>
                                  <th style={{ textAlign: "right" }}>
                                    Available to Bet
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    Funds available to withdraw
                                  </th>
                                  <th style={{ textAlign: "right" }}>
                                    Current Exposure
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{clickedUserBalance}</td>
                                  <td style={{ textAlign: "right" }}>{availBalance}</td>
                                  <td style={{ textAlign: "right" }}>{clickedUserBalance}</td>
                                  <td style={{ textAlign: "right" }}>{totalExposure}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="myaccount-page">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagettl"> Profile </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="profile-section">
                          <div className="profile-sec-heading"> About You </div>
                          <dl>
                            <dt>First Name</dt>
                            <dd>{data.length === 0 ? ".." : data[0].UserName}</dd>
                            <dt>Last Name</dt>
                            <dd>--</dd>
                            <dt>Birthday</dt>
                            <dd>--</dd>
                            <dt>E-mail</dt>
                            <dd>--</dd>
                            <dt>Password</dt>
                            <dd>
                              ***********{" "}
                              <a
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#editpop"
                                className="edit-pass"
                              >
                                Edit{" "}
                                <i className="fa fa-pencil" aria-hidden="true" />
                              </a>
                            </dd>
                            <dt>Time Zone</dt>
                            <dd>IST</dd>
                          </dl>
                        </div>
                        <div className="profile-section">
                          <div className="profile-sec-heading">
                            {" "}
                            Contact Details{" "}
                          </div>
                          <dl>
                            <dt>Primary Number</dt>
                            <dd>--</dd>
                            <dt>Currency</dt>
                            <dd>{currency}</dd>
                          </dl>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="profile-section">
                          <div className="profile-sec-heading">
                            {" "}
                            Limits &amp; Commission{" "}
                          </div>
                          <dl>
                            <dt>Exposure Limit</dt>
                            <dd>
                              {data.length !== 0 ? data[0].RoleId === 8 ? data[0].ExposureLimit : 0 : 0}
                              <a
                                type="button"
                                // data-bs-toggle="modal"
                                // data-bs-target="#editcommision"
                                className="edit-pass"
                              >
                                Edit{" "}
                                <i className="fa fa-pencil" aria-hidden="true" />
                              </a>
                            </dd>
                            <dt>Commission</dt>
                            <dd>0</dd>
                            <dt>Book Commission</dt>
                            <dd>0 </dd>
                            <dt>Fancy Commission</dt>
                            <dd>0 </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="editpop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="editpopLabel"
          aria-hidden="true"
          className="modal fade changepassword-model"
        >
          <div className="modal-dialog">
            <div className="ng-untouched ng-pristine ng-invalid">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 id="editpopLabel" className="modal-title">
                    Change Password
                  </h6>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setNewPassword("");
                      setConfirmPassword("");
                      setOldPassword("");
                    }}
                    className="btn-close"
                  >
                    X
                  </button>
                </div>
                <div className="modal-body">
                  <dl className="form_list">
                    <dt>New Password </dt>
                    <dd>
                      <input
                        id="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={(e) => { setNewPassword(e.target.value) }}
                        placeholder="Enter"
                        aria-autocomplete="list"
                        formcontrolname="new_password"
                        className="ng-untouched ng-pristine ng-invalid"
                      />
                      <span className="must">＊</span>
                    </dd>
                    <div
                      id="isnewPassword"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Please enter your password.
                    </div>
                    <dt>New Password Confirm </dt>
                    <dd>
                      <input
                        id="newPasswordConfirm"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                        placeholder="Enter"
                        formcontrolname="confirm_password"
                        className="ng-untouched ng-pristine ng-invalid"
                      />
                      <span className="must">＊</span>
                    </dd>
                    <div
                      id="isnewPasswordConfirm"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Please enter yourconfirm password.
                    </div>
                    <div
                      id="isPassword"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Your Password Not Matched.
                    </div>
                    <dt>Your Password </dt>
                    <dd>
                      <input
                        id="ParentPassword"
                        type="password"
                        placeholder="Enter"
                        value={oldPassword}
                        onChange={(e) => { setOldPassword(e.target.value) }}
                        formcontrolname="master_password"
                        className="ng-untouched ng-pristine ng-invalid"
                      />
                      <span className="must">＊</span>
                    </dd>
                    <div
                      id="isChange"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Please enter your password.
                    </div>
                    <dt />
                    <dd>
                      <button
                        id="changePasswordBtn"
                        type="submit"
                        onClick={(e) => changePasswordFunc(e)}
                        data-bs-dismiss="modal"
                        className="btn btn2"
                      >
                        Change
                      </button>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="../../../images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
    </>


  )
}

export default AccountSummary
