import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../context/currentUser/currentUser';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import axios from 'axios';
// import logo from "../../public/uploads/panel-logo.png";

const Header = () => {


  const locationData = useLocation();
  let LocationPath = locationData?.pathname;
  let userIP = Cookies.get('userIP');

  const [Currency, setCurrency] = useState("");
  const [mainBalance, setMainBalance] = useState(0);

  const context = useContext(currentUser);
  const { isLoggedIn, setIsLoggedIn, roleId, userId, fullName, pageLoad, userStatusId, currentUserPages, SetCurrentUserPages, currentDashboard, setCurrentDashboard, setPageLoad, currentCurrency, SetCurrentCurrency, currentBalance, SetCurrentBalance, siteNotice, setSiteNotice, allApiUserdata, setAllApiUserdataData, refetchUsers, setRefetchUsers, reloadOn, setReloadOn } = context;


  const navigate = useNavigate();

  const userIdLS = localStorage.getItem(`UserId`);
  const LoggedInRoleId = localStorage.getItem(`RoleId`);
  const dataFromLS = localStorage.getItem(`currentUserPages`);
  const dataInObj = JSON.parse(dataFromLS);
  const currentLoggedInUser = dataInObj?.[0]?.id;

  const fetchMasterData = async () => {
    let res = await fetch("https://api.betcare.live/api/getMasterData");
    let resData = await res.json();
    console.log('master global data : ', resData[0]?.SiteMessage);
    setSiteNotice(resData[0]?.SiteMessage)
    // // setting data
    // setWhatsAppLink1(resData[0].Whatsapp1Link);
    // setWhatsAppLink2(resData[0].Whatsapp2Link);
    // setTelegramLink1(resData[0].Telegram1Link);
    // setTelegramLink2(resData[0].Telegram2Link);
    // setStartTime(resData[0].BetStartTime);
    // setNoticeMessage(resData[0].SiteMessage);
  }

  //console.log(dataInObj + "curenttesting");


  console.log('siteNotice data : ', siteNotice);

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  }


  // Login Balance 

  const loginBalance = async () => {
    try {
      //console.log("User Id header : ", userIdLS)
      const res = await fetch(`https://api.betcare.live/myBalance/${userIdLS}`)
      const resData = await res.json();

      if (resData.isSuccess) {

        //console.log("login Balance : ", resData.mainBalance[0].ResultAmountP)
        SetCurrentBalance(resData.mainBalance[0].ResultAmountP !== null ? resData.mainBalance[0].ResultAmountP : 0)
        setMainBalance(resData.mainBalance[0].ResultAmountP !== null ? resData.mainBalance[0].ResultAmountP : 0)
      }

    } catch (error) {
      console.error("Error Fetching Login Balance..!")
    }
  }

  const filterChild = async () => {
    try {
      setReloadOn(true);
      let fetchExpo = await fetch(`https://api.betcare.live/newcalcExposOfAllUser/${userIdLS}?status=0`)
      let fetchExpoData = await fetchExpo.json();
      console.log('fetch expo from header : ', fetchExpo)
      if (fetchExpo.ok) {
        console.log(' filterChild fetcg expo data : ', fetchExpoData.data)
        if (fetchExpoData.data.length > 0) {
          setAllApiUserdataData(fetchExpoData.data)
        }
      } else {
        setAllApiUserdataData([0])
      }
    }
    catch (error) {
      console.log('Error in Filter Child')
      setAllApiUserdataData(['error']);
    } finally {
      setReloadOn(false)
    }
  }

  ////console.log(typeof(fullName));

  const logoutSetDataFunc = async () => {
    let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
  }

  const handleLogOut = async () => {

    try {
      console.log('logout user Id : ', userIdLS)

      let res = await axios.post('https://api.betcare.live/logoutByIp', {
        UserId: userIdLS,
        UserIp: userIP
      }, {
        headers: { "Content-Type": "application/json" }
      });

    } catch (error) {
      alert("failed to logged out !!");
    }

  }

  // function to logOut user
  const logOutFunc = () => {
    Cookies.remove('userIP');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('RoleId');
    localStorage.removeItem('UserName');
    localStorage.removeItem("currentDashboard");
    localStorage.removeItem("currentUserPages");
    // localStorage.removeItem("CurrentCurrency");
    setIsLoggedIn(false);
    handleLogOut();
    navigate("/login");
  }

  // Check User Status is User logged in or not
  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const res = await fetch(`https://api.betcare.live/GetUserIdandUserStatus/${userIdLS}/${userIP}`);
        const resData = await res.json();

        if (resData.isSuccess) {
          console.log('User login status: ', resData);

          if (!resData.isLoggedIn) {
            Cookies.remove('userIP');
            logOutFunc()
          }
        }
      } catch (error) {
        console.error('Error fetching user status:', error);
      }
    };

    fetchUserStatus();
  }, [LocationPath]);



  const reSetData = () => {
    let reset = currentUserPages?.slice(0, 1);
    SetCurrentUserPages(reset);
    setCurrentDashboard({ userId: currentLoggedInUser, roleId: currentUserPages?.[0]?.roleId });
  };




  useEffect(() => {

    const fetchCurrentCurrency = async () => {
      let res = await fetch(`https://api.betcare.live/api/getActiveCurrency/currency`);

      let resData = await res.json();
      //console.log(resData[0].CurrencyName);
      setCurrency(resData[0]?.CurrencyName);
      SetCurrentCurrency(resData[0]?.CurrencyName)
    }
    fetchCurrentCurrency();


    // //console.log("enter of header " + Math.floor(Math.random()*25));
  }, [pageLoad])
  ////console.log(UserRoleShortName[1]);



  // useEffect(() => {

  //   const fetchUserStatusCheck = async () => {
  //     let res = await fetch(`https://api.betcare.live/api/isUserIsLoggedIn/userstatus/${userStatusId}`);
  //     let resdata = await res.json();
  //     let userIsLoggedIn = resdata.message;
  //     //console.log(userIsLoggedIn + " hey boyy");

  //     if (userIsLoggedIn == "false") {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('RoleId');
  //       localStorage.removeItem('UserName');
  //       localStorage.removeItem("currentDashboard");
  //       localStorage.removeItem("currentUserPages");
  //       // localStorage.removeItem("CurrentCurrency");
  //       setIsLoggedIn(false);
  //       logoutSetDataFunc();
  //       navigate("/login");
  //     }
  //   };

  //   fetchUserStatusCheck();


  // }, [])

  useEffect(() => {
    loginBalance();
    // filterChild();
    fetchMasterData();
  }, [])

  useEffect(() => {
    filterChild();
    setRefetchUsers(false);
  }, [refetchUsers])



  return (
    <div>
      <div className="main-header login-header">
        <nav className="navbar navbar-light">
          <div className="container mxw-100">
            <div className="left-logo d-flex align-items-center">
              <a className="navbar-brand" href="/">
                <img src='https://ag.betcare.live/uploads/panel-logo.png' id='headerSiteLogo' />
              </a>
            </div>
            <div className="right-side-inputs">
              <form className="d-flex desktop-header-login ng-untouched ng-pristine ng-valid">
                <ul className="header-user">
                  <li>
                    <span>{UserRoleShortName[roleId]} </span> {fullName}
                  </li>
                  <li>
                    <span>Main</span> {currentCurrency} {mainBalance}
                  </li>
                </ul>
                <ul className="amout-dtls bg-black1">
                  <li>
                    <a href="javascript:void(0);" className="load-btn">
                      <i className="fa fa-history" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </nav>
        <nav>
          <div className="navmenu">
            <div className="container mxw-100">
              <div className="d-flex justify-content-between">
                <ul className="nav nav-pills flex-nowrap">
                  <li className="nav-item">
                    {/* <Link onClick={reSetData} to={"/"}> */}
                    <a className="nav-link active" href='/' onClick={reSetData}>
                      Downline List
                    </a>
                    {/* </Link> */}
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/accountsummary"}
                      state={currentDashboard !== null && { id: currentUserPages?.[0].id }}
                    >
                      My Account
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a role="button" className="nav-link dropdown-toggle">
                      {" "}
                      My Report{" "}
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to={"/Report/profitLossReportByDownline"}
                        >
                          Profit/Loss Report by Downline
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={"/Report/profitLossReportByMarket"}
                        >
                          Profit/Loss Report by Market
                        </Link>
                      </li>
                      <li>
                        <a className="dropdown-item"
                        // href="/Report/CasinoReport"
                        >
                          Casino Report
                        </a>
                      </li>
                      <li className="ng-star-inserted">
                        <a className="dropdown-item"
                        // href="/Report/GGRReport"
                        >
                          GGR Report
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                        // href="/Report/CasinoProfitLossReport"
                        >
                          Casino Profit/Loss
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link to="/betList" className="nav-link" >
                      BetList
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/betlistlive" className="nav-link" >
                      BetListLive
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="/RiskManagement/ManagementRisk">
                      Risk Management
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to={`/banking`}>
                      Banking
                    </Link>
                  </li>
                  {LoggedInRoleId <= 2 && (
                    <>
                      <li className="nav-item dropdown ng-star-inserted">
                        <a role="button" className="nav-link dropdown-toggle">
                          {" "}
                          Setting{" "}
                        </a>
                        <ul className="dropdown-menu">
                          {/* <li>
                    <a className="dropdown-item" href="/Setting/MarketSetting">
                      Market Settings
                    </a>
                  </li> */}
                          <li>
                            {/* <a className="dropdown-item" href="/Setting/MatchSetting"> */}
                            <Link className='dropdown-item' to="/matchSetting">
                              Match Settings
                            </Link>
                            {/* </a> */}
                          </li>
                          <li>
                            {/* <a className="dropdown-item" href="/Setting/MatchSetting"> */}
                            <Link className='dropdown-item' to="/seriesSetting">
                              Series Settings
                            </Link>
                            {/* </a> */}
                          </li>
                          {/* <li>
                    <a className="dropdown-item" href="/Setting/MarketRollback">
                      Market Rollback
                    </a>
                  </li> */}
                          {/* <li>
                    <a className="dropdown-item" href="/Setting/ManageSeries">
                      Manage Series
                    </a>
                  </li> */}
                          <li>
                            <Link className='dropdown-item' to="/sportSetting">
                              Sports Settings
                            </Link>
                          </li>
                          {/* <li>
                    <a className="dropdown-item" href="/Setting/SeriesSetting">
                      Series Setting
                    </a>
                  </li> */}
                          <li>
                            <Link className="dropdown-item" to="/onlineUser">
                              Online User
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/betdeletehistory">
                              Delete Bet History
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/globalSetting">
                              Global Settings
                            </Link>
                          </li>
                          {/* <li>
                          <a className="dropdown-item" href="fancy-setting.html">
                            Fancy Setting
                          </a>
                        </li> */}
                        </ul>
                      </li>
                      <li className="nav-item dropdown ng-star-inserted">
                        <a role="button" className="nav-link dropdown-toggle">
                          {" "}
                          SelfUser{" "}
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <Link className='dropdown-item' to="/selfUserList">
                              User List
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/referral">
                              Referral
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/selfUserBetHistory">
                              Bet History
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/selfUserBetListLive">
                              BetList Live
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/selfUserPL">
                              Profilt Loss
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/selfUserPLByMarket">
                              Profilt Loss By Market
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/selfUserDeletedBet">
                              Deleted Bet History
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/depositRequest">
                              Deposit Request
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/withdrawRequest">
                              Withdraw Request
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/selfUserTransactionHistory">
                              Transaction History
                            </Link>
                          </li>
                          <li>
                            <Link className='dropdown-item' to="/PaymentSetting">
                              Payment Setting
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  )}
                </ul>
                <ul className="right-setting-bet list-unstyled d-inline-flex">
                  <li>
                    <div className="btn-group setting-popup">
                      <a >
                        {/* href="javascript:void(0);" */}
                        <span className="timer-zone">Time Zone&nbsp;</span>:&nbsp;{" "}
                        <b>GMT+5:30</b>
                      </a>
                      <a style={{ cursor: "pointer" }} onClick={logOutFunc}>
                        <b>Logout</b>
                        <i className="fa fa-sign-out ml-1" aria-hidden="true" />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="download-list">
        <div className="container mxw-100">
          <div className="news-box align-items-center">
            <div className="news-title">
              <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
            </div>
            <div className="news-content">
              <marquee behavior="scroll" direction="left" scrollamount="2" scrolldelay="10" style={{ lineHeight: '2%', paddingTop: '10px' }}>
                <p style={{ color: '#6ac2ff', fontSize: '14.90px', fontWeight: 'bold', fontFamily: 'Helvetica, Tahoma, sans-serif', marginRight: '21.3333333333vw', margintTop: '5px', marginBottom: '10px' }}>{siteNotice}</p>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Header
