import React, { useContext, useEffect, useState } from 'react'
import { toast } from "react-toastify"
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import currentUser from '../../context/currentUser/currentUser';

function SelfUserBetListLive() {

    const navigate = useNavigate();

    const LoggedInRoleId = localStorage.getItem(`RoleId`);

    const [BetListSport, setBetListSport] = useState(0);
    const [BetStatus, setBetStatus] = useState(2);
    const [OrderBy, setOrderBy] = useState("t2.AmountStake");
    const [AscOrDesc, setAscOrDesc] = useState("ASC");

    const [betListData, setBetListData] = useState([]);
    const [pagesNumber, setPagesNumber] = useState([]);

    const [page, setPage] = useState(1);
    const [updatePage, setUpdatePage] = useState(false);
    const [totalNoOfBetData, setTotalNoOfBetData] = useState();
    // const pageSize = 50; 
    const [pageSize, setPageSize] = useState(20);


    const context = useContext(currentUser);
    const { userStatusId, setIsLoggedIn } = context


    const fetchBetLiveData = async () => {

        let extractedData = JSON.parse(localStorage.getItem("currentDashboard"));
        console.log(extractedData.userId);
        // console.log(LoggedInUserId);

        try {
            let res = await fetch(`https://api.betcare.live/api/admin/selfUser/getBetListLiveData/bets/1/${BetListSport}/${OrderBy}/${AscOrDesc}/${BetStatus}/?page=${page}&pageSize=${pageSize}`);
            let resData = await res.json();
            setBetListData(resData.bets);
            setTotalNoOfBetData(Math.ceil(resData.totalData / pageSize));
            console.log('resData bet List Live data : ', resData)

            // let totalFilterData = Math.ceil(resData.filterDataLength/25);
            let totalFilterData = resData.filterDataLength;

            console.log(" total filter data " + totalFilterData);

            //  if(totalFilterData < 25){
            //   setPagesNumber([1]);
            //   return;
            //  }

            let totalNumber = Math.ceil(resData.totalData / pageSize);

            const newArray = Array.from({ length: totalNumber }, (_, index) => index + 1);

            setPagesNumber(newArray);

            console.log(pagesNumber);

        } catch (err) { console.log(err); }
    }


    const funcToUpdateBetStatusToVoid = (betId) => {

        Swal.fire({
            title: "Conformation",
            text: "Are you Sure ?",
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (!result.value) return;

            const res = await fetch(`https://api.betcare.live/api/admin/selfUser/updateBetStatusToVoid/bets/${betId}`, {
                method: 'PUT'
            });

            const resData = await res.json();

            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });

            setUpdatePage((prev) => !prev)

        })
    }



    const funcToUpdateBetStatusToDelete = (betIdForDelete) => {
        Swal.fire({
            title: "Conformation",
            text: "Are you Sure ?",
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (!result.value) return;

            const res = await fetch(`https://api.betcare.live/api/updateBetStatusToDelete/bets/${betIdForDelete}`, {
                method: 'PUT'
            });

            const resData = await res.json();

            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });

            setUpdatePage((prev) => !prev)


        })
    }


    const logoutSetDataFunc = async () => {
        let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
    }


    const funcToUpdateBetStatusToRollBack = (betIdForDelete) => {
        Swal.fire({
            title: "Conformation",
            text: "Are you Sure ?",
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (!result.value) return;

            const res = await fetch(`https://api.betcare.live/api/updateBetStatusToRollback/bets/${betIdForDelete}`, {
                method: 'PUT'
            });

            const resData = await res.json();

            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });

            setUpdatePage((prev) => !prev)


        })
    }

    useEffect(() => {
        fetchBetLiveData();
    }, [BetListSport, BetStatus, OrderBy, AscOrDesc, page, updatePage, pageSize])


    useEffect(() => {
        setPage(1);
    }, [BetListSport, BetStatus, pageSize])


    return (
        <main role="main" className="pb-3">
            <div className="inner-pages">
                <div className="ng-untouched ng-pristine ng-valid">
                    <div className="container-fluid">
                        <input type="hidden" id="pageNoMatch" defaultValue={0} />
                        <input type="hidden" id="pageNoUnMatch" defaultValue={0} />
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Bet List Live </h6>
                        </div>
                        <div className="my-report">
                            <div className="time-zone-section">
                                <ul className="radio-group-list">
                                    <li>
                                        <input
                                            type="radio"
                                            id="All"
                                            name="sports"
                                            // value={BetListSport}
                                            checked={BetListSport == 0}
                                            onClick={() => { setBetListSport(0) }}
                                            formcontrolname="sports"
                                            className="ng-untouched ng-pristine ng-valid"
                                        />
                                        All
                                    </li>
                                    <li className="ng-star-inserted">
                                        <input
                                            type="radio"
                                            name="sports"
                                            // value={BetListSport}
                                            checked={BetListSport == 1}
                                            onClick={() => { setBetListSport(1) }}
                                            formcontrolname="sports"
                                            className="ng-untouched ng-pristine ng-valid"
                                        />
                                        Soccer
                                    </li>
                                    <li className="ng-star-inserted">
                                        <input
                                            type="radio"
                                            name="sports"
                                            checked={BetListSport == 2}
                                            onClick={() => { setBetListSport(2) }}
                                            formcontrolname="sports"
                                            className="ng-untouched ng-pristine ng-valid"
                                        // defaultValue={2}
                                        />
                                        Tennis
                                    </li>
                                    <li className="ng-star-inserted">
                                        <input
                                            type="radio"
                                            name="sports"
                                            checked={BetListSport == 4}
                                            onClick={() => { setBetListSport(4) }}
                                            formcontrolname="sports"
                                            className="ng-untouched ng-pristine ng-valid"
                                        // defaultValue={4}
                                        // defaultChecked=""
                                        />
                                        Cricket
                                    </li>
                                </ul>
                                <ul className="list-unstyled report-btn-group bet-list-live">
                                    <li>
                                        <label className="my-1 mr-2">Order of display:</label>
                                        <select
                                            formcontrolname="betStake"
                                            id="Displayddl"
                                            value={OrderBy}
                                            onChange={(e) => setOrderBy(e.target.value)}
                                            className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                        >
                                            <option value="t2.AmountStake"> Stake </option>
                                            <option value="t2.UserId"> Player ID </option>
                                            <option value="t2.MatchedTime"> Time </option>
                                        </select>
                                    </li>
                                    <li>
                                        <label className="my-1 mr-2">Of</label>
                                        <select
                                            formcontrolname="order"
                                            id="OrderByFilterddl"
                                            value={AscOrDesc}
                                            onChange={(e) => setAscOrDesc(e.target.value)}
                                            className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                        >
                                            <option value="ASC"> Ascending </option>
                                            <option value="DESC"> Descending </option>
                                        </select>
                                    </li>
                                    <li>
                                        <label className="my-1 mr-2">Last:</label>
                                        <select
                                            formcontrolname="transaction"
                                            id="Lastddl"
                                            value={pageSize}
                                            onChange={(e) => { setPageSize(e.target.value) }}
                                            className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                        >
                                            <option value={25}>25 Txn</option>
                                            <option value={50}>50 Txn</option>
                                            <option value={75}>75 Txn</option>
                                            <option value={100}>100 Txn</option>
                                        </select>
                                    </li>
                                    <li>
                                        <label className="my-1 mr-2">Auto Refresh (Seconds)</label>
                                        <select
                                            formcontrolname="autorefresh"
                                            id="autorefresh"
                                            className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                        >
                                            <option value={0}>Stop</option>
                                            <option value={60000}>60</option>
                                            <option value={30000}>30</option>
                                            <option value={15000}>15</option>
                                            <option value={10000}>10</option>
                                            <option value={5000}>5</option>
                                        </select>
                                    </li>
                                    <li>
                                        <label className="my-1 mr-2 fw-normal">Bet Status</label>
                                        <select
                                            formcontrolname="betStatus"
                                            id="betStatusddl"
                                            value={BetStatus}
                                            onChange={(e) => { setBetStatus(e.target.value) }}
                                            className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                        >
                                            {/* <option value={0}>All</option>
                    <option value={1}>Unmatched</option> */}
                                            <option value={2}>Matched</option>
                                            {LoggedInRoleId <= 2 && (<option value={3}>Void</option>)}
                                        </select>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className="btnblk"
                                            onclick="getBothList()"
                                        >
                                            Refresh
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="LiveMatch">
                            <div className="profile-section mt-3 unmatech-section">
                                <div className="unmatched-title text-start d-inline-block">
                                    {" "}
                                    Matched{" "}
                                </div>
                                <div className="ng-star-inserted">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                {/* <th>UPLINE ID</th> */}
                                                <th>PL ID</th>
                                                <th width="200px;">Bet taken</th>
                                                <th>IP Address</th>
                                                <th width="20%">Market</th>
                                                <th>Market Id</th>
                                                <th>Selection</th>
                                                <th>Type</th>
                                                <th>Odds req.</th>
                                                <th>Stake</th>
                                                <th>Liability</th>
                                                {LoggedInRoleId <= 2 && (<th>Action</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                betListData.map((e, index) => {
                                                    return (
                                                        <tr className={`${e.Type} ng-star-inserted`} key={e.Id}>
                                                            <td>{(page - 1) * pageSize + index + 1}</td>
                                                            {/* <td>{e.parentname}</td> */}
                                                            <td>{e.username}</td>
                                                            <td>{new Date(e.MatchedTime).toLocaleString()} </td>
                                                            <td>{e.IpAddress} </td>
                                                            <td className="form-to">
                                                                <span className="form">{e.Selection} </span>
                                                                <i className="fa fa-play" aria-hidden="true" />
                                                                <span className="to">{e.Market}</span>
                                                            </td>
                                                            <td>{e.MarketId}</td>
                                                            <td>{e.EventName} </td>
                                                            <td>{e.Type}</td>
                                                            <td>{e.OddsRequest}</td>
                                                            <td>{e.AmountStake}</td>
                                                            <td className="red">{e.Liability}</td>
                                                            {LoggedInRoleId <= 2 && (
                                                                BetStatus != 3 ? (
                                                                    <td>
                                                                        <button onClick={() => { funcToUpdateBetStatusToVoid(e.Id) }} style={{ padding: "5px 10px", background: "red", border: "2px solid red", color: "white" }}>Void</button>
                                                                    </td>)
                                                                    : (
                                                                        <td style={{ display: "flex", gap: "5px" }}>
                                                                            <button onClick={() => { funcToUpdateBetStatusToDelete(e.Id) }} style={{ padding: "5px 10px", background: "red", border: "2px solid red", color: "white" }}>Delete</button>
                                                                            <button onClick={() => { funcToUpdateBetStatusToRollBack(e.Id) }} style={{ padding: "5px 10px", background: "green", border: "2px solid green", color: "white" }}>Rollback</button>
                                                                        </td>
                                                                    )

                                                            )}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ margin: "20px", display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>

                                        <button
                                            style={{ padding: "5px 10px", background: "white" }}
                                            onClick={() => setPage((prevPage) => prevPage === 1 ? 1 : prevPage - 1)}
                                            disabled={page === 1}
                                        >Previous</button>

                                        {/* <button
                      onClick={()=>{setPage(1)}}
                      style={ page === 1 ? {padding:"5px 10px", background:"black", color:"wheat"} : {padding:"5px 10px", background:"white"}}
                      >1</button> */}

                                        {pagesNumber.map((pageNumber) => {
                                            return (
                                                <button
                                                    key={pageNumber}
                                                    onClick={() => { setPage(pageNumber) }}
                                                    style={page === pageNumber ? { padding: "5px 10px", background: "black", color: "wheat" } : { padding: "5px 10px", background: "white" }}
                                                >{pageNumber}</button>
                                            )
                                        })}


                                        {

                                        }

                                        <button
                                            style={{ padding: "5px 10px", background: "white" }}
                                            onClick={() => { setPage((prev) => prev + 1) }}
                                            disabled={page === totalNoOfBetData}
                                        >Next</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}

export default SelfUserBetListLive