import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";

function SeriesSetting() {

    const [data, setData] = useState([]);
    const [showActiveData, setShowActiveData] = useState("all");
    const [sportStatus, setSportStatus] = useState("all");
    const [isActive, setIsActive] = useState(true);
    const [pagesNumber, setPagesNumber] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const pageSize = 10;
    const displayPageCount = 5;

    // function to fetch all data from backend
    const fetchData = async () => {
        let res = await fetch(`https://api.betcare.live/api/getSeriesData/${showActiveData}/${sportStatus}/?page=${page}&pageSize=${pageSize}`);
        let resData = await res.json();
        console.log('series data : ', resData);
        setData(resData.data);

        const totalRows = resData.totalRow[0].row_count;
        const totalPages = Math.ceil(totalRows / pageSize);
        setTotalPage(totalPages);
        console.log("total page : ", totalPage);
        // setTotalNoOfBetData(Math.ceil (resData.totalRow[0].row_count/10));

        let totalNumber = Math.ceil(resData.totalRow[0].row_count / 10);

        const newArray = Array.from({ length: totalPages }, (_, index) => index + 1);
        setPagesNumber(newArray);
        console.log("page Numbers : ", pagesNumber);

    }


    const onChangeActiveHandler = (e) => {
        console.log("Active/Inactive : ", e.target.value);
        setShowActiveData(e.target.value);

    }


    const onChangeSportHandler = (e) => {
        console.log("Sport Id : ", e.target.value);
        setSportStatus(e.target.value);
    }

    // Function to get the page numbers to display
    const getDisplayedPages = () => {
        const halfWay = Math.ceil(displayPageCount / 2);
        let start = Math.max(page - halfWay, 1);
        let end = Math.min(start + displayPageCount - 1, totalPage);

        if (end - start + 1 < displayPageCount) {
            start = Math.max(end - displayPageCount + 1, 1);
        }

        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    }


    // updating status in sportevent table
    const updateSportStatus = async (event, id) => {
        event.preventDefault();


        console.log('Event and seriesId : ', event, id)
        let status;
        event.target.checked ? status = 1 : status = 0;




        toast.success(`Your work has been saved !!`, {
            autoClose: 1500,
            theme: "colored",
        });
        setIsActive((prev => !prev));


        setTimeout(() => {
            window.location.reload();

        }, 2000)


        let res = await fetch(`https://api.betcare.live/api/updateSeriesData/${id}/${status}`,
            { method: 'PUT' });



    }

    useEffect(() => {
        fetchData();
    }, [isActive, showActiveData, sportStatus, page])




    useEffect(() => {
        setPage(1);
    }, [showActiveData, sportStatus])




    return (
        <>
            <main role="main" className="pb-3">
                {/* <div className="download-list">
          <div className="container mxw-100">
            <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div>
          </div>
        </div> */}
                <div className="inner-pages banking">
                    <div className="container-fluid">
                        <div className="page-title" style={{ fontSize: 16 }}>
                            <h6 className="my-2">Series Setting</h6>
                        </div>
                        <form noValidate="" className="ng-untouched ng-pristine ng-valid">
                            <div className="row marketdiv">
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Status</label>
                                            <select
                                                id="status"
                                                name="status"
                                                value={showActiveData}
                                                onChange={onChangeActiveHandler}
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                            >
                                                <option value="all">All</option>
                                                <option value="1" id="CheckAction">
                                                    Active
                                                </option>
                                                <option value="0" id="CheckAction">
                                                    Inactive
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group status-filter position-relative">
                                        <div>
                                            <label className="my-1 mr-2">Sport Name</label>
                                            <select
                                                id="sportOption"
                                                name="sportId"
                                                value={sportStatus}
                                                onChange={onChangeSportHandler}
                                                className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                                            >
                                                <option value="all">
                                                    All
                                                </option>
                                                <option value="1" className="ng-star-inserted">
                                                    Soccer
                                                </option>
                                                <option value="2" className="ng-star-inserted">
                                                    Tennis
                                                </option>
                                                <option value="4" className="ng-star-inserted">
                                                    Cricket
                                                </option>
                                                <option value="5" className="ng-star-inserted">
                                                    Casino
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div />
                        <div className="profile-section mt-3 unmatech-section">
                            <div id="matchplist">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Series.Id</th>
                                            <th>Series Name</th>
                                            <th>Sports Name</th>
                                            <th>ON / OFF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((e, index) => {
                                            return (

                                                <tr className="ng-star-inserted" key={e.SrNo}>
                                                    <td>{(page - 1) * pageSize + index + 1}</td>
                                                    <td>{e.seriesId}</td>
                                                    <td>{e.seriesName}</td>
                                                    <td>{e.sportId == 1 ? 'Soccer' : e.sportId == 2 ? 'Tennis' : e.sportId == 4 ? 'Cricket' : ''}</td>
                                                    <td>
                                                        <div
                                                            className="form-check form-switch"
                                                            style={{ textAlign: "center !important" }}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                role="switch"
                                                                id="CheckAction_1"
                                                                checked={e.status}
                                                                onClick={(event) => { updateSportStatus(event, e.seriesId) }}
                                                                className="form-check-input CheckAction"
                                                                defaultChecked="checked"
                                                                title="Click here to deactivate match"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div style={{ margin: "20px", display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                                    <button
                                        style={{ padding: "5px 10px", background: "white" }}
                                        onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))}
                                        disabled={page === 1}
                                    >
                                        Previous
                                    </button>

                                    {getDisplayedPages().map(pageNumber => (
                                        <button
                                            key={pageNumber}
                                            onClick={() => setPage(pageNumber)}
                                            style={page === pageNumber
                                                ? { padding: "5px 10px", background: "black", color: "wheat" }
                                                : { padding: "5px 10px", background: "white" }}
                                        >
                                            {pageNumber}
                                        </button>
                                    ))}

                                    <button
                                        style={{ padding: "5px 10px", background: "white" }}
                                        onClick={() => setPage(prevPage => Math.min(prevPage + 1, totalPage))}
                                        disabled={page === totalPage}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default SeriesSetting