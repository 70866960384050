import React, { useContext, useEffect } from 'react'
import Header from './Header'
import { Outlet, useNavigate } from 'react-router-dom'
import currentUser from '../context/currentUser/currentUser';



const Layout = () => {

  const context = useContext(currentUser);
  const {isLoggedIn} = context;

  const navigate = useNavigate();

useEffect(()=>{

  console.log(isLoggedIn);
  if(isLoggedIn === "false") {
    navigate("/login");
    return;
  }
  if(!isLoggedIn) {
    navigate("/login");
    return;
  }
},[])

  return (
    <>
      <Header/>
      <Outlet/>
    </>
  )
}

export default Layout
