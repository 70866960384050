import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import currentUser from '../../context/currentUser/currentUser';
import moment from "moment";
import { useQuery } from '@tanstack/react-query';

const ActivityLog = () => {

  const [data, setData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const totalPage = 20;
  // const pageSize = 15; // Number of items per page
  // const [currentPage, setCurrentPage] = useState(1);
  let { state } = useLocation();

  const { data: activityLogData, isLoading, isError } = useQuery({
    queryKey: [`activityLog`, page], queryFn: async () => {
      let res = await fetch(`https://api.betcare.live/api/admin/userActivityLogs/${state.id}?page=${page}&totalpage=${totalPage}`);
      let resData = res.json();
      return resData;
    }
  });


  //  let sortedData =  activityLogData?.sort((a, b) => new Date(b.LoginTime) - new Date(a.LoginTime));
  //  const indexOfLastEvent = currentPage * eventsPerPage;
  //  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;




  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;


  const context = useContext(currentUser);
  const { isLoggedIn, roleId, fullName, name, userStatusId, setIsLoggedIn, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages } = context;



  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  };



  useEffect(() => {
    (async () => {
      let res = await fetch(`https://api.betcare.live/api/getUserDetail/users/${state.id}`);
      let resData = await res.json();
      setData(resData);
    })()

  }, [])


  // useEffect(()=>{
  //   // const currentEvents = sortedData?.slice(indexOfFirstEvent, indexOfLastEvent);
  //   // setActivityList(currentEvents);
  // },[currentPage])


  return (
    <>
      <main role="main" className="pb-3">
        <div className="account-pages">
          <div className="container mxw-100">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <ul id="agentPath" className="agent_path-L mb-0">
                    <li id="path6">
                      <a href="javascript: void(0);">
                        <span className="lv_0"> {UserRoleShortName[data?.length === 0 ? ".." : data[0].RoleId]} </span>
                        <strong> {data?.length === 0 ? ".." : data[0].UserName}   </strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 pe-0">
                <div className="match-list-inplay">
                  <div className="vertical-match-list">
                    <ul className="list-unstyled">
                      <li className="menu-title">Position</li>
                      <li id="linkActive" className="d-none">
                        <Link

                        >
                          Account Statement
                        </Link>
                      </li>
                      <li id="linkActive" className="">
                        <Link
                          className="link"
                          to={`/accountsummary`}
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Account Summary
                        </Link>
                      </li>
                      <li className="menu-title">
                        <a>Performance</a>
                      </li>
                      <li id="linkActiveBetting" className="">
                        <Link
                          className="link"
                          to="/bettinghistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting History
                        </Link>
                      </li>
                      <li id="linkActiveLoss" className="">
                        <Link
                          className="link"
                          to="/bettingprofitloss"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting Profit &amp; Loss
                        </Link>
                      </li>
                      <li id="linkActiveHistory" className="">
                        <Link
                          className="link"
                          to="/transactionhistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          {" "}
                          Transaction History
                        </Link>
                      </li>
                      <li id="Profile" className="d-none">
                        <a
                          className="link"
                          href="/user/Profile?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Profile
                        </a>
                      </li>
                      <li id="linkActiveLog" className="active">
                        <Link
                          className="link"
                          to={"/activitylog"}
                          state={currentDashboard !== null && { id: currentDashboard.userId, selfUser: state?.selfUser }}
                        >
                          Activity Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div id="logList">
                  <div className="myaccount-page">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="pagettl"> Activity Log </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="profile-section">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Login Date &amp; Time</th>
                                  <th>Login Status</th>
                                  <th>IP Address</th>
                                  {/* <th>ISP</th> */}
                                  <th>City/State/Country</th>
                                  <th>User Agent Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isError && <tr><td>Data not found !E</td></tr>}
                                {isLoading && <tr><td>Data is Loading</td></tr>}
                                {activityLogData?.rows?.map((activity) => {

                                  const parsedTimestamp = moment(activity.LoginTime);

                                  // Format date and time
                                  const formattedDate = parsedTimestamp.format('DD/MM/YYYY');
                                  const formattedTime = parsedTimestamp.format('HH:mm:ss');


                                  return (
                                    <tr key={activity.Id} >
                                      <td>{formattedDate + " " + formattedTime === null ? "N/A" : formattedDate + " " + formattedTime} </td>
                                      <td style={{ color: "#508d0e" }}>
                                        Login Success
                                      </td>
                                      <td>{activity.IpAddress === null ? "N/A" : activity.IpAddress}</td>
                                      {/* <td>grameenphone limited</td> */}
                                      <td>{activity.BrowserInfo == null ? "N/A" : activity.BrowserInfo}</td>
                                      <td>Browser</td>
                                    </tr>
                                  )
                                })}
                                {/* <tr>
                              <td>1 /3/2024 23:3:36 </td>
                              <td style={{ color: "#508d0e" }}>
                                Login Success
                              </td>
                              <td>37.111.203.210</td>
                              <td>grameenphone limited</td>
                              <td>Dhaka/Dhaka Division/Bangladesh/1206</td>
                              <td>Browser</td>
                            </tr>
                            <tr>
                              <td>24 /2/2024 21:13:17 </td>
                              <td style={{ color: "#508d0e" }}>
                                Login Success
                              </td>
                              <td>37.111.225.176</td>
                              <td>grameenphone limited</td>
                              <td>Puthia/Rajshahi Division/Bangladesh/</td>
                              <td>Browser</td>
                            </tr>
                            <tr>
                              <td>24 /2/2024 11:54:22 </td>
                              <td style={{ color: "#508d0e" }}>
                                Login Success
                              </td>
                              <td>37.111.247.152</td>
                              <td>grameenphone limited</td>
                              <td>
                                Rajshahi/Rajshahi Division/Bangladesh/6100
                              </td>
                              <td>Browser</td>
                            </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <pagination-controls className="text-center">
                          <pagination-template>
                            <nav aria-label="Pagination">
                              <ul className="ngx-pagination ng-star-inserted">
                                <li className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}>
                                  <button
                                    className="ng-star-inserted"
                                    style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                    onClick={() => setPage((prev) => prev - 1)}
                                    disabled={page === 1 ? true : false}
                                  >
                                    Previous{" "}
                                  </button>
                                </li>
                                {/* <li className="current ng-star-inserted">
                              <span
                                aria-live="polite"
                                className="ng-star-inserted"
                              >
                                <span className="show-for-sr">
                                  You're on page{" "}
                                </span>
                                <span>1</span>
                              </span>
                            </li> */}
                                <li className={`pagination-next  ${page === Math.ceil(activityLogData?.totalrows / totalPage) ? "disabled" : activityLogData?.totalrows <= 20 ? "disabled" : ""}`}>
                                  <button
                                    // aria-disabled="true"
                                    className=""
                                    style={page === Math.ceil(activityLogData?.totalrows / totalPage) ? { cursor: "not-allowed", border: "0px" } : activityLogData?.totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                    onClick={() => setPage((prev) => prev + 1)}
                                    disabled={page === Math.ceil(activityLogData?.totalrows / totalPage) ? true : activityLogData?.totalrows <= 20 ? true : false}
                                  >Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </pagination-template>
                        </pagination-controls>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
    </>

  )
}

export default ActivityLog
