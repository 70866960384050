import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import currentUser from '../../context/currentUser/currentUser';
import { FiPlusSquare } from "react-icons/fi";
import { FaRegSquareMinus } from "react-icons/fa6";
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const BettingProfitLoss = () => {

  let today1 = new Date();
  let todayM = moment(today1);
  let today = todayM.format(`YYYY-MM-DD`);

  let Tommorrow1 = new Date();
  let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
  let TommorrowM = moment(Tommorrow12);
  let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

  let Yesterdat1 = new Date();
  let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
  let YesterdayM = moment(Yesterday12);
  let yesterday = YesterdayM.format(`YYYY-MM-DD`);


  const [data, setData] = useState([]);
  // const [profitLossData, setProfitLossData] = useState([]);

  const [subProfitLossData, setSubProfitLossData] = useState([]);
  const [backTotal, setBackTotal] = useState();
  const [betType, setBetType] = useState("Exchange"); // it can be Exchange , Bookmaker , FancyBet
  const [sportType, setSportType] = useState("all");
  const [OpenMore, setOpenMore] = useState({});
  // const [currentSport , setCurrentSport] = useState("All");
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [fromDate, setFromDate] = useState(today);
  const [fromTime, setFromTime] = useState(`00:00:00`);
  const [toDate, setToDate] = useState(Tommorrow);
  const [toTime, setToTime] = useState(`23:59:59`);
  const [loadPage, setLoadPage] = useState(false);

  let { state } = useLocation();
  const userIdLS = localStorage.getItem(`UserId`);
  const SportsName = { "1": "SOCCER", "2": "TENNIS", "4": "CRICKET" };

  console.log('State : ', state);



  const { data: profitlossdata, isLoading, isError } = useQuery({
    queryKey: [`profit-loss-data`, page, betType, sportType, loadPage], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/admin/profitLossData/${state?.id !== undefined ? state?.id : userIdLS}/${betType}/${sportType}?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`);
      let resData = await res.data;
      console.log('res data : ', resData)
      return resData;
    }
  });





  const context = useContext(currentUser);
  const { isLoggedIn, roleId, fullName, name, userStatusId, setIsLoggedIn, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages, currentCurrency } = context;

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  };


  const setDateFunc = async (btnName) => {

    if (btnName === 'today') {
      setFromDate(today);
      setFromTime(`00:00:00`);
      setToDate(Tommorrow);
      setToTime(`12:59:59`);
      // await queryClient.refetchQueries({ type: 'betting-data' });
      // refetch();
      setPage(1);
      setLoadPage((prev) => !prev);

    } else if (btnName === "yesterday") {

      setFromDate(yesterday);
      setFromTime(`00:00:00`);
      setToTime(`12:59:59`);
      setToDate(today);
      // await queryClient.refetchQueries({ type: 'betting-data' });
      // refetch();
      setPage(1);
      setLoadPage((prev) => !prev);

    }
  }

  const onChangeHandler = (e, dateTimeType) => {
    if (dateTimeType === "fromDate") {
      setFromDate(e.target.value);

      // setLoadPage((prev)=>!prev);
      // refetch();
    }
    else if (dateTimeType === "fromTime") {
      setFromTime(e.target.value);
      // setLoadPage((prev)=>!prev);
      // refetch();
    }
    else if (dateTimeType === "toDate") {
      setToDate(e.target.value);
      // setLoadPage((prev)=>!prev);
      // refetch();
    }
    else if (dateTimeType === "toTime") {
      setToTime(e.target.value);
      // setLoadPage((prev)=>!prev);
      // refetch();
    }
  }


  async function getHistory() {
    // await queryClient.refetchQueries({ type: 'betting-data' });
    setLoadPage((prev) => !prev);
    setPage(1);
  }





  const getSubProfitLossDataFunc = async (index, eventID) => {
    console.log(`enteredd the func`);
    console.log(index);
    if (OpenMore?.id == index) {
      console.log(`open more to close`);
      setOpenMore({})
    } else {
      setOpenMore({ id: index });
      console.log(`open more to open`);

      try {
        console.log(`entered the try log!!`);
        let res = await axios.get(`https://api.betcare.live/api/admin/subProfitLossData/${state?.id !== undefined ? state?.id : userIdLS}/${eventID}/${betType}`);
        console.log(`after res`);
        let resData = await res.data;
        console.log(`after resData`);
        setSubProfitLossData(resData);
        let backResult = subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0);

        console.log(resData);
        setBackTotal(backResult);

      } catch (error) {
        console.log(error);
      }
    }
  }


  useEffect(() => {
    (async () => {

      try {
        let res = await fetch(`https://api.betcare.live/api/getUserDetail/users/${state?.id}`);
        let resData = await res.json();
        setData(resData);
      } catch (error) {
        console.log(error);
      }

    })()
  }, [])


  console.log('sport type : ', sportType);
                                    
  return (
    <>
      <main role="main" className="pb-3">
        <input type="hidden" id="btnSelect" defaultValue="TodayHistory" />
        <input type="hidden" id="userId" defaultValue={3} />
        <div className="account-pages">
          <div className="container mxw-100">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <ul id="agentPath" className="agent_path-L mb-0">
                    <li id="path6">
                      <a href="javascript: void(0);">
                        <span className="lv_0"> {UserRoleShortName[data.length === 0 ? ".." : data[0].RoleId]} </span>
                        <strong> {data.length === 0 ? ".." : data[0].UserName} </strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 pe-0">
                <div className="match-list-inplay">
                  <div className="vertical-match-list">
                    <ul className="list-unstyled">
                      <li className="menu-title">Position</li>
                      <li id="linkActive" className="d-none">
                        <a
                          className="link"
                          href="/User/AccountStatement?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Account Statement
                        </a>
                      </li>
                      <li id="linkActive" className="">
                        <Link
                          className="link"
                          to="/accountsummary"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Account Summary
                        </Link>
                      </li>
                      <li className="menu-title">
                        <a>Performance</a>
                      </li>
                      <li id="linkActiveBetting" className="">
                        <Link
                          className="link"
                          to="/bettinghistory"
                          state={currentDashboard !== null && { id: state?.id, roleId: state?.roleId, selfUser: state?.selfUser }}
                        >
                          Betting History
                        </Link>
                      </li>
                      <li id="linkActiveLoss" className="active">
                        <Link
                          className="link"
                          to="/bettingprofitloss"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting Profit &amp; Loss
                        </Link>
                      </li>
                      <li id="linkActiveHistory" className="">
                        <Link
                          className="link"
                          to="/transactionhistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          {" "}
                          Transaction History
                        </Link>
                      </li>
                      <li id="Profile" className="d-none">
                        <a
                          className="link"
                          href="/user/Profile?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Profile
                        </a>
                      </li>
                      <li id="linkActiveLog" className="">
                        <Link
                          className="link"
                          to="/activitylog"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Activity Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="myaccount-page">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="pagettl"> Betting Profit &amp; Loss </div>
                    </div>
                    <ul className="acc-info">
                      {/* <li>
                    <img src="images/profile-btn.png" />
                    <span className="mx-1">superadmin</span>
                  </li> */}
                    </ul>
                    <div className="col-lg-12">
                      <div className="my-bets mb-3">
                        <div id="myTabContent" className="tab-content">
                          <div className="d-inline-flex align-items-center ng-untouched ng-pristine ng-valid w-100">
                            <div
                              id="BetsHistory"
                              role="tabpanel"
                              aria-labelledby="BetsHistory-tab"
                              className="tab-pane fade show active w-100"
                            >
                              <ul
                                id="myTab2"
                                role="tablist"
                                className="nav nav-tabs mb-0"
                              >
                                <li role="presentation" className="nav-item">
                                  <button
                                    id="BetsHistoryExchange-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#BetsHistoryExchange"
                                    type="button"
                                    role="tab"
                                    aria-controls="BetsHistoryExchange"
                                    aria-selected="true"
                                    value="Exchange"
                                    onClick={() => { setBetType("Exchange"); setPage(1); }}
                                    className={`nav-link ${betType === "Exchange" ? "active" : ""}`}
                                  >
                                    Exchange
                                  </button>
                                </li>
                                <li role="presentation" className="nav-item ">
                                  <button
                                    id="BetsHistoryExchange-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#BetsHistoryExchange"
                                    type="button"
                                    role="tab"
                                    aria-controls="BetsHistoryExchange"
                                    aria-selected="true"
                                    value="Exchange"
                                    onClick={() => { setBetType("Bookmaker"); setPage(1); }}
                                    className={`nav-link  ${betType === "Bookmaker" ? "active" : ""}`}
                                  >
                                    Bookmaker
                                  </button>
                                </li>
                                <li role="presentation" className="nav-item">
                                  <button
                                    id="BetsHistoryExchange-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#BetsHistoryExchange"
                                    type="button"
                                    role="tab"
                                    aria-controls="BetsHistoryExchange"
                                    aria-selected="true"
                                    value="Exchange"
                                    onClick={() => { setBetType("FancyBet"); setPage(1); }}
                                    className={`nav-link ${betType === "FancyBet" ? "active" : ""}`}
                                  >
                                    FancyBet
                                  </button>
                                </li>
                              </ul>
                              <div id="myTabContent2" className="tab-content">
                                <div
                                  id="BetsHistoryExchange"
                                  role="tabpanel"
                                  aria-labelledby="BetsHistoryExchange-tab"
                                  className="tab-pane fade show active"
                                >
                                  <div className="my-report">
                                    <div className="time-zone-section">
                                      <ul className="list-unstyled">
                                        <li>
                                          <label className="my-1 mr-2 fw-normal">
                                            Period
                                          </label>
                                          <input
                                            type="date"
                                            formcontrolname="periodFormDate"
                                            value={fromDate}
                                            onChange={(e) => { onChangeHandler(e, "fromDate") }}
                                            id="sdate"
                                            className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                            style={{ width: '100%' }}
                                          />
                                          {/* <input
                                            type="time"
                                            placeholder="9:00:00"
                                            value={fromTime}
                                            onChange={(e) => { onChangeHandler(e, "fromTime") }}
                                            formcontrolname="periodFormTime"
                                            id="stime"
                                            ng-model="datetime.time"
                                            className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                          /> */}
                                          <span className="d-inline-flex mx-2">
                                            To
                                          </span>
                                          <input
                                            type="date"
                                            formcontrolname="periodtoDate"
                                            value={toDate}
                                            onChange={(e) => { onChangeHandler(e, "toDate") }}
                                            id="edate"
                                            className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                            style={{ width: '100%' }}
                                          />
                                          {/* <input
                                            type="time"
                                            placeholder="8:59:59"
                                            id="etime"
                                            value={toTime}
                                            onChange={(e) => { onChangeHandler(e, "toTime") }}
                                            formcontrolname="periodtotime"
                                            className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                          /> */}
                                        </li>
                                      </ul>
                                      <ul className="list-unstyled report-btn-group">
                                        <li>
                                          <button
                                            type="button"
                                            className={`${fromDate === today ? toDate === Tommorrow ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                            onClick={() => { setDateFunc(`today`) }}
                                          >
                                            Just For Today
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="button"
                                            className={`${fromDate === yesterday ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                            onClick={() => { setDateFunc(`yesterday`) }}
                                          >
                                            From Yesterday
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            type="submit"
                                            onClick={getHistory}
                                            className="btnblk"
                                          >
                                            Get P &amp; L
                                          </button>
                                        </li>
                                      </ul>
                                      <button
                                        tabIndex={0}
                                        aria-controls="example"
                                        type="button"
                                        className="dt-button buttons-excel buttons-html5"
                                      >
                                        <span>
                                          <i
                                            className="fa fa-file-text-o"
                                            aria-hidden="true"
                                          />{" "}
                                          Excel
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="betPLList">
                            <div>
                              <div className="total-pl-header-tbl d-flex justify-content-between align-items-center">
                                <h6 className="mb-0">
                                  Total P/L: {currentCurrency} <span className={Number(profitlossdata?.rows?.reduce((acc, obj) => acc + obj.TotalResultAmount, 0)).toFixed(2) >= 0 ? "green" : "red"}>{Number(profitlossdata?.rows?.reduce((acc, obj) => acc + obj.TotalResultAmount, 0)).toFixed(2)}</span>
                                </h6>
                                <div className="secthph-path d-inline-flex align-items-center">
                                  <select
                                    formcontrolname="sportId"
                                    id="sports"
                                    value={sportType}
                                    onChange={(e) => { setSportType(e.target.value); setPage(1); }}
                                    className="form-select mr-sm-2"
                                  >
                                    {/* <select  name="sports" id=""> */}
                                    <option name="sports" value={`all`} >All</option>
                                    <option name="sports" value={1}>Soccer</option>
                                    <option name="sports" value={2}>Tennis</option>
                                    <option name="sports" value={4}>Cricket</option>
                                    {/* </select>  */}
                                  </select>
                                  <h6 className="ms-2 mb-0">
                                    {currentCurrency} <span className={Number(profitlossdata?.rows?.reduce((acc, obj) => acc + obj.TotalResultAmount, 0)).toFixed(2) >= 0 ? "green" : "red"}>{Number(profitlossdata?.rows?.reduce((acc, obj) => acc + obj.TotalResultAmount, 0)).toFixed(2)} </span>
                                  </h6>
                                </div>
                              </div>
                              <div className="profile-section mt-3 unmatech-section betting-prfit">
                                <div>
                                  <table className="table table01 text-end">
                                    <thead>
                                      <tr>
                                        <th>Srno</th>
                                        <th className="text-start">Market</th>
                                        <th>Start Time</th>
                                        <th>Settled date </th>
                                        <th>Profit/Loss</th>
                                      </tr>
                                    </thead>
                                    {/* <tbody> */}
                                    {profitlossdata?.rows
                                      ?.filter(item => sportType === 'all' || sportType === item.SportId.toString())
                                      ?.map((row, index) => {

                                        console.log('sport type : ', sportType , row.SportId)
                                        const SettleDateTime = moment(row.SettleTime);
                                        const EventDateTime = moment(row.eventDate);

                                        const SettleDateF = SettleDateTime.format('DD/MM/YYYY');
                                        const SettleTimeF = SettleDateTime.format('HH:mm:ss');

                                        const EventDateF = EventDateTime.format('DD/MM/YYYY');
                                        const EventTimeF = EventDateTime.format('HH:mm:ss');

                                        return (
                                          <tbody key={index}>
                                            <tr>
                                              <td>{(((page - 1) * pageSize) + (index + 1))}</td>
                                              <td className='text-start'>
                                                {SportsName[row.SportId]} <span>{">"}</span>
                                                <span className='font-weight-bold'>{row.EventName}</span>
                                                <span>{">"}</span>
                                                <span>{row.Market}</span>
                                              </td>
                                              <td>{row.eventDate === null ? "N/A" : EventDateF + " " + EventTimeF}</td>
                                              <td>{SettleDateF + " " + SettleTimeF}</td>
                                              <td>
                                                <span style={Number(row.TotalResultAmount).toFixed(2) >= 0 ? { color: "green" } : { color: "red" }}>
                                                  {Number(row.TotalResultAmount).toFixed(2)}
                                                </span>
                                                <span onClick={() => { getSubProfitLossDataFunc(index, row.EventId) }} >
                                                  {OpenMore?.id === index ? <FaRegSquareMinus className='cursor-pointer' style={{ fontSize: "15px" }} /> : <FiPlusSquare className='cursor-pointer' style={{ fontSize: "15px" }} />}
                                                </span>
                                              </td>
                                            </tr>
                                            {OpenMore?.id === index && (
                                              <tr>
                                                <td colSpan={6} dir='ltl'>
                                                  <table style={{ border: "1px solid black" }}>
                                                    <thead>
                                                      <tr>
                                                        <th>BetId</th>
                                                        <th>Selection</th>
                                                        <th>Odds</th>
                                                        <th>Stake</th>
                                                        <th>Type</th>
                                                        <th>Placed</th>
                                                        <th>P/L</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {subProfitLossData.length === 0 ? (
                                                        <tr><td>Can't fetch the data</td></tr>
                                                      ) : (
                                                        subProfitLossData?.map((subRow, subIndex) => {
                                                          const PlaceDateTime = moment(subRow.PlaceTime);
                                                          const PlaceDateF = PlaceDateTime.format('DD/MM/YYYY');
                                                          const PlaceTimeF = PlaceDateTime.format('HH:mm:ss');
                                                          return (
                                                            <tr key={subIndex} className={`${subRow.Type} ng-star-inserted`}>
                                                              <td>{subRow.BetId}</td>
                                                              <td>{subRow.Selection}</td>
                                                              <td>{subRow.OddsRequest}</td>
                                                              <td>{subRow.AmountStake}</td>
                                                              <td>{subRow.Type}</td>
                                                              <td>{PlaceDateF + " " + PlaceTimeF}</td>
                                                              <td style={Number(subRow.ResultAmount).toFixed(2) >= 0 ? { color: "green", background: "white" } : { color: "red", background: "white" }}>
                                                                {Number(subRow.ResultAmount).toFixed(2)}
                                                              </td>
                                                            </tr>
                                                          );
                                                        })
                                                      )}
                                                    </tbody>
                                                  </table>
                                                  <div style={{ border: "1px solid black", marginLeft: "800px", marginTop: "10px", padding: "2px", paddingLeft: "10px" }}>
                                                    <div className='text-start' style={{ display: "flex", gap: "45px" }}>
                                                      Total Stakes <span style={subProfitLossData?.reduce((acc, obj) => acc + obj.AmountStake, 0) >= 0 ? { color: "green" } : { color: "red" }}>
                                                        {subProfitLossData?.reduce((acc, obj) => acc + obj.AmountStake, 0)}
                                                      </span>
                                                    </div>
                                                    <div className='text-start' style={{ display: "flex", gap: "32px" }}>
                                                      Back SubTotal <span style={subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0).toFixed(2) >= 0 ? { color: "green" } : { color: "red" }}>
                                                        {subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0).toFixed(2)}
                                                      </span>
                                                    </div>
                                                    <div className='text-start' style={{ display: "flex", gap: "43px" }}>
                                                      Lay SubTotal <span style={subProfitLossData?.reduce((acc, obj) => obj.Type === 'lay' ? acc + obj.ResultAmount : acc, 0).toFixed(2) >= 0 ? { color: "green" } : { color: "red" }}>
                                                        {subProfitLossData?.reduce((acc, obj) => obj.Type === 'lay' ? acc + obj.ResultAmount : acc, 0).toFixed(2)}
                                                      </span>
                                                    </div>
                                                    <div className='text-start' style={{ borderBottom: "1px solid black", padding: "2px", display: "flex", gap: "22px" }}>
                                                      Market Subtotal <span style={Number(subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0) + subProfitLossData?.reduce((acc, obj) => obj.Type === 'lay' ? acc + obj.ResultAmount : acc, 0)).toFixed(2) >= 0 ? { color: "green" } : { color: "red" }}>
                                                        {Number(subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0) + subProfitLossData?.reduce((acc, obj) => obj.Type === 'lay' ? acc + obj.ResultAmount : acc, 0)).toFixed(2)}
                                                      </span>
                                                    </div>
                                                    <div className='text-start' style={{ display: "flex", gap: "22px" }}>
                                                      Net Market Total <span style={Number(subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0) + subProfitLossData?.reduce((acc, obj) => obj.Type === 'lay' ? acc + obj.ResultAmount : acc, 0)).toFixed(2) >= 0 ? { color: "green" } : { color: "red" }}>
                                                        {Number(subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0) + subProfitLossData?.reduce((acc, obj) => obj.Type === 'lay' ? acc + obj.ResultAmount : acc, 0)).toFixed(2)}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        );
                                      })}

                                    {/* </tbody> */}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>
        <pagination-controls className="text-center">
          <pagination-template>
            <nav aria-label="Pagination">
              <ul className="ngx-pagination ng-star-inserted">
                <li
                  className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                >
                  <button
                    className="ng-star-inserted"
                    style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                    onClick={() => { setPage((prev) => prev - 1) }}
                    disabled={page === 1 && true}
                  >
                    Previous{" "}
                  </button>
                </li>
                {/* <li className="current ng-star-inserted">
                              <span
                                aria-live="polite"
                                className="ng-star-inserted"
                              >
                                <span className="show-for-sr">
                                  You're on page{" "}
                                </span>
                                <span>1</span>
                              </span>
                            </li> */}
                <li
                  className={`pagination-next  ${page === Math.ceil(profitlossdata?.totalrows / pageSize) ? "disabled" : profitlossdata?.totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                >
                  <button
                    // aria-disabled="true"
                    className="ng-star-inserted"
                    style={page === Math.ceil(profitlossdata?.totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : profitlossdata?.totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                    onClick={() => setPage((prev) => prev + 1)}
                    disabled={page === Math.ceil(profitlossdata?.totalrows / pageSize) ? true : profitlossdata?.totalrows <= 20 ? true : false}
                  >Next
                  </button>
                </li>
              </ul>
            </nav>
          </pagination-template>
        </pagination-controls>
      </div>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
    </>

  )
}

export default BettingProfitLoss
