import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { addViewportMetaTag, removeViewportMetaTag } from '../metaUtils';

const MetaManager = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/login') {
            addViewportMetaTag();
        } else {
            removeViewportMetaTag();
        }
    }, [location.pathname]);

    return null;
};

export default MetaManager;