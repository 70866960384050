
import { useState } from "react"
import CurrentUser from "./currentUser"

const CurrentUserState = (props) => {

   const isUserLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
   const UserRoleId = JSON.parse(localStorage.getItem("RoleId"));
   const UserFullName = JSON.parse(localStorage.getItem("UserName"));
   const userStatusId = JSON.parse(localStorage.getItem("userStatusId"));
   // const CurrentCurrency = JSON.parse(localStorage.getItem("CurrentCurrency"));

   //console.log(userStatusId);

   const currentDashboardFromLS = JSON.parse(localStorage.getItem("currentDashboard"));
   const currentUserPagesFromLS = JSON.parse(localStorage.getItem("currentUserPages"));

   // //console.log("the data from local storage \n "+ currentDashboardFromLS);

   // //console.log("enter of header " + Math.floor(Math.random()*25));

   const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn);
   const [roleId, setRoleId] = useState(Number(UserRoleId));

   const [userId, setUserId] = useState();
   // const userStatusID = 

   const [siteNotice, setSiteNotice] = useState('')

   const [fullName, setFullName] = useState(UserFullName);
   const [pageLoad, setPageLoad] = useState(false);

   const [currentCurrency, SetCurrentCurrency] = useState('');
   const [currentBalance, SetCurrentBalance] = useState(0);

   const [currentDashboard, setCurrentDashboard] = useState(currentDashboardFromLS);

   const [currentUserPages, SetCurrentUserPages] = useState(currentUserPagesFromLS);

   const [allApiUserdata, setAllApiUserdataData] = useState([]) //store all from in Header use into AdminDashboard Component
   const [dashboardUserFilterStatus, setDashboardUserFilterStatus] = useState(0)

   const [refetchUsers, setRefetchUsers] = useState(false); // when its true it calls the filterChild in header

   const [reloadOn, setReloadOn] = useState(false)
   // const [currentRoleId]

   // //console.log("After storing in a state "+ currentCurrency);
   //  //console.log(currentUserPages);  
   //  //console.log(currentDashboard);
   //console.log(currentDashboard);

   return (
      <CurrentUser.Provider value={{ isLoggedIn, setIsLoggedIn, pageLoad, userStatusId, setPageLoad, roleId, currentDashboard, userId, setUserId, setCurrentDashboard, setRoleId, fullName, setFullName, currentUserPages, SetCurrentUserPages, currentCurrency, SetCurrentCurrency, currentBalance, SetCurrentBalance, siteNotice, setSiteNotice, allApiUserdata, setAllApiUserdataData, dashboardUserFilterStatus, setDashboardUserFilterStatus, refetchUsers, setRefetchUsers, reloadOn, setReloadOn }}>
         {props.children}
      </CurrentUser.Provider>
   )
}


export default CurrentUserState;