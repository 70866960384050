import React, { useContext, useEffect, useState } from 'react'
import { toast } from "react-toastify";
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// import mikePhone from '../../../images/mike-phone.svg'
// import instaIcon from '../../../images/insta-icon.svg'
// import skyIcon from '../../../images/sky-icon.svg'
// import shearIcon from '../../../images/shear-icon.svg'
// import betfairLogo from '../../../images/logo-betfair.png'
// import iconBrowserW from '../../../images/icon-browser-W.png'
// import phoneIcon from '../../../images/phone-icon.svg'
// import KVpic from '../../../images/KV-pic.PNG'
// import bgLoginWrap from '../../../images/bg-login_wrap.png'
// import loginIcon from '../../../images/login-icon.svg'

const Login = () => {

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [ValidationCode, setValidationCode] = useState("");
  const [ip, setIp] = useState("103.300.85.18");
  const [BrowerInfo, setBrowerInfo] = useState("jaipur/Rajasthan/India/302012");
  const [isLoading, setIsLoading] = useState(false);

  const [code, setCode] = useState();

  let navigate = useNavigate();

  const context = useContext(currentUser);
  const { isLoggedIn, setIsLoggedIn, setRoleId, roleId, currentDashboard, SetCurrentCurrency, userId, setUserId, setCurrentDashboard, setFullName, SetCurrentUserPages } = context;





  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "username") {
      setUserName(value)
    }
    if (name === "password") {
      setPassword(value)
    }
    if (name === "validationcode") {
      setValidationCode(value)
    }
  }


  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      if (ValidationCode !== code) {
        toast.error(`Code is not Matched Try another Time`, {
          autoClose: 1500,
          theme: "colored",
        });

        // setTimeout(() => window.location.reload(), 2000);
        return;
      }
      setIsLoading(true)

      console.log('browser ifor : ', BrowerInfo)
      let res = await fetch("https://api.betcare.live/api/auth/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: userName,
          password: password,
          ip: ip,
          browerInfo: BrowerInfo
        })
      });


      let resData = await res.json();

      if (res.status === 400) {
        toast.error(`${resData.error}`, {
          autoClose: 1500,
          theme: "colored",
        });

        // setTimeout(() => window.location.reload(), 2000);
        setIsLoading(false)
        return;
      }


      setIsLoggedIn(true)
      setRoleId(resData.RoleId);
      setFullName(resData.FullName);
      // SetCurrentCurrency(resData.CurrencyName);
      console.log('login User Id : ', resData.UserId);
      setUserId(resData.UserId);

      SetCurrentUserPages([{
        roleId: resData.RoleId,
        fullName: resData.FullName,
        id: resData.UserId
      }]);

      setCurrentDashboard({ roleId: resData.RoleId, userId: resData.UserId });


      // console.log(resData.FullName);
      Cookies.set('userIP', ip, { expires: 1 });
      //  localStorage.setItem("isLoggedIn","true");
      localStorage.setItem("RoleId", JSON.stringify(resData.RoleId));
      localStorage.setItem("UserName", JSON.stringify(resData.FullName));
      localStorage.setItem("UserId", JSON.stringify(resData.UserId));
      //  localStorage.setItem("CurrentCurrency",JSON.stringify(resData.CurrencyName))
      localStorage.setItem("userStatusId", JSON.stringify(resData.UserStatusId));
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      localStorage.setItem("currentDashboard", JSON.stringify({ roleId: resData.RoleId, userId: resData.UserId }));
      localStorage.setItem("currentUserPages", JSON.stringify([{
        roleId: resData.RoleId,
        fullName: resData.FullName,
        id: resData.UserId
      }]));

      console.log(`the status id : ${resData.UserStatusId}`);
      toast.success(`Logged In Successfully!`, {
        autoClose: 1000,
        theme: "colored",
      });

      navigate(`/`);
    } catch (error) {
      console.log("Error While Agent login : ", error)
    } finally {
      setIsLoading(false)
    }

  }


  useEffect(() => {
    let number1 = Math.floor(Math.random() * 10);
    let number2 = Math.floor(Math.random() * 10);
    let number3 = Math.floor(Math.random() * 10);
    let number4 = Math.floor(Math.random() * 10);
    setCode(String(number1) + String(number2) + String(number3) + String(number4));


    if (isLoggedIn) {
      navigate("/");
    }


  }, [])


  useEffect(() => {
    ; (async () => {
      let res = await fetch(`https://api.ipify.org/?format=json`);
      let resData = await res.json();
      console.log('api.ipify : ', resData);
      setIp(resData.ip);

    })()

  }, [])




  useEffect(() => {
    (async () => {
      console.log(`ip of this brower is : ${ip}`);
      let res = await fetch(`https://freeipapi.com/api/json/${ip}`);
      let resData = await res.json();
      console.log('ip-api : ', resData);

      let city = await resData.cityName;
      let region = await resData.regionName;
      let country = await resData.countryName;
      let zip = await resData.zipCode;
      console.log(city + region + country + zip);
      setBrowerInfo(`${city}/${region}/${country}/${zip}`);

    })();
  }, [ip])


  useEffect(() => {
    ; (async () => {
      console.log('Browser info : ', BrowerInfo);

    })()
  }, [ip])


  return (
    <div className="loginpanle-page">
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <div className="container-adminpanle">
        <div className="login-sec">
          <div className="container">
            <div className="login-inner">
              <div className="login-left">
                <img className="dekstop-img" src='/images/bg-login_wrap.PNG' alt="" />
                <img className="responsive-img" src='/images/KV-pic.PNG' alt="" />
                <div className="mobile-logo">
                  <h1>BetcareLive</h1>
                </div>
              </div>
              <div className="login-right">
                <p className="font-size20">Agent login</p>
                {/* <img src="dist/assets/verifycode.gr" alt="" /> */}
                <form onSubmit={onSubmitForm}>
                  <ul>
                    <li>
                      <input type="text" name='username' onChange={onChangeHandler} value={userName} placeholder="username" />
                    </li>
                    <li>
                      <input type="password" name='password' onChange={onChangeHandler} value={password} placeholder="password" />
                    </li>
                    <li className="verify-coad" style={{ display: "flex", alignItems: "center" }}>
                      <input type="text" name='validationcode' onChange={onChangeHandler} value={ValidationCode} placeholder="Validation Code" />
                      <span style={{ background: "white", padding: "11px", fontSize: "1.2rem", fontWeight: "bold" }}>{code}</span>
                    </li>
                    <li>
                      <button>
                        Login
                        {/* <img src='../../images/login-icon.svg' alt="" /> */}
                      </button>
                    </li>
                  </ul>
                </form>
                <div className="responsive-support-sec">
                  <ul>
                    <li>
                      <div className="responsive-support-btn">
                        <div className="support-btn">
                          <ul>
                            <li>
                              <img src='../../../images/mike-phone.svg' alt="" />
                            </li>
                            <li>
                              <a href="#">Customer support1</a>
                            </li>
                            <li className="support-border">
                              <a href="#">support2</a>
                            </li>
                          </ul>
                        </div>
                        <div className="support-btn">
                          <ul>
                            <li>
                              <img src='../../../images/phone-icon.svg' alt="" />
                            </li>
                            <li>
                              <a href="#">+910000000000</a>
                            </li>
                            <li className="support-border">
                              <a href="#">+910000000000</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="border-line" />
                    </li>
                    <li>
                      <div className="support-btn-sec social-sec">
                        <div className="support-btn">
                          <ul>
                            <li>
                              <img src='../../../images/sky-icon.svg' alt="" />
                            </li>
                            <li>
                              <a href="#">Skype</a>
                            </li>
                          </ul>
                        </div>
                        <div className="support-btn">
                          <ul>
                            <li>
                              <img src='../../../images/shear-icon.svg' alt="" />
                            </li>
                            <li>
                              <a href="#">Email</a>
                            </li>
                          </ul>
                        </div>
                        <div className="support-btn">
                          <ul>
                            <li>
                              <img src='../../../images/insta-icon.svg' alt="" />
                            </li>
                            <li>
                              <a href="#">insta</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Customer-support-sec">
          <div className="container">
            <div className="Customer-support-inner desktop-support-sec">
              <ul>
                <li>
                  <div className="support-btn-sec">
                    <div className="support-btn">
                      <ul>
                        <li>
                          <img src='../../../images/mike-phone.svg' alt="" />
                        </li>
                        <li>
                          <a href="#">Customer support1</a>
                        </li>
                        <li className="support-border">
                          <a href="#">support2</a>
                        </li>
                      </ul>
                    </div>
                    <div className="support-btn">
                      <ul>
                        <li>
                          <img src='../../../images/phone-icon.svg' alt="" />
                        </li>
                        <li>
                          <a href="#">+910000000000</a>
                        </li>
                        <li className="support-border">
                          <a href="#">+910000000000</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="border-line" />
                </li>
                <li>
                  <div className="support-btn-sec social-sec">
                    <div className="support-btn">
                      <ul>
                        <li>
                          <img src='../../../images/sky-icon.svg' alt="" />
                        </li>
                        <li>
                          <a className="dekstop-text" href="#">
                            betcareliveofficial
                          </a>
                          <a className="responsive-text" href="#">
                            Skype
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="support-btn">
                      <ul>
                        <li>
                          <img src='../../../images/shear-icon.svg' alt="" />
                        </li>
                        <li>
                          <a className="dekstop-text" href="#">
                            info@betcarelive.com
                          </a>
                          <a className="responsive-text" href="#">
                            Email
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="support-btn">
                      <ul>
                        <li>
                          <img src='../../../images/insta-icon.svg' alt="" />
                        </li>
                        <li>
                          <a className="dekstop-text" href="#">
                            officialbetcarelive
                          </a>
                          <a className="responsive-text" href="#">
                            insta
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Powered by */}
        <div className="Powered-by">
          <div className="container">
            <div className="Powered-by-inner">
              <ul>
                <li>
                  <h3>
                    <span>Powered by</span>
                    <img src='../../../images/logo-betfair.png' alt="" />
                  </h3>
                </li>
                <li>
                  <iframe
                    src="https://licensing.gaming-curacao.com/validator/?lh=7a83475c9e54450a218a18bd28e33fad&template=seal"
                    width={150}
                    height={50}
                    style={{ border: "none" }}
                  />
                </li>
                <li>
                  <p>
                    <a href="#">Betcare Live</a> is licensed and regulated by
                    Government of Curacao under license no 365/JAZ Sub-License
                    GLH-OCCHKTW0707072023.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="browsers-sec">
          <div className="container">
            <div className="browsers-inner">
              <img src='../../../images/icon-browser-W.png' alt="" />
              <p>
                Our website works best in the newest and last prior version of these
                browsers:
                <br />
                Google Chrome. Firefox
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default Login
