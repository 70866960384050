import React, { useState, useEffect, useContext } from 'react'

import { toast } from "react-toastify";
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';




const MatchSetting = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const [TotalNumberOfData, setTotalNumberOfData] = useState();
  const [pagesNumber, setPagesNumber] = useState([]);



  const [showActiveData, setShowActiveData] = useState("all");
  const [sportStatus, setSportStatus] = useState("all");
  const [searchWord, setSearchWord] = useState("");


  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const pageSize = 10;
  const displayPageCount = 5;


  const context = useContext(currentUser);
  const { userStatusId, setIsLoggedIn } = context


  // Match setting data /- Min and Max of Odds , Bookmaker , Session

  // odds
  const [minOdds, setMinOdds] = useState(0);
  const [maxOdds, setMaxOdds] = useState(0);


  // bookmaker
  const [minBookmaker, setMinBookmaker] = useState(0);
  const [maxBookmaker, setMaxBookmaker] = useState(0);


  // session
  const [minSession, setMinSession] = useState(0);
  const [maxSession, setMaxSession] = useState(0);

  const [gameId, setGameId] = useState(0);





  // handling onChange event in odds,bookmaker,session
  const handleOnChange = (e) => {
    let inputValue = e.target.value;
    let dataName = e.target.name;

    if (dataName == "minOdds") {
      setMinOdds(inputValue);
    }

    if (dataName == "maxOdds") {
      setMaxOdds(inputValue);
    }

    if (dataName == "minBookmaker") {
      setMinBookmaker(inputValue);
    }

    if (dataName == "maxBookmaker") {
      setMaxBookmaker(inputValue);
    }

    if (dataName == "minSession") {
      setMinSession(inputValue);
    }

    if (dataName == "maxSession") {
      setMaxSession(inputValue);
    }

  }




  // function to preventing user to use -ve number
  const disableKey = (e) => {
    let key1 = "-";
    let key2 = "+";

    if (e.key.toLowerCase() == key1 || e.key.toLowerCase() == key2) {
      e.preventDefault();

      toast.warning(`Value can't be nagative !! use positive number`, {
        autoClose: 1500,
        theme: "colored",
      });

    }
  }




  // function to set data of min and max of odds,bookmaker,session
  const fetchDataForMinAndMaxAmount = async (gameId, sportId) => {
    let res = await fetch(`https://api.betcare.live/api/checkAndSendData/matchSettingPage/${gameId}/${sportId}`);
    let resData = await res.json();


    // console.log(resData);
    setGameId(gameId);

    // setting data from api
    setMinOdds(resData[0].MinOddLimit);
    setMaxOdds(resData[0].MaxOddLimit);
    setMinBookmaker(resData[0].MinBookmakerLimit);
    setMaxBookmaker(resData[0].MaxBookmakerLimit);
    setMinSession(resData[0].MinFancyLimit);
    setMaxSession(resData[0].MaxFancyLimit);
  }




  // function to update the min max data of odds , bookmaker, session
  const updateDataForMinAndMaxAmount = async () => {
    try {

      let res = await fetch(`https://api.betcare.live/api/checkAndUpdateData/matchSettingPage/${gameId}/${minOdds}/${maxOdds}/${minBookmaker}/${maxBookmaker}/${minSession}/${maxSession}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        }
      });


      toast.success(`Your work has been saved !!`, {
        autoClose: 1500,
        theme: "colored",
      });
    }
    catch (error) {
      console.log("error in connecting the api");
    }

  }









  const onChangeActiveHandler = (e) => {
    console.log(e.target.value);
    setShowActiveData(e.target.value);
    setSearchWord('')

  }


  const onChangeSportHandler = (e) => {
    console.log(e.target.value);
    setSportStatus(e.target.value);
    setSearchWord('')
  }








  // function to update search word
  const handleSearchFunc = async (e) => {
    e.preventDefault();
    let res = await fetch(`https://api.betcare.live/api/getdataBySearch/${showActiveData}/${sportStatus}?q=${searchWord}&page=${page}&pageSize=${pageSize}`);
    let resData = await res.json();
    setData(resData.data);


    const totalRows = resData.totalRow[0].row_count;
    const totalPages = Math.ceil(totalRows / pageSize);
    setTotalPage(totalPages);
    console.log("total page : ", totalPage);
    // setTotalNoOfBetData(Math.ceil (resData.totalRow[0].row_count/10));

    let totalNumber = Math.ceil(resData.totalRow[0].row_count / 10);

    const newArray = Array.from({ length: totalPages }, (_, index) => index + 1);
    setPagesNumber(newArray);
    console.log("page Numbers : ", pagesNumber);

  }


  // function to fetch all data from backend
  const fetchData = async () => {
    let res = await fetch(`https://api.betcare.live/api/getMatchEventData/${showActiveData}/${sportStatus}/?page=${page}&pageSize=${pageSize}`);
    let resData = await res.json();
    //  console.log(resData);
    setData(resData.data);

    const totalRows = resData.totalRow[0].row_count;
    const totalPages = Math.ceil(totalRows / pageSize);
    setTotalPage(totalPages);
    console.log("total page : ", totalPage);
    // setTotalNoOfBetData(Math.ceil (resData.totalRow[0].row_count/10));

    let totalNumber = Math.ceil(resData.totalRow[0].row_count / 10);

    const newArray = Array.from({ length: totalPages }, (_, index) => index + 1);
    setPagesNumber(newArray);
    console.log("page Numbers : ", pagesNumber);

  }

  // Function to get the page numbers to display
  const getDisplayedPages = () => {
    const halfWay = Math.ceil(displayPageCount / 2);
    let start = Math.max(page - halfWay, 1);
    let end = Math.min(start + displayPageCount - 1, totalPage);

    if (end - start + 1 < displayPageCount) {
      start = Math.max(end - displayPageCount + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }


  // updating status in sportevent table
  const updateSportStatus = async (event, id, whichToUpdate) => {
    event.preventDefault();



    let status;
    event.target.checked ? status = 1 : status = 0;




    toast.success(`Your work has been saved !!`, {
      autoClose: 1500,
      theme: "colored",
    });
    setIsActive((prev => !prev));


    setTimeout(() => {
      window.location.reload();

    }, 2000)


    let res = await fetch(`https://api.betcare.live/api/updateMatchSettingData/${id}/${status}/${whichToUpdate}`,
      { method: 'PUT' });



  }



  const logoutSetDataFunc = async () => {
    let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
  }


  useEffect(() => {
    fetchData();
  }, [isActive, showActiveData, sportStatus, searchWord, page])




  useEffect(() => {
    setPage(1);
  }, [showActiveData, sportStatus, searchWord])


  // useEffect(() => {

  //   const fetchUserStatusCheck = async () => {
  //     let res = await fetch(`https://api.betcare.live/api/isUserIsLoggedIn/userstatus/${userStatusId}`);
  //     let resdata = await res.json();
  //     let userIsLoggedIn = resdata.message;
  //     console.log(userIsLoggedIn + " hey boyy");

  //     if (userIsLoggedIn == "false") {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('RoleId');
  //       localStorage.removeItem('UserName');
  //       localStorage.removeItem("currentDashboard");
  //       localStorage.removeItem("currentUserPages");
  //       // localStorage.removeItem("CurrentCurrency");
  //       setIsLoggedIn(false);
  //       logoutSetDataFunc();
  //       navigate("/login");
  //     }
  //   };

  //   fetchUserStatusCheck();

  // }, [])



  return (
    <>
      <main role="main" className="pb-3">
        {/* <div className="download-list">
          <div className="container mxw-100">
            <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div>
          </div>
        </div> */}
        <div className="inner-pages banking">
          <div className="container-fluid">
            <div className="page-title" style={{ fontSize: 16 }}>
              <h6 className="my-2">Match Setting</h6>
            </div>
            <form noValidate="" className="ng-untouched ng-pristine ng-valid">
              <div className="row marketdiv">
                <div className="col-lg-3">
                  <div className="form-group status-filter position-relative">
                    <div>
                      <label className="my-1 mr-2">Status</label>
                      <select
                        id="status"
                        name="status"
                        value={showActiveData}
                        onChange={onChangeActiveHandler}
                        className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                      >
                        <option value="all">All</option>
                        <option value="1" id="CheckAction">
                          Active
                        </option>
                        <option value="0" id="CheckAction">
                          Inactive
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group status-filter position-relative">
                    <div>
                      <label className="my-1 mr-2">Sport Name</label>
                      <select
                        id="sportOption"
                        name="sportId"
                        value={sportStatus}
                        onChange={onChangeSportHandler}
                        className="form-select mr-sm-2 ng-untouched ng-pristine ng-valid"
                      >
                        <option value="all">
                          All
                        </option>
                        <option value="1" className="ng-star-inserted">
                          Soccer
                        </option>
                        <option value="2" className="ng-star-inserted">
                          Tennis
                        </option>
                        <option value="4" className="ng-star-inserted">
                          Cricket
                        </option>
                        <option value="5" className="ng-star-inserted">
                          Casino
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group status-filter position-relative">
                    <label className="my-1 mr-2">Match Name</label>
                    <input
                      type="search"
                      placeholder=""
                      value={searchWord}
                      onChange={(e) => { setSearchWord(e.target.value) }}
                      id="searchMatch"
                      aria-controls="clientListTable"
                      className="form-control datatable-search"
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div>
                    <button
                      type="button"
                      value="Submit"
                      className="btn btnblk btn-sm w-auto ms-1 mt-4"
                      onClick={handleSearchFunc}
                    >
                      search
                    </button>
                    <span className="red" id="Error" />
                  </div>
                </div>
              </div>
            </form>
            <div />
            <div className="profile-section mt-3 unmatech-section">
              <div id="matchplist">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Match Name</th>
                      <th>Date</th>
                      <th>Setting</th>
                      <th>Match ON/OFF</th>
                      <th>Bet Lock ON/OFF</th>
                      <th>Match Odds ON/OFF</th>
                      <th>BookMaker ON/OFF</th>
                      <th>Fancy ON/OFF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((e, index) => {
                      return (

                        <tr className="ng-star-inserted" key={e.SrNo}>
                          <td>{(page - 1) * pageSize + index + 1}</td>
                          {/* <td hidden="">40020242643056181</td> */}
                          <td>{e.eventName}</td>
                          <td>{new Date(e.eventDate).toLocaleString()}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btnblk btn-sm w-auto ms-1"
                              data-toggle="modal"
                              onClick={() => { fetchDataForMinAndMaxAmount(e.gameId, e.eid) }}
                              data-target="#matchSetting"
                              style={{ textAlign: "center !important" }}
                            >
                              Setting
                            </button>
                          </td>
                          <td>
                            <div
                              className="form-check form-switch"
                              style={{ textAlign: "center !important" }}
                            >
                              <input
                                type="checkbox"
                                role="switch"
                                id="CheckAction_1"
                                checked={e.ActiveMatch}
                                onClick={(event) => { updateSportStatus(event, e.gameId, "ActiveMatch") }}
                                className="form-check-input CheckAction"
                                defaultChecked="checked"
                                title="Click here to deactivate match"
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className="form-check form-switch"
                              style={{ textAlign: "center !important" }}
                            >
                              <input
                                type="checkbox"
                                role="switch"
                                checked={e.IsBetLock}
                                onClick={(event) => { updateSportStatus(event, e.gameId, "IsBetLock") }}
                                id="CheckActionIsCup_1"
                                className="form-check-input CheckActionIsCup"
                                title="Click here to activate isCup"
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className="form-check form-switch"
                              style={{ textAlign: "center !important" }}
                            >
                              <input
                                type="checkbox"
                                role="switch"
                                checked={e.Matchodds}
                                onClick={(event) => { updateSportStatus(event, e.gameId, "Matchodds") }}
                                id="CheckActionBetLock_1"
                                className="form-check-input CheckActionBetLock"
                                title="Click here to activate betLock"
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className="form-check form-switch"
                              style={{ textAlign: "center !important" }}
                            >
                              <input
                                type="checkbox"
                                role="switch"
                                checked={e.BookMaker}
                                onClick={(event) => { updateSportStatus(event, e.gameId, "BookMaker") }}
                                id="CheckActionIsCup_1"
                                className="form-check-input CheckActionIsCup"
                                title="Click here to activate isCup"
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className="form-check form-switch"
                              style={{ textAlign: "center !important" }}
                            >
                              <input
                                type="checkbox"
                                role="switch"
                                checked={e.Fancy}
                                onClick={(event) => { updateSportStatus(event, e.gameId, "Fancy") }}
                                id="CheckActionBetLock_1"
                                className="form-check-input CheckActionBetLock"
                                title="Click here to activate betLock"
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div style={{ margin: "20px", display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                  <button
                    style={{ padding: "5px 10px", background: "white" }}
                    onClick={() => setPage(prevPage => Math.max(prevPage - 1, 1))}
                    disabled={page === 1}
                  >
                    Previous
                  </button>

                  {getDisplayedPages().map(pageNumber => (
                    <button
                      key={pageNumber}
                      onClick={() => setPage(pageNumber)}
                      style={page === pageNumber
                        ? { padding: "5px 10px", background: "black", color: "wheat" }
                        : { padding: "5px 10px", background: "white" }}
                    >
                      {pageNumber}
                    </button>
                  ))}

                  <button
                    style={{ padding: "5px 10px", background: "white" }}
                    onClick={() => setPage(prevPage => Math.min(prevPage + 1, totalPage))}
                    disabled={page === totalPage}
                  >
                    Next
                  </button>
                </div>
                <div
                  id="matchSetting"
                  tabIndex={-1}
                  aria-labelledby="ChangeStatusLabel"
                  className="modal fade changepassword-model show"
                  aria-modal="true"
                  role="dialog"
                  style={{ display: "none" }}
                >
                  <div className="modal-dialog match-setting-model">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h6 id="AddAdminLabel" className="modal-title">
                          Match Setting
                        </h6>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          <i
                            className="fa fa-times close-icon"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <div className="modal-body">
                        <form noValidate="" className="">
                          {/* <div className="form-group">
                        <label htmlFor="scoreType">Score Type:</label>
                        <br />
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="ballByball"
                            name="score_type"
                            defaultValue={0}
                            formcontrolname="score_type"
                            className="custom-control-input"
                          />
                          <label
                            htmlFor="ballByball"
                            className="custom-control-label"
                          >
                            Ball by Ball
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="betfair"
                            name="score_type"
                            defaultValue={1}
                            formcontrolname="score_type"
                            className="custom-control-input"
                          />
                          <label
                            htmlFor="betfair"
                            className="custom-control-label"
                          >
                            Betfair
                          </label>
                        </div>
                      </div> */}
                          <div className="row">
                            <div className="col-sm-6 pe-2">
                              <div className="form-group">
                                <label htmlFor="scorekey">Match Min Limit:</label>
                                <input
                                  type="number"
                                  min={0}
                                  name='minOdds'
                                  value={minOdds}
                                  onChange={handleOnChange}
                                  onKeyDown={disableKey}
                                  id="scorekey"
                                  formcontrolname="score_key"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 ps-2">
                              <div className="form-group">
                                <label htmlFor="scorekey">Match Max Limit:</label>
                                <input
                                  type="number"
                                  min={0}
                                  name='maxOdds'
                                  value={maxOdds}
                                  onChange={handleOnChange}
                                  onKeyDown={disableKey}
                                  id="scorekey"
                                  formcontrolname="matchMax"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 pe-2">
                              <div className="form-group">
                                <label htmlFor="scorekey">Bookmaker Min Limit:</label>
                                <input
                                  type="number"
                                  name='minBookmaker'
                                  value={minBookmaker}
                                  onChange={handleOnChange}
                                  min={0}
                                  onKeyDown={disableKey}
                                  id="scorekey"
                                  formcontrolname="matchMin"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 ps-2">
                              <div className="form-group">
                                <label htmlFor="scorekey">Bookmaker Max Limit:</label>
                                <input
                                  type="number"
                                  name='maxBookmaker'
                                  value={maxBookmaker}
                                  onChange={handleOnChange}
                                  min={0}
                                  onKeyDown={disableKey}
                                  id="scorekey"
                                  formcontrolname="sessionMax"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 pe-2">
                              <div className="form-group">
                                <label htmlFor="scorekey">Session Min Limit:</label>
                                <input
                                  type="number"
                                  name='minSession'
                                  value={minSession}
                                  onChange={handleOnChange}
                                  min={0}
                                  onKeyDown={disableKey}
                                  id="scorekey"
                                  formcontrolname="sessionMin"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 ps-2">
                              <div className="form-group">
                                <label htmlFor="scorekey">
                                  Session Max Stack:
                                </label>
                                <input
                                  type="number"
                                  name='maxSession'
                                  value={maxSession}
                                  onChange={handleOnChange}
                                  min={0}
                                  onKeyDown={disableKey}
                                  id="scorekey"
                                  formcontrolname="bookmaker_max_stack"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="modal-footer">
                          <button data-dismiss="modal" className="btn btn-danger">
                            Cancel
                          </button>
                          <button data-dismiss="modal" onClick={updateDataForMinAndMaxAmount} className="btn btn-success">Update</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list"></div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
    </>

  )
}

export default MatchSetting
