import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment';

export default function DepositRequest() {


    const navigate = useNavigate();
    const context = useContext(currentUser);
    const { isLoggedIn } = context;
    const userIdLS = localStorage.getItem(`UserId`);



    const [allData, setAllData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [totalrows, setTotalrows] = useState(0);
    const [changeActionTo, setChangeActionTo] = useState(0);
    const [selectedUser, setSelectedUser] = useState({ name: "", RoleId: 0, userstatus: 0, id: 0 });
    const [comment, setComment] = useState('');
    const [fetchedPaymentDetail, setFetchedPaymentDetail] = useState([]);


    const fetchSelfUserDepositRequest = async () => {

        try {
            setIsLoading(true);
            var res = await fetch(`https://api.betcare.live/admin/api/selfUser/fetchDeposit`);
            let resData = await res.json();

            console.log("self user deposit data : ", resData)

            setAllData(resData.data)
            setTotalrows(resData.data.length);

        } catch (error) {
            console.error("Error in get Self User : ", error);
        } finally {
            setIsLoading(false)
        }
    }

    const updatingStatusOfUser = async () => {

        console.log("Selected User : ", selectedUser)


        let res = await fetch(`https://api.betcare.live/admin/api/selfUser/changeDepositStatus/${selectedUser.id}/${changeActionTo == 0 ? selectedUser.userstatus : changeActionTo}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ loggedInUserId: userIdLS, comment: comment, srno: selectedUser.srno })
        });

        let resData = await res.json();

        if (res.status === 200) {
            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
            fetchSelfUserDepositRequest();
            setChangeActionTo(0);
            setComment('');

        } else {
            toast.error(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
        }
    }

    const fetchPaymentDetail = async (id, userId) => {
        try {
            const res = await fetch(`https://api.betcare.live/admin/api/selfUser/fetchPayDetail/${id}/${userId}`);
            const resData = await res.json();
            if (res.ok) {
                console.log('fetched payment : ', resData);
                if (resData?.data?.length > 0) {
                    setFetchedPaymentDetail(resData?.data);
                }
            }
        } catch (error) {
            console.log('Error while fetching detail : ', error);
        }
    }


    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
            return;
        }

        if (isLoggedIn === "false") {
            navigate("/login");
            return;
        }

        fetchSelfUserDepositRequest();

    }, [isLoggedIn, page])

    return (
        <>
            <style>{`
    div::-webkit-scrollbar {
  display: none;
}
    `}</style>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">
                <div className="inner-pages">
                    <div className="ng-untouched ng-pristine ng-valid">
                        <div className="container-fluid">
                            <div className="page-title" style={{ fontSize: 16 }}>
                                <h6 className="my-2">Deposit Request </h6>
                            </div>
                            <div className="download-list">
                                <div className="">
                                    <div className="profile-section mt-3">
                                        <div className="table-responsive  text-end">
                                            <table className="table table01">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Sr No</th>
                                                        <th>Date</th>
                                                        <th>User ID</th>
                                                        <th>User Name</th>
                                                        <th>Amount</th>
                                                        {/* <th>Bonus</th> */}
                                                        <th>Payment Detail</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData?.length > 0 && allData?.map((item, index) => (

                                                        <tr className="ng-star-inserted" key={item.id}>
                                                            <td className=" ng-star-inserted">{index + 1}</td>
                                                            <td>
                                                                {moment(item.RequestDate).format('DD MMM h:mm A')}
                                                            </td>
                                                            <td>{item.UserID}</td>
                                                            <td>{item.UserName}</td>
                                                            <td>{item.Amount}</td>
                                                            {/* <td>{item.Bonus}</td> */}
                                                            <td>
                                                                <button className='px-2 py-1 border rounded'
                                                                    style={{
                                                                        backgroundColor: '#71b8e4',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    data-toggle="modal" data-target="#checkPaymentDetailModal"
                                                                    onClick={() => { fetchPaymentDetail(item.SrNo, item.UserID) }}
                                                                >
                                                                    Detail
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <span className={item.Status == 1 ? "activebtn" : item.Status == 2 ? 'pendingbtn' : item.Status == 3 ? 'suspendbtn' : 'activebtn'} style={{ marginLeft: "10px" }}>{item.Status == 1 ? 'Approved' : item.Status == 2 ? 'Pending' : item.Status == 3 ? 'Rejected' : 'Status'}</span>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#changeActionModal"
                                                                    onClick={() => { setSelectedUser({ name: item.UserName, RoleId: 8, userstatus: item.Status, id: item.UserID, srno: item.SrNo }) }}>Action</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Check payment detail modal */}
                            <div
                                id="checkPaymentDetailModal"
                                className="modal fade changepassword-model"
                                style={{ display: "none" }}
                                data-backdrop="static"
                                aria-modal="true"
                                role="dialog"
                            >
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content w-100">
                                        <div className="modal-header">
                                            <h6 id="logmodelLabel" className="modal-title">
                                                Payment Detail
                                            </h6>
                                            <button type="button"
                                                className="close p-1"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid',
                                                    fontWeight: 'bolder'
                                                }}
                                                onClick={() => {
                                                    setFetchedPaymentDetail([])
                                                }}
                                                data-dismiss="modal">
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status" id="statementList">
                                            <div className="profile-section mt-0 unmatech-section">

                                                {fetchedPaymentDetail?.length > 0 && fetchedPaymentDetail?.map((item) => (

                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                        <div className='p-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '1rem', width: '100%' }}>
                                                            <div style={{ fontSize: '1.4em' }}>
                                                                <p><b>Request Date : </b>{moment(item.RequestDate).format('DD MMM h:mm A')}</p>
                                                                <p><b>Deposit Amount : </b>{item.Amount}</p>
                                                                <p><b>Display Name : </b>{item.DisplayName}</p>
                                                                <p><b>Number : </b>{item.AccountNumber}</p>
                                                                <p><b>Payment Type : </b>{item.Bank}</p>
                                                                <p><b>Account Type : </b>{item.PaymentSubType}</p>
                                                                {/* <p><b>IFCS Code : </b>{item.IFSC}</p>
                                                                <p><b>UPI ID : </b>{item.UPIID}</p> */}
                                                                <p><b>Transaction Id : </b>{item.UTRNo}</p>
                                                            </div>
                                                        </div>

                                                        <div style={{ width: '100%', height: '', border: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        >
                                                            <img src={`/payment/${item.fileName}`} alt="" srcset="" style={{
                                                                width: '20em',
                                                                maxWidth: '100%',
                                                                height: '100%',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center',

                                                            }} />

                                                        </div>


                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Change status pop start */}
                            <div
                                id="changeActionModal"
                                tabindex="-1"
                                aria-labelledby="ChangeStatusLabel"
                                className="modal fade changepassword-model show"
                                data-backdrop="static"
                                data-keyboard="false"
                                role="dialog"
                                style={{ display: "none" }}
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h6 id="ChangeStatusLabel" className="modal-title">
                                                Change Status
                                            </h6>
                                            {/* <button
                        type="button" data-bs-dismiss="modal" aria-label="Close"
                        class="btn-close"><i class="bi bi-x">X</i></button> */}
                                            <button type="button"
                                                className="close"
                                                onClick={() => {
                                                    // setUserTypesPassword("");
                                                    setChangeActionTo(0)
                                                    setComment('');
                                                }}
                                                data-dismiss="modal">
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status">
                                            <div className="change-st-box">
                                                <div className="chnage-st-name d-flex justify-content-between align-items-center">
                                                    <p className="mb-0">
                                                        {/* selectedUser.RoleId] */}
                                                        <span className="account-name-specail-txt">PL</span>
                                                        <b>{selectedUser.name}</b>
                                                        {/* selectedUser.name */}
                                                    </p>
                                                    <a className={selectedUser.userstatus === 1 ? "activebtn" : selectedUser.userstatus === 2 ? "pendingbtn" : "suspendbtn"}>
                                                        {selectedUser.userstatus === 1 && "Approved"}
                                                        {selectedUser.userstatus === 2 && "Pending"}
                                                        {selectedUser.userstatus === 3 && "Reject"}
                                                    </a>
                                                </div>

                                                <div className="status-button-group status_but">
                                                    <input type="hidden" name="UserId" id="statusUserId" />
                                                    <input type="hidden" name="Status" id="statusStatus" />

                                                    <button
                                                        className={`status-button but_active ${changeActionTo === 1 && "open"}`}
                                                        id="activeBtn"
                                                        disabled={selectedUser.userstatus === 1 && true}
                                                        // href="#active"
                                                        style={selectedUser.userstatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        data-status="active"

                                                        onClick={() => { setChangeActionTo(1) }}
                                                    // style={{background:"green"}}
                                                    >
                                                        <div className="status-active " style={selectedUser.userstatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }} >
                                                            <div className="sts-icon">
                                                                <span className="icon">
                                                                    <i
                                                                        className="fa fa-check-circle-o"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </div>{" "}
                                                            Approve
                                                        </div>
                                                    </button>

                                                    <button
                                                        className={`status-button but_suspend ${changeActionTo === 3 && "open"}`}
                                                        style={selectedUser.userstatus === 3 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        disabled={selectedUser.userstatus === 3 && true}
                                                        id="suspendBtn"
                                                        // href="#active"

                                                        onClick={() => { setChangeActionTo(3) }}
                                                        data-status="suspend"
                                                    >
                                                        <div className="status-disbled" style={selectedUser.userstatus === 3 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                                                            <div className="sts-icon">
                                                                <span className="icon">
                                                                    <i className="fa fa-ban" aria-hidden="true" />
                                                                </span>
                                                            </div>{" "}
                                                            Reject
                                                        </div>
                                                    </button>

                                                </div>
                                            </div>
                                            <dl className="form_list d-flex mt-3">
                                                <dt>Comment </dt>
                                                <dd className="mb-2">
                                                    <textarea
                                                        placeholder="Comment"
                                                        className="ng-untouched ng-pristine ng-valid"
                                                        value={comment}
                                                        onChange={(e) => setComment(e.target.value)} />
                                                </dd>
                                                <dt />
                                                <dd className="w-100 text-center">
                                                    <a
                                                        id="changePasswordBtn1"
                                                        title="Change"
                                                        onClick={updatingStatusOfUser}
                                                        className="btn btn2 px-4"
                                                        data-dismiss="modal"
                                                    >
                                                        Change
                                                    </a>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
