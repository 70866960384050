import React, { useEffect, useState } from 'react';

function OnlineUser() {
    const userIdLS = localStorage.getItem('UserId');
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const res = await fetch(`https://api.betcare.live/api/admin/onlineUsers/${userIdLS}`);
            const resData = await res.json();

            if (resData.isSuccess) {
                console.log('data : ', resData.data);
                setData(resData.data);
            }
        } catch (error) {
            console.error('Error while fetching online user : ', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const roleLabels = {
        2: 'Tech Admin',
        3: 'SuperAdmin',
        4: 'SubAdmin',
        5: 'SuperSuper',
        6: 'Super',
        7: 'Master',
        8: 'User'
    };

    return (
        <main role="main" className="pb-3">
            <div className="account-pages">
                <div className="container mxw-100">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div id="logList">
                                <div className="myaccount-page">
                                    <div className="row">
                                        {Object.keys(roleLabels).map((roleId) => {
                                            const filteredData = data.filter(item => item.RoleId == roleId);
                                            if (filteredData.length > 0) {
                                                return (
                                                    <div key={roleId} className="col-lg-12 text-left mb-4">
                                                        <div className="pagettl mb-3">{roleLabels[roleId]}</div>
                                                        <div className="profile-section">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sr</th>
                                                                            <th>UserId</th>
                                                                            <th>UserName</th>
                                                                            <th>Login Date & Time</th>
                                                                            <th>Login Status</th>
                                                                            <th>IP Address</th>
                                                                            <th>City/State/Country</th>
                                                                            {/* <th>User Agent Type</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {filteredData.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{item.Id}</td>
                                                                                <td>{item.UserName}</td>
                                                                                <td>{item.LoginTime}</td>
                                                                                <td>True</td>
                                                                                <td>{item.IpAddress}</td>
                                                                                <td>{item.BrowserInfo}</td>
                                                                                {/* <td>{item.RoleId < 8 ? 'Parent' : 'Child'}</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default OnlineUser;
