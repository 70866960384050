import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import currentUser from '../../context/currentUser/currentUser';
import { FiPlusSquare } from "react-icons/fi";
import { FaRegSquareMinus } from "react-icons/fa6";
function SelfUserProfitLoss() {

    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterdat1 = new Date();
    let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let yesterday = YesterdayM.format(`YYYY-MM-DD`);


    const [allData, setAllData] = useState([])
    const [openMoreIndex, setOpenMoreIndex] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [fromDate, setFromDate] = useState(today);
    const [fromTime, setFromTime] = useState(`00:00:00`);
    const [toDate, setToDate] = useState(Tommorrow);
    const [toTime, setToTime] = useState(`23:59:59`);
    const [loadPage, setLoadPage] = useState(false);
    const [totalStake, settotalStake] = useState(0);
    const [totalUserPLAmount, setTotalUserPLAmount] = useState(0);
    const [calcBalance, setcalcBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalrows, setTotalrows] = useState(0)



    const context = useContext(currentUser);
    const { isLoggedIn } = context;



    const UserRoleShortName = {
        1: "DM",
        2: "TA",
        3: "SA",
        4: "SB",
        5: "SS",
        6: "SU",
        7: "MA",
        8: "PL"
    }


    const fetchChildData = async () => {
        try {
            setIsLoading(true);

            // Fetch profit loss of all self users users for pagination
            let fetchExpo = await fetch(`https://api.betcare.live/api/admin/selfUser/profitLossForSelfUser?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`);
            let fetchExpoData = await fetchExpo.json();

            if (fetchExpoData.isSuccess) {
                console.log("Calc expos call..! : ", fetchExpoData);
                const startIndex = (page - 1) * pageSize;
                const endIndex = startIndex + pageSize;
                const currentPageData = fetchExpoData?.data?.slice(startIndex, endIndex);
                setAllData(currentPageData);
                setTotalrows(fetchExpoData?.data?.length);
            } else {
                console.log("Error in calcExposOfAllUser");
            }

        } catch (error) {
            console.error("Error fetching downline");
        } finally {
            setIsLoading(false);
        }
    };



    const calcTotal = () => {
        let calctotalStake = 0;
        let totalUserPL = 0;
        let balance = 0;

        allData.forEach((item) => {
            calctotalStake += item.UserStake != null ? Number(item.UserStake) : 0;
            totalUserPL += item.UserPL != null ? Number(item.UserPL) : 0;
        });

        settotalStake(calctotalStake)
        setTotalUserPLAmount(totalUserPL)
        setcalcBalance(balance)


    }



    const setDateFunc = async (btnName) => {

        if (btnName === 'today') {
            setFromDate(today);
            setFromTime(`00:00:00`);
            setToDate(Tommorrow);
            setToTime(`12:59:59`);
            setPage(1);
            setLoadPage((prev) => !prev);

        } else if (btnName === "yesterday") {

            setFromDate(yesterday);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);

        }
    }

    const onChangeHandler = (e, dateTimeType) => {
        if (dateTimeType === "fromDate") {
            setFromDate(e.target.value);
        }
        else if (dateTimeType === "fromTime") {
            setFromTime(e.target.value);
        }
        else if (dateTimeType === "toDate") {
            setToDate(e.target.value);
        }
        else if (dateTimeType === "toTime") {
            setToTime(e.target.value);
        }
    }

    async function getPLMarket() {
        setLoadPage((prev) => !prev);
        setPage(1);
    }

    useEffect(() => {
        fetchChildData();
    }, [isLoggedIn, loadPage, page])


    useEffect(() => {
        calcTotal();
    }, [allData])



    return (
        <>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <div className="inner-pages">
                <div className="container mxw-100">
                    <div className="page-title" style={{ fontSize: 16 }}>
                        <h6 className="my-2">Profit/Loss Report by Downline </h6>
                    </div>
                    <div className="my-report">
                        <div className="time-zone-section">
                            <ul className="list-unstyled">
                                <li>
                                    <label className="my-1 mr-2 fw-normal">Period</label>
                                    <input
                                        type="date"
                                        formcontrolname="periodFormDate"
                                        value={fromDate}
                                        onChange={(e) => { onChangeHandler(e, "fromDate") }}
                                        id="sdate"
                                        className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                        style={{ width: '100%' }}
                                    />
                                    {/* <input
                                    type="time"
                                    placeholder="9:00:00"
                                    formcontrolname="periodFormTime"
                                    value={fromTime}
                                    onChange={(e) => { onChangeHandler(e, "fromTime") }}
                                    id="stime"
                                    ng-model="datetime.time"
                                    className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                /> */}
                                    <span className="d-inline-flex mx-2">To</span>
                                    <input
                                        type="date"
                                        formcontrolname="periodtoDate"
                                        value={toDate}
                                        onChange={(e) => { onChangeHandler(e, "toDate") }}
                                        id="edate"
                                        className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                        style={{ width: '100%' }}
                                    />
                                    {/* <input
                                    type="time"
                                    placeholder="8:59:59"
                                    id="etime"
                                    formcontrolname="periodtotime"
                                    value={toTime}
                                    onChange={(e) => { onChangeHandler(e, "toTime") }}
                                    className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                /> */}
                                </li>
                            </ul>
                            <ul className="list-unstyled report-btn-group">
                                <li>
                                    <button
                                        type="button"
                                        className={`${fromDate === today ? toDate === Tommorrow ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                        onClick={() => { setDateFunc(`today`) }}
                                    >
                                        Just For Today
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`${fromDate === yesterday ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                        onClick={() => { setDateFunc(`yesterday`) }}
                                    >
                                        From Yesterday
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="submit"
                                        onClick={getPLMarket}
                                        className="btnblk"
                                    >
                                        Get P &amp; L
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div id="DownlineList">
                        <div className="profile-section mt-4 ng-star-inserted">
                            <div className="table-responsive">
                                <table className="table table-pt ng-star-inserted">
                                    <thead>
                                        <tr>
                                            <th>UID</th>
                                            <th style={{ textAlign: "right" }}>Stake</th>
                                            <th style={{ textAlign: "right" }}>Player P/L </th>
                                            {/* <th style={{ textAlign: "right" }} className="ng-star-inserted">
                                                Downline PL
                                            </th>
                                            <th style={{ textAlign: "right" }}>Super Comm.</th>
                                            <th style={{ textAlign: "right" }}>Upline P/L</th> */}
                                        </tr>
                                    </thead>
                                    {allData.map((item, index) => {
                                        let status = item.parentStatus;
                                        if (item.parentStatus < item.Status) {
                                            status = item.Status;
                                        }
                                        return (
                                            <tbody key={item.Id}>
                                                <tr className="ng-star-inserted">
                                                    <td id={`userFullName_${index}`} className="text-start ng-star-inserted">
                                                        <span>
                                                            {openMoreIndex === index ? (
                                                                <FaRegSquareMinus
                                                                    className="cursor-pointer"
                                                                    style={{ fontSize: "15px", cursor: "pointer" }}
                                                                    onClick={() => setOpenMoreIndex(null)}
                                                                />
                                                            ) : (
                                                                <FiPlusSquare
                                                                    className="cursor-pointer"
                                                                    style={{ fontSize: "15px", cursor: "pointer" }}
                                                                    onClick={() => setOpenMoreIndex(index)}
                                                                />
                                                            )}
                                                        </span>
                                                        <span className="lv_1">{UserRoleShortName[item.RoleId]}</span>{" "}
                                                        <a
                                                            href="javascript:void(0);"
                                                        >
                                                            {item.UserName}
                                                        </a>
                                                    </td>
                                                    <td className={item.UserStake < 0 ? "red" : ""}>
                                                        {item.UserStake != null ? item.UserStake : 0}
                                                    </td>

                                                    <td className={item.UserPL < 0 ? "red" : ""}>
                                                        {item.UserPL != null ? item.UserPL : 0}
                                                    </td>
                                                    {/* <td className={item.UserPL < 0 ? "red" : ""}>
                                                        {item.UserPL != null ? item.UserPL : 0}
                                                    </td>
                                                    <td>0.00</td>
                                                    <td className={item.UserPL < 0 ? "red" : ""}>
                                                        {item.UserPL != null ? item.UserPL : 0}
                                                    </td> */}
                                                </tr>
                                                {openMoreIndex === index && (
                                                    <>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "left" }}> TENNIS </td>
                                                            <td > {item.TennisStake != null ? item.TennisStake : 0}</td>
                                                            <td className={item.TennisPL < 0 ? 'red' : ''}>{item.TennisPL != null ? item.TennisPL : 0}</td>
                                                            {/* <td className={item.TennisPL < 0 ? 'red' : ''}>{item.TennisPL != null ? item.TennisPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.TennisPL < 0 ? 'red' : ''}>{item.TennisPL != null ? item.TennisPL : 0}</td> */}
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "left" }}> SOCCER </td>
                                                            <td > {item.SoccerStake != null ? item.SoccerStake : 0}</td>
                                                            <td className={item.SoccerPL < 0 ? 'red' : ''}>{item.SoccerPL != null ? item.SoccerPL : 0}</td>
                                                            {/* <td className={item.SoccerPL < 0 ? 'red' : ''}>{item.SoccerPL != null ? item.SoccerPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.SoccerPL < 0 ? 'red' : ''}>{item.SoccerPL != null ? item.SoccerPL : 0}</td> */}
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "left" }}> Cricket-Bookmaker </td>
                                                            <td > {item.CricBMStake != null ? item.CricBMStake : 0}</td>
                                                            <td className={item.CricBMPL < 0 ? 'red' : ''}>{item.CricBMPL != null ? item.CricBMPL : 0}</td>
                                                            {/* <td className={item.CricBMPL < 0 ? 'red' : ''}>{item.CricBMPL != null ? item.CricBMPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.CricBMPL < 0 ? 'red' : ''}>{item.CricBMPL != null ? item.CricBMPL : 0}</td> */}
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "right" }}> Cricket-Fancy </td>
                                                            <td> {item.CricFCStake != null ? item.CricFCStake : 0}</td>
                                                            <td className={item.CricFCPL < 0 ? 'red' : ''}>{item.CricFCPL != null ? item.CricFCPL : 0}</td>
                                                            {/* <td className={item.CricFCPL < 0 ? 'red' : ''}>{item.CricFCPL != null ? item.CricFCPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.CricFCPL < 0 ? 'red' : ''}>{item.CricFCPL != null ? item.CricFCPL : 0}</td> */}
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start ' style={{ textAlign: "right" }}> Cricket </td>
                                                            <td > {item.CricMOStake != null ? item.CricMOStake : 0}</td>
                                                            <td className={item.CricMOPL < 0 ? 'red' : ''}>{item.CricMOPL != null ? item.CricMOPL : 0}</td>
                                                            {/* <td className={item.CricMOPL < 0 ? 'red' : ''}>{item.CricMOPL != null ? item.CricMOPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.CricMOPL < 0 ? 'red' : ''}>{item.CricMOPL != null ? item.CricMOPL : 0}</td> */}
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        )
                                    })}
                                    <tr className="total fw-bold banking-table-footer">
                                        <td className="align-L">Total</td>
                                        <td id="totalBalance"> {totalStake}</td>
                                        <td id="totalAvailableBalance">{totalUserPLAmount}</td>
                                        {/* <td id="totalExposure">{totalUserPLAmount}</td>
                                        <td
                                            id="totalCreditReference"
                                            colSpan={2}
                                            className="text-center"
                                        >
                                            {totalUserPLAmount}
                                        </td> */}
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination-controls className="text-center">
                    <pagination-template>
                        <nav aria-label="Pagination">
                            <ul className="ngx-pagination ng-star-inserted">
                                <li
                                    className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                                >
                                    <button
                                        className="ng-star-inserted"
                                        style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                        onClick={() => { setPage((prev) => prev - 1) }}
                                        disabled={page === 1 && true}
                                    >
                                        Previous{" "}
                                    </button>
                                </li>
                                <li
                                    className={`pagination-next  ${page === Math.ceil(totalrows / pageSize) ? "disabled" : totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                                >
                                    <button
                                        className="ng-star-inserted"
                                        style={page === Math.ceil(totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                        onClick={() => setPage((prev) => prev + 1)}
                                        disabled={page === Math.ceil(totalrows / pageSize) ? true : totalrows <= 20 ? true : false}
                                    >Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </pagination-template>
                </pagination-controls>
            </div>
        </>

    )
}

export default SelfUserProfitLoss