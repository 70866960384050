import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../../context/currentUser/currentUser';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';

function SelfUserList() {


  const [allData, setAllData] = useState([])
  const [userStatus, setUserStatus] = useState(0);

  // to store the detail of selected user in case of changing status
  const [selectedUser, setSelectedUser] = useState({ name: "", RoleId: 0, userstatus: 0, id: 0 });





  // console.log(selectedUser);

  // it will store the current status for the selected user
  const [currentStatus, setCurrentStatus] = useState(selectedUser.userstatus);

  // it will store the status that user need
  const [changeStatusTo, setChangeStatusTo] = useState(0);

  // it will store the password that user typed in status change model
  const [userTypedPassword, setUserTypesPassword] = useState("");
  const [totalExposure, settotalExposure] = useState(0);
  const [calcBalance, setcalcBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [totalrows, setTotalrows] = useState(0);
  const [totalUserBalance, setTotalUserBalance] = useState(0);
  const [totalUserExposure, setTotalUserExposure] = useState(0);






  const context = useContext(currentUser);
  const { isLoggedIn, currentDashboard, currentCurrency } = context;

  const userIdLS = localStorage.getItem(`UserId`);
  const navigate = useNavigate();
  //  console.log(name);

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  }


  const fetchSelfUserCalcuation = async () => {

    try {
      setIsLoading(true);
      var res = await fetch(`https://api.betcare.live/api/selfUser/calculateTotals`);
      let resData = await res.json();

      console.log("self user data calculations : ", resData)

      // setAllData(resData.data);
      setTotalUserBalance(resData?.totalAllUserMainBalanceAfterExpo);
      setTotalUserExposure(resData?.totalAllUserExposure);
      // setTotalrows(resData.totalCount);

    } catch (error) {
      console.error("Error in get Self User : ", error);
    } finally {
      // setIsLoading(false)
    }
  }




  const fetchSelfUser = async () => {

    try {
      setIsLoading(true);
      var res = await fetch(`https://api.betcare.live/api/selfUser/userList?page=${page}&status=${userStatus}`);
      let resData = await res.json();

      console.log("self user data : ", resData)

      setAllData(resData?.data);
      setTotalrows(resData?.totalCount);

    } catch (error) {
      console.error("Error in get Self User : ", error);
    } finally {
      setIsLoading(false)
    }
  }


  const funcToSetSelectedUser = (selectedUsername, selectedRoleId, selectedStatus, selectedId) => {

    console.log("data in funcToSeleUs : ", selectedUsername, selectedRoleId, selectedStatus, selectedId)


    const data = {
      name: selectedUsername,
      RoleId: selectedRoleId,
      userstatus: selectedStatus,
      id: selectedId
    }

    console.log("selecte user stting tab : ", data)

    setSelectedUser({
      name: selectedUsername,
      RoleId: selectedRoleId,
      userstatus: selectedStatus,
      id: selectedId
    })
  }




  const updatingStatusOfUser = async () => {

    console.log("Password : ", userTypedPassword)
    console.log("Selected User : ", selectedUser)


    let res = await fetch(`https://api.betcare.live/api/selfUser/changeSelfUserStatus/${selectedUser.id}/${changeStatusTo == 0 ? selectedUser.userstatus : changeStatusTo}`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ loggedInUserId: userIdLS, password: userTypedPassword })
    });

    let resData = await res.json();

    if (res.status === 200) {
      toast.success(`${resData.message}`, {
        autoClose: 1500,
        theme: "colored",
      });
      fetchSelfUser();

    } else {
      toast.error(`${resData.message}`, {
        autoClose: 1500,
        theme: "colored",
      });
    }
  }



  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    if (isLoggedIn === "false") {
      navigate("/login");
      return;
    }

    fetchSelfUser();

  }, [isLoggedIn, page, userStatus])



  useEffect(() => {
    setCurrentStatus(selectedUser.userstatus)
  }, [selectedUser]);

  useEffect(() => {
    fetchSelfUserCalcuation();
  }, []);




  return (

    isLoggedIn &&

    <>

      <style>{`
    div::-webkit-scrollbar {
  display: none;
}
    `}</style>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <main role="main" className="pb-3">
        <div className="download-list">
          <div className="container mxw-100">
            <div id="filterDiv">
              <div className="fiter-box">
                <div className="saerch-filter">
                  <div className="form-group position-relative">
                    <span className="serch-icon">
                      <i className="fa fa-search" aria-hidden="true" />
                    </span>
                    <input
                      type="search"
                      placeholder="Find Member.."
                      className="form-control ng-untouched ng-pristine ng-valid"
                      name="SearchTerm"
                      id="Searchingusername"
                    />
                    <button
                      type="submit"
                      className="seacrh-btn"
                      onclick="getUsersListBystatus()"
                      name="SearchTerm"
                    >
                      Search
                    </button>
                  </div>
                  <div className="form-group status-filter position-relative ml-2" style={{ marginRight: '6px' }}>
                    <div className="form-inline ng-untouched ng-pristine ng-valid m-0" style={{ display: 'flex', alignItems: 'center' }}>
                      <div><label className="my-1 mr-2">Status</label></div>

                      <div>   <select
                        name="betStatus"
                        className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                        id="betStatus"
                        value={userStatus}
                        onChange={(e) => { setUserStatus(e.target.value) }}
                      >
                        <option value={0}>All</option>
                        <option value={1}>Active</option>
                        <option value={3}>Suspend</option>
                        <option value={2}>Locked</option>
                      </select></div>

                    </div>
                  </div>
                  {/* <div className="ms-2 agent_path" style={{ width: '35vw', overflowX: 'scroll' }}>
                    <ul
                      id="agentPath"
                      className="agent_path-L mt-0 mb-0 mr-1 mb-0 ng-star-inserted"
                    >
                      {currentUserPages.map((e, index) => {


                        return (
                          <li style={{ cursor: "pointer" }} id="path_3" key={e.fullName}
                            onClick={() => { updatingCurrentUserPage(index, e.roleId, e.id, e.fullName, e.bal) }}
                            className="ng-star-inserted active">
                            <a >
                              <span className={`lv_${e.roleId - 2}`}> {UserRoleShortName[e.roleId]} </span>
                              <strong> {e.fullName}</strong>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div> */}
                </div>
                {/* <div className="right-add-admin" id="showBtn">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#AddAdmin"
                    onClick={() => { setAddUserState(UserRoleFullName[currentDashboard.roleId + 1]) }}
                    className="light-btn ng-star-inserted"
                  >
                    <i className="fa fa-user-plus mr-2" aria-hidden="true" />
                    <span id="crtBtn">Add {UserRoleFullName[currentDashboard.roleId + 1]}</span>
                  </button>
                  <button type="button" className="light-btn refreshbtn ml-2">
                    <i className="fa fa-history" aria-hidden="true" />
                  </button>
                </div> */}
              </div>
            </div>
            <div id="filterList">
              <div className="total-entries-selfUser">
                <div className="total-item-selfUser">
                  <span>Total User Balance</span>
                  <h6>{currentCurrency} {totalUserBalance !== null ? totalUserBalance : 0}</h6>
                </div>
                <div className="total-item-selfUser">
                  <span>Total User Exposure</span>
                  <h6>
                    {currentCurrency} <b className="red">{totalUserExposure !== null ? totalUserExposure : 0}</b>
                  </h6>
                </div>
                <div className="total-item-selfUser">
                  <span>Total Users</span>
                  <h6>{totalrows !== null ? totalrows : 0}</h6>
                </div>
              </div>
              <div className="profile-section mt-3">
                <div className="table-responsive  text-end">
                  <table className="table table01">
                    <thead>
                      <tr>
                        <th className="text-left">Account</th>
                        <th>Balance</th>
                        <th>Exposure</th>
                        <th>Referral</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData?.map((item) => {
                        let status = item.Status

                        let clickBalance = 0

                        if (item.RoleId !== 8) {
                          clickBalance += item.ResultAmountU != null ? item.ResultAmountU : 0;
                        } else {
                          clickBalance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
                            (item.UserPL != null ? item.UserPL : 0)
                        }



                        return (
                          <tr className="ng-star-inserted" key={item.Id}>
                            <td className="text-start ng-star-inserted">
                              <span
                                className="lv_1 account-name-specail-txt"
                                id="number"
                              >
                                {UserRoleShortName[item.RoleId]}
                              </span>{" "}
                              <a> {item.UserName}</a>
                            </td>
                            <td>
                              {item.mainBalanceafterExpo}
                              <a
                                href="javascript:void(0);"
                                className="table-expend-plus-btn"
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </a>
                            </td>
                            <td className="red">
                              <span>{item.FinalExpo}</span>
                            </td>
                            <td className="">
                              <span>{`${!item.refUserName ? '' : item.refUserName}${!item.ReferralCode ? '' : `(${item.ReferralCode})`}`}</span>
                            </td>
                            <td>
                              <a className={
                                status === 1 ? "activebtn" : status === 2 ? "blockedbtn" : "suspendbtn"
                              }>
                                {status === 1 && "Active"}
                                {status === 2 && "Blocked"}
                                {status === 3 && "Suspend"}
                              </a>
                            </td>
                            <td>
                              <div className="action-bnt-list justify-content-end align-items-center d-flex">
                                <Link
                                  to={"/bettingprofitloss"}
                                  className="icon d-inline-flex ng-star-inserted"
                                  state={currentDashboard !== null && { id: item.Id, roleId: item.RoleId, selfUser: item.selfUser }}
                                >
                                  <img src="../../../images/profit-btn.png" />
                                </Link>
                                <Link
                                  to={"/bettinghistory"}
                                  className="icon d-inline-flex ng-star-inserted"
                                  state={currentDashboard !== null && { id: item.Id, roleId: item.RoleId, selfUser: item.selfUser }}
                                >
                                  <img src="../../../images/history-btn.png" />
                                </Link>
                                <a
                                  data-toggle="modal"
                                  data-target="#changeStatusModal"
                                  // {name:"",RoleId:0,status:0,parentStatus:0,id:0}
                                  onClick={(e) => { e.preventDefault(); funcToSetSelectedUser(item.UserName, item.RoleId, item.Status, item.Id) }}
                                  type="button"
                                  className="icon d-inline-flex usersettingstatus"
                                >
                                  <img src="../../../images/setting-bet.png" />
                                </a>
                                <Link
                                  to={"/accountsummary"}
                                  state={{
                                    id: item.Id, roleId: item.RoleId, bal: item.mainBalanceafterExpo, expo: item.FinalExpo, selfUser: item.selfUser
                                  }}
                                  className="icon d-inline-flex"
                                >
                                  <img src="../../../images/profile-btn.png" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination-controls className="text-center">
                <pagination-template>
                  <nav aria-label="Pagination">
                    <ul className="ngx-pagination ng-star-inserted">
                      <li
                        className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                      >
                        <button
                          className="ng-star-inserted"
                          style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                          onClick={() => { setPage((prev) => prev - 1) }}
                          disabled={page === 1 && true}
                        >
                          Previous{" "}
                        </button>
                      </li>
                      <li
                        className={`pagination-next  ${page === Math.ceil(totalrows / pageSize) ? "disabled" : totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                      >
                        <button
                          // aria-disabled="true"
                          className="ng-star-inserted"
                          style={page === Math.ceil(totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                          onClick={() => setPage((prev) => prev + 1)}
                          disabled={page === Math.ceil(totalrows / pageSize) ? true : totalrows <= 20 ? true : false}
                        >Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </pagination-template>
              </pagination-controls>
            </div>
          </div>
        </div>
        {/* Change status pop start */}
        <div
          id="changeStatusModal"
          tabindex="-1"
          aria-labelledby="ChangeStatusLabel"
          className="modal fade changepassword-model show"
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          style={{ display: "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 id="ChangeStatusLabel" className="modal-title">
                  Change Status
                </h6>
                {/* <button
                        type="button" data-bs-dismiss="modal" aria-label="Close"
                        class="btn-close"><i class="bi bi-x">X</i></button> */}
                <button type="button"
                  className="close"
                  onClick={() => {
                    setUserTypesPassword("");
                    setChangeStatusTo(0)
                  }}
                  data-dismiss="modal">
                  X
                </button>
              </div>
              <div className="modal-body change-status">
                <div className="change-st-box">
                  <div className="chnage-st-name d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      {/* selectedUser.RoleId] */}
                      <span className="account-name-specail-txt">{UserRoleShortName[selectedUser.RoleId]}</span>
                      <b>{selectedUser.name}</b>
                      {/* selectedUser.name */}
                    </p>
                    <a className={currentStatus === 1 ? "activebtn" : currentStatus === 2 ? "blockedbtn" : "suspendbtn"}>
                      {currentStatus === 1 && "Active"}
                      {currentStatus === 2 && "Blocked"}
                      {currentStatus === 3 && "Suspend"}
                    </a>
                  </div>

                  <div className="status-button-group status_but">
                    <input type="hidden" name="UserId" id="statusUserId" />
                    <input type="hidden" name="Status" id="statusStatus" />

                    <button
                      className={`status-button but_active ${changeStatusTo === 1 && "open"}`}
                      id="activeBtn"
                      disabled={currentStatus === 1 && true}
                      // href="#active"
                      style={currentStatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      data-status="active"

                      onClick={() => { setChangeStatusTo(1) }}
                    // style={{background:"green"}}
                    >
                      <div className="status-active " style={currentStatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }} >
                        <div className="sts-icon">
                          <span className="icon">
                            <i
                              className="fa fa-check-circle-o"
                              aria-hidden="true"
                            />
                          </span>
                        </div>{" "}
                        Active
                      </div>
                    </button>

                    <button
                      className={`status-button but_suspend ${changeStatusTo === 3 && "open"}`}
                      style={currentStatus === 3 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      disabled={currentStatus === 3 && true}
                      id="suspendBtn"
                      // href="#active"

                      onClick={() => { setChangeStatusTo(3) }}
                      data-status="suspend"
                    >
                      <div className="status-disbled" style={currentStatus === 3 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                        <div className="sts-icon">
                          <span className="icon">
                            <i className="fa fa-ban" aria-hidden="true" />
                          </span>
                        </div>{" "}
                        Suspend
                      </div>
                    </button>

                    <button
                      className={`status-button but_locked ${changeStatusTo === 2 && "open"}`}
                      style={currentStatus === 2 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      disabled={currentStatus === 2 && true}
                      id="lockedBtn"
                      // href="#active"
                      onClick={() => { setChangeStatusTo(2) }}
                      data-status="locked"
                    >
                      <div className="status-locked" style={currentStatus === 2 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                        <div className="sts-icon">
                          <span className="icon">
                            <i className="fa fa-lock" aria-hidden="true" />
                          </span>
                        </div>{" "}
                        locked
                      </div>
                    </button>
                  </div>
                </div>
                <dl className="form_list d-flex mt-3">
                  <dt>Password </dt>
                  <dd className="mb-2">
                    <input
                      type="password"
                      value={userTypedPassword}
                      onChange={(passi) => { setUserTypesPassword(passi.target.value) }}
                      placeholder="Enter"
                      className="ng-untouched ng-pristine ng-valid"
                    />
                  </dd>
                  <dt />
                  <dd className="w-100 text-center">
                    <a
                      id="changePasswordBtn1"
                      title="Change"
                      onClick={updatingStatusOfUser}
                      className="btn btn2 px-4"
                      data-dismiss="modal"
                    >
                      Change
                    </a>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="../../../images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
    </>

  )
}

export default SelfUserList