import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../../context/currentUser/currentUser';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

// import ProfitBtn from "../../../images/profit-btn.png";
// import HistoryBtn from "../../../images/history-btn.png";
// import SettingBtn from "../../../images/setting-bet.png";
// import ProfileBtn from "../../../images/profile-btn.png";
// import DollarCoinBtn from "../../../images/doller-btn.png";
import axios from 'axios';

const AdminDashboard = () => {


  // to store the all child data
  const [ChildData, setChildData] = useState([]);
  // const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [userStatus, setUserStatus] = useState(0);
  // to store all the username in array
  const [allUsernameList, setAllUsernameList] = useState([]);

  // to store if the typed user exist or not
  const [userExist, setUserExist] = useState(null);
  const [userNotExist, setUserNotExist] = useState(null);

  // to store the detail of selected user in case of changing status
  const [selectedUser, setSelectedUser] = useState({ name: "", RoleId: 0, userstatus: 0, userparentStatus: 0, id: 0 });

  // to store the current user type
  const [addUserState, setAddUserState] = useState("");

  // for reloading the data 
  const [updatePage, setUpdatePage] = useState(true);



  // data state for adding the New User
  const [AddUsername, setAddUserName] = useState("");
  const [AddPassword, setAddPassword] = useState("");
  const [AddConfirmPassword, setAddConfirmPassword] = useState("");





  // console.log(selectedUser);

  // it will store the current status for the selected user
  const [currentStatus, setCurrentStatus] = useState(selectedUser.userparentStatus);

  // it will store the status that user need
  const [changeStatusTo, setChangeStatusTo] = useState(0);

  // it will store the password that user typed in status change model
  const [userTypedPassword, setUserTypesPassword] = useState("");
  const [totalExposure, settotalExposure] = useState(0);
  const [availBalance, setavailBalance] = useState(0);
  const [calcBalance, setcalcBalance] = useState(0);
  const [clickedUserBalance, setClickedUserBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [totalrows, setTotalrows] = useState(0);
  const [currentCreditRef, setCurrentCreditRef] = useState(0)
  const [newCreditRef, setNewCreditRef] = useState(0);
  const [loggedInPas, setLoggedInPass] = useState('');
  const [CreditUserId, setCreditUserId] = useState('')




  const context = useContext(currentUser);
  const { isLoggedIn, roleId, userId, fullName, name, setIsLoggedIn, userStatusId, setFullName, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages, currentCurrency, currentBalance, allApiUserdata, setAllApiUserdataData, setRefetchUsers, reloadOn } = context;

  const userIdLS = localStorage.getItem(`UserId`);
  const navigate = useNavigate();
  //  console.log(name);

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  }

  const UserRoleFullName = {
    1: "Domain",
    2: "TechAdmin",
    3: "SuperAdmin",
    4: "SubAdmin",
    5: "SuperSuper",
    6: "Super",
    7: "Master",
    8: "User"
  }



  useEffect(() => {
    console.log('reloadOn User ; ', reloadOn)
    setIsLoading(reloadOn)

  }, [reloadOn]);

  const loginBalance = async () => {
    try {
      console.log("User Id : ", userIdLS)
      const res = await fetch(`https://api.betcare.live/myBalance/${userIdLS}`)
      const resData = await res.json();

      if (resData.isSuccess) {

        console.log("login Balance : ", resData.mainBalance[0].ResultAmountP)
        setClickedUserBalance(resData.mainBalance[0].ResultAmountP !== null ? resData.mainBalance[0].ResultAmountP : 0)

        currentUserPages.filter(item => item.id == userIdLS).map(user => user.bal = resData.mainBalance[0].ResultAmountP !== null ? resData.mainBalance[0].ResultAmountP : 0)
      }

    } catch (error) {
      console.error("Error Fetching Login Balance..!")
    }
  }

  // const filterChild = async () => {
  //   try {
  //     setIsLoading(true);
  //     let fetchExpo = await fetch(`https://api.betcare.live/newcalcExposOfAllUser/${userIdLS}?status=0`)
  //     let fetchExpoData = await fetchExpo.json();

  //     if (fetchExpoData.isSuccess) {
  //       console.log(' filterChild fetcg expo data : ', fetchExpoData.data)
  //       setData(fetchExpoData.data)


  //     }
  //   }
  //   catch (error) {
  //     console.log('Error in Filter Child')
  //   }
  // }


  const fetchChildData = async (childRoleId, userName, userId, clickBalance, isUpdate) => {

    try {
      // setIsLoading(true);
      var res;
      if (userId !== undefined) {
        res = await fetch(`https://api.betcare.live/getUsers/${userId}`);
      } else {
        res = await fetch(`https://api.betcare.live/getUsers/${userIdLS}`);
      }
      let resData = await res.json();
      console.log("user data : ", resData)
      if (resData.data.length > 0) {



        // setData(filterUser)

      }

      if (userId !== undefined) {
        NavigatToChildDasboard(childRoleId, userName, userId, clickBalance, isUpdate)
      }
      // setChildData(resData.data);

      let filterUser;
      if (userStatus != 0) {
        filterUser = allApiUserdata.filter((item) => {
          return resData.data.some(user => item.Id == user.Id && item.ASBusers == userStatus)
        });
      } else {
        filterUser = allApiUserdata.filter((item) => {
          return resData.data.some(user => item.Id == user.Id)
        });
      }

      console.log('filterUser data length : ', filterUser.length)
      console.log('filterUser data : ', filterUser)

      // Calculate ResultAmountU and add firstChildBal to each user
      filterUser = filterUser.map(user => {
        const childResultAmounts = allApiUserdata
          .filter(child => child.ParentId === user.Id)
          .map(child => child.ResultAmountU || 0);
        const firstChildBal = childResultAmounts.reduce((acc, amount) => acc + amount, 0);
        return { ...user, firstChildBal };
      });
      // setAllData(filterUser)

      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentPageData = filterUser.slice(startIndex, endIndex);

      console.log("Add exposure of every data state array element whose Id is == to exposureArr of userId current page : ", currentPageData);
      setChildData(filterUser);
      setAllData(currentPageData)
      setTotalrows(filterUser.length);

    } catch (error) {
      console.error("Error in get User")
    } finally {
      // setIsLoading(false)
    }
  }



  const fetchExposure = async () => {
    try {

      const responseRunner = await fetch(`https://api.betcare.live/getRunners`);
      const resultRunner = await responseRunner.json();

      const runnersSelectionIds = [];

      if (resultRunner.isSuccess && resultRunner.data.length > 0) {
        // Extracting SelectionId and Market from each item in the resultRunner.data array
        const filterRunnerSId = resultRunner.data.map(item => ({
          eventId: item.EventId,
          selectionId: item.SelectionId,
          market: item.Market
        }));

        runnersSelectionIds.push(...filterRunnerSId);
      }

      // console.log("Runner Selection ID in fetch Exposure function  : ", runnersSelectionIds);

      const matchOddsBetsByRunner = runnersSelectionIds.filter(runner => runner.market == "Match Odds")

      const SBetsByRunner = matchOddsBetsByRunner.filter(runner => runner.selectionId);

      // console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunner);


      const BookMakerBetsByRunner = runnersSelectionIds.filter(runner => runner.market == "Bookmaker")

      const SBetsByRunnerBM = BookMakerBetsByRunner.filter(runner => runner.selectionId);

      // console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunnerBM);

      let exposureArr = [];

      // console.log("Data before exposure : ", ChildData)

      const promises = ChildData.map(async item => {
        try {

          const response = await fetch(`https://api.betcare.live/getExposure/${item.Id}`);
          const result = await response.json();

          if (result.isSuccess) {
            // console.log("Exposure Api data for Id:", item.Id, " is : ", result.data);


            let exposure = 0;

            // Iterate over each unique EventId
            result.data.forEach(event => {
              const eventId = event.eventId;
              const eventSelections = [];
              const eventSelectionsBM = [];


              // For BACK !!!!!!!

              const filtermatchOdds = event.data.filter(item => item.market == "Matchodds" && SBetsByRunner.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

              const totalProfit = filtermatchOdds.reduce((sum, item) => sum + item.profit, 0);

              // console.log("Total Profit for back: ", totalProfit);
              const totalLoss = filtermatchOdds.reduce((sum, item) => sum + item.liability, 0);

              // console.log("Total Loss for back: ", totalLoss);

              // console.log("Match Odd filter : " + JSON.stringify(filtermatchOdds))

              // For LAY !!!!!!

              const filtermatchOddsLay = event.data.filter(item => item.market == "Matchodds" && SBetsByRunner.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

              const totalProfitLay = filtermatchOddsLay.reduce((sum, item) => sum + item.profit, 0);

              // console.log("Total Loss for Lay : ", totalProfitLay);
              const totalLossLay = filtermatchOddsLay.reduce((sum, item) => sum + item.liability, 0);

              // console.log("Total Profit for Lay : ", totalLossLay);

              // console.log("Match Odd filter for lay : " + JSON.stringify(filtermatchOddsLay))

              // NEXT TEAM For BACK /////////////

              const filterODD = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "Matchodds" &&
                  item.type === "back" &&
                  SBetsByRunner.some(oddsitem => oddsitem.selectionId == item.selectionId);
              });

              // console.log("filter ODD Next team : ", filterODD)

              // Get unique selectionIds from filterODDLay
              const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

              // console.log("Filter odd SelectionIds ssss : ", filterODDSelectionIds)

              const filterOddAgainForOdd = SBetsByRunner.filter(item => !filterODDSelectionIds.has(item.selectionId)
                && filterODD.some(odds => odds.eventId == item.eventId));

              // console.log("Filter odd again for odd ssss : ", filterOddAgainForOdd)

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // console.log("Filter odd again for odd with profit loss ssss : ", filterOddAgainWithProfitLossOdd)
              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

              // console.log("Next Team for Odd: " + JSON.stringify(combinedArrayForOdd));

              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionId = {};
              const totalLossBySelectionId = {};

              // Calculate net profit for each selectionId
              combinedArrayForOdd.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionId[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
                  totalLossBySelectionId[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionId[selectionId] += profit;
                totalLossBySelectionId[selectionId] += loss;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionId = {};
              Object.keys(totalProfitBySelectionId).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

                const profit = totalProfitBySelectionId[selectionId];
                const loss = totalLossBySelectionId[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
              });

              // // Example usage:
              // console.log("Total Profit by SelectionId for Odd: ", totalProfitBySelectionId);
              // console.log("Total Loss by SelectionId for Odd : ", totalLossBySelectionId);
              // console.log("Net Profit by SelectionId for Odd : ", netProfitBySelectionId);

              // NEXT TEAM For LAY /////////////

              const filterODDLay = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "Matchodds" &&
                  item.type === "lay" &&
                  SBetsByRunner.some(oddsitem => oddsitem.selectionId === item.selectionId);
              });

              // console.log("filterODDLay fltered data : ", filterODDLay)
              // Get unique selectionIds from filterODDLay
              const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

              // Filter items from odds based on selectionId not present in filterODDLay
              const filterOddAgain = SBetsByRunner.filter(item => !filterODDLaySelectionIds.has(item.selectionId) && filterODDLay.some(odds => odds.eventId == item.eventId));

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // console.log("filter odd Again : " + JSON.stringify(filterOddAgainWithProfitLoss));

              // console.log("Next Team for Lay : " + JSON.stringify(filterODDLay));

              // console.log("Odds item : " + JSON.stringify(SBetsByRunner.length))

              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

              // console.log("Combined Both Array: " + JSON.stringify(combinedArray));

              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionIdLay = {};
              const totalLossBySelectionIdLay = {};

              // Calculate net profit for each selectionId
              combinedArray.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionIdLay[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
                  totalLossBySelectionIdLay[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionIdLay[selectionId] += loss;
                totalLossBySelectionIdLay[selectionId] += profit;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionIdLay = {};
              Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

                const profit = totalProfitBySelectionIdLay[selectionId];
                const loss = totalLossBySelectionIdLay[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
              });


              // console.log("Total Profit by SelectionId Odd: ", netProfitBySelectionId)
              // console.log("Total Loss by SelectionId Odd: ", totalLossBySelectionId)
              // console.log("Total Profit by SelectionId Lay: ", totalProfitBySelectionIdLay);
              // console.log("Total Loss by SelectionId Lay: ", totalLossBySelectionIdLay);
              // console.log("Net Profit by SelectionId Lay: ", netProfitBySelectionIdLay);

              // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
              const finalProfitMatchOdd = {};

              Object.keys(netProfitBySelectionId).forEach(selectionId => {
                // Sum the corresponding values for each selectionId
                finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
              });

              // console.log("Final Profit for Match Odd: ", finalProfitMatchOdd);

              const negativeProfits = Object.values(finalProfitMatchOdd).filter(profit => profit < 0);

              // If there are negative profits, find the maximum among them
              if (negativeProfits.length > 0) {
                const maxNegativeProfit = Math.min(...negativeProfits);
                // console.log("Maximum negative profit from finalProfitMatchOdd: ", maxNegativeProfit);

                exposure += maxNegativeProfit;
              } else {
                // console.log("No negative profits found in finalProfitMatchOdd.");
              }

              // For Book Maker

              // console.log("///////   Book Maker /////")
              // For BACK !!!!!!!

              const filtermatchOddsBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

              const totalProfitBM = filtermatchOddsBM.reduce((sum, item) => sum + item.profit, 0);

              // console.log("Total Profit for back BookMaker : ", totalProfitBM);
              const totalLossBM = filtermatchOddsBM.reduce((sum, item) => sum + item.liability, 0);

              // console.log("Total Loss for back BookMaker : ", totalLossBM);

              // console.log("Match Odd filter BookMaker : " + JSON.stringify(filtermatchOddsBM))

              // For LAY !!!!!!

              const filtermatchOddsLayBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

              const totalProfitLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.profit, 0);

              // console.log("Total Loss for Lay  BookMaker : ", totalProfitLayBM);
              const totalLossLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.liability, 0);

              // console.log("Total Profit for Lay BookMaker : ", totalLossLayBM);

              // console.log("Match Odd filter for lay BookMaker : " + JSON.stringify(filtermatchOddsLayBM))

              // NEXT TEAM For BACK /////////////

              const filterODDBM = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "BookMaker" &&
                  item.type === "back" &&
                  SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId == item.selectionId);
              });

              // console.log("filter ODD Next team BookMaker : ", filterODDBM)

              // Get unique selectionIds from filterODDLay
              const filterODDSelectionIdsBM = new Set(filterODDBM.map(item => item.selectionId));

              // console.log("Filter odd SelectionIds ssss BookMaker : ", filterODDSelectionIdsBM)

              const filterOddAgainForOddBM = SBetsByRunnerBM.filter(item => !filterODDSelectionIdsBM.has(item.selectionId)
                && filterODDBM.some(odds => odds.eventId == item.eventId));



              // console.log("Filter odd again for odd ssss BookMaker : ", filterOddAgainForOddBM)

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLossOddBM = filterOddAgainForOddBM.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // console.log("Filter odd again for odd with profit loss ssss BookMaker : ", filterOddAgainWithProfitLossOddBM)
              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArrayForOddBM = [...filterODDBM, ...filterOddAgainWithProfitLossOddBM];

              // console.log("Next Team for Odd BookMaker : " + JSON.stringify(combinedArrayForOddBM));
              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionIdBM = {};
              const totalLossBySelectionIdBM = {};

              // Calculate net profit for each selectionId
              combinedArrayForOddBM.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionIdBM.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionIdBM[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionIdBM.hasOwnProperty(selectionId)) {
                  totalLossBySelectionIdBM[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionIdBM[selectionId] += profit;
                totalLossBySelectionIdBM[selectionId] += loss;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionIdBM = {};
              Object.keys(totalProfitBySelectionIdBM).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdBM)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBM[otherSelectionId], 0);

                const profit = totalProfitBySelectionIdBM[selectionId];
                const loss = totalLossBySelectionIdBM[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionIdBM[selectionId] = profit + totalLossToOtherSelectionsBM;
              });


              // // Example usage:
              // console.log("Total Profit by SelectionId for Odd BookMaker: ", totalProfitBySelectionIdBM);
              // console.log("Total Loss by SelectionId for Odd BookMaker : ", totalLossBySelectionIdBM);
              // console.log("Net Profit by SelectionId for Odd  BookMaker : ", netProfitBySelectionIdBM);

              // NEXT TEAM For LAY /////////////

              const filterODDLayBM = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "BookMaker" &&
                  item.type === "lay" &&
                  SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId === item.selectionId);
              });

              // console.log("filterODDLay fltered data BookMaker : ", filterODDLayBM)
              // Get unique selectionIds from filterODDLay
              const filterODDLaySelectionIdsBM = new Set(filterODDLayBM.map(item => item.selectionId));

              // Filter items from odds based on selectionId not present in filterODDLay
              const filterOddAgainBM = SBetsByRunnerBM.filter(item => !filterODDLaySelectionIdsBM.has(item.selectionId) && filterODDLayBM.some(odds => odds.eventId == item.eventId));

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLossBM = filterOddAgainBM.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // console.log("filter odd Again BookMaker : " + JSON.stringify(filterOddAgainWithProfitLossBM));

              // console.log("Next Team for Lay BookMaker : " + JSON.stringify(filterODDLayBM));

              // console.log("Odds item BookMaker : " + JSON.stringify(SBetsByRunnerBM.length))

              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArrayBM = [...filterODDLayBM, ...filterOddAgainWithProfitLossBM];

              // console.log("Combined Both Array BookMaker : " + JSON.stringify(combinedArrayBM));

              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionIdLayBM = {};
              const totalLossBySelectionIdLayBM = {};

              // Calculate net profit for each selectionId
              combinedArrayBM.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionIdLayBM.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionIdLayBM[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionIdLayBM.hasOwnProperty(selectionId)) {
                  totalLossBySelectionIdLayBM[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionIdLayBM[selectionId] += loss;
                totalLossBySelectionIdLayBM[selectionId] += profit;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionIdLayBM = {};
              Object.keys(totalProfitBySelectionIdLayBM).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdLayBM)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBM[otherSelectionId], 0);

                const profit = totalProfitBySelectionIdLayBM[selectionId];
                const loss = totalLossBySelectionIdLayBM[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionIdLayBM[selectionId] = profit + totalLossToOtherSelectionsBM;
              });


              // console.log("Total Profit by SelectionId Odd BookMaker : ", netProfitBySelectionIdBM)
              // console.log("Total Loss by SelectionId Odd BookMaker : ", totalLossBySelectionIdBM)
              // console.log("Total Profit by SelectionId Lay BookMaker : ", totalProfitBySelectionIdLayBM);
              // console.log("Total Loss by SelectionId Lay BookMaker : ", totalLossBySelectionIdLayBM);
              // console.log("Net Profit by SelectionId Lay BookMaker : ", netProfitBySelectionIdLayBM);

              // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
              const finalProfitMatchOddBM = {};

              Object.keys(netProfitBySelectionIdBM).forEach(selectionId => {
                // Sum the corresponding values for each selectionId
                finalProfitMatchOddBM[selectionId] = (netProfitBySelectionIdBM[selectionId] || 0) + (netProfitBySelectionIdLayBM[selectionId] || 0);
              });

              // console.log("Final Profit for Book Maker: ", finalProfitMatchOddBM);

              const negativeProfitsBM = Object.values(finalProfitMatchOddBM).filter(profit => profit < 0);

              // If there are negative profits, find the maximum among them
              if (negativeProfitsBM.length > 0) {
                const maxNegativeProfit = Math.min(...negativeProfitsBM);
                // console.log("Maximum negative profit from finalProfitMatchOddBM BookMaker : ", maxNegativeProfit);
                exposure += maxNegativeProfit;
              } else {
                // console.log("No negative profits found in finalProfitMatchOddBM BookMaker.");
              }

              //  FOR Fancy ///////
              ////console.log("//// Fancy ///")

              const filterFancy = event.data.filter(item => item.market == 'Fancy')



              //console.log("Fancy bets calcualtion : ", filterFancy)


              const fancyBackLayResult = [];
              let maxNegativeBackToLay = 0;
              let maxNegativeLayToBack = 0;
              let maxNegativeValue = 0;


              // Step 2: Group items by selectionId
              const groupedItems = {};

              filterFancy.forEach(item => {
                if (!groupedItems[item.selectionId]) {
                  groupedItems[item.selectionId] = [];
                }
                groupedItems[item.selectionId].push(item);
              });

              // Step 3: Calculate profit and liability for 'Back' and 'Lay' markets for each selectionId
              // for (const selectionId in groupedItems) {
              //   const backItems = groupedItems[selectionId].filter(item => item.type == 'back');
              //   const layItems = groupedItems[selectionId].filter(item => item.type == 'lay');

              //   let backProfit = 0;
              //   let backLiability = 0;
              //   let layProfit = 0;
              //   let layLiability = 0;

              //   backItems.forEach(item => {
              //     backProfit += item.profit;
              //     backLiability += item.liability;
              //   });

              //   layItems.forEach(item => {
              //     layProfit += item.profit;
              //     layLiability += item.liability;
              //   });

              //   // Step 4: Calculate back profit to lay liability and back liability to lay profit
              //   const backProfitToLayLiability = backProfit + layLiability;
              //   const backLiabilityToLayProfit = backLiability + layProfit;

              //   // Step 5: Console the results for each selectionId
              //  //console.log(`SelectionID: ${selectionId}`);
              //  //console.log("Back Profit to Lay Liability:", backProfitToLayLiability);
              //  //console.log("Back Liability to Lay Profit:", backLiabilityToLayProfit);


              //   fancyBackLayResult.push({
              //     selectionId: selectionId,
              //     backPLayL: backProfitToLayLiability,
              //     backLlayP: backLiabilityToLayProfit
              //   });

              //   const maxNegativeInIteration = Math.min(backProfitToLayLiability, backLiabilityToLayProfit);
              //   maxNegativeValue += maxNegativeInIteration
              // }


              for (const selectionId in groupedItems) {
                const items = groupedItems[selectionId];
                const minOddsRequest = Math.min(...items.map(item => item.oddsRequest));
                const maxOddsRequest = Math.max(...items.map(item => item.oddsRequest));



                // BET CALCULATION FOR BACK !!!!!!!!!

                const filterByBack = items.filter(item => item.type == "back")

                //console.log("Fancy filter for Back : " + JSON.stringify(filterByBack))


                // Group items by id
                const groupedByIdBack = {};
                filterByBack.forEach(item => {
                  if (!groupedByIdBack[item.id]) {
                    groupedByIdBack[item.id] = [];
                  }
                  groupedByIdBack[item.id].push(item);
                });

                // Calculate the range and set the length dynamically
                const rangeBack = maxOddsRequest - minOddsRequest + 10;

                // Create FancyBookArray for each group
                const resultArraysBack = [];
                const calcArrayBack = []
                Object.keys(groupedByIdBack).forEach(groupId => {
                  const groupItems = groupedByIdBack[groupId];


                  const FancyBookArray = Array.from({ length: rangeBack }, (_, index) => {
                    const request = index + minOddsRequest - 5;
                    const pl = request >= groupItems[0].oddsRequest ? groupItems[0].profit : groupItems[0].liability;

                    return { request, pl };
                  });

                  resultArraysBack.push({ id: groupId, FancyBookArray });
                });

                // //console.log("Result Arrays For Back:", resultArraysBack);

                const redcBack = resultArraysBack.reduce((acc, current) => {
                  current.FancyBookArray.forEach((entry) => {
                    const existingEntry = acc.find((item) => item.request == entry.request);

                    if (existingEntry) {
                      existingEntry.pl += entry.pl;
                    } else {
                      acc.push({ request: entry.request, pl: entry.pl });
                    }
                  });

                  return acc;
                }, []);

                // //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))



                // BET CALCULATION FOR LAY !!!!!!!!!

                const filterByLay = items.filter(item => item.type == "lay")

                //console.log("Fancy filter for Lay : " + JSON.stringify(filterByLay))


                // Group items by id
                const groupedById = {};
                filterByLay.forEach(item => {
                  if (!groupedById[item.id]) {
                    groupedById[item.id] = [];
                  }
                  groupedById[item.id].push(item);
                });

                // Calculate the range and set the length dynamically
                const range = maxOddsRequest - minOddsRequest + 10;

                // Create FancyBookArray for each group
                const resultArrays = [];
                const calcArray = []
                Object.keys(groupedById).forEach(groupId => {
                  const groupItems = groupedById[groupId];


                  const FancyBookArray = Array.from({ length: range }, (_, index) => {
                    const request = index + minOddsRequest - 5;
                    const pl = request >= groupItems[0].oddsRequest ? groupItems[0].liability : groupItems[0].profit;

                    return { request, pl };
                  });

                  resultArrays.push({ id: groupId, FancyBookArray });
                });

                //console.log("Result Arrays:", resultArrays);

                const redc = resultArrays.reduce((acc, current) => {
                  current.FancyBookArray.forEach((entry) => {
                    const existingEntry = acc.find((item) => item.request == entry.request);

                    if (existingEntry) {
                      existingEntry.pl += entry.pl;
                    } else {
                      acc.push({ request: entry.request, pl: entry.pl });
                    }
                  });

                  return acc;
                }, []);

                //console.log("Calculated Array for lay : " + JSON.stringify(redc))
                //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))


                let finalCalculatedArray = []
                if (redcBack.length > 0) {

                  // Combine redcBack and redc to create the final calculated array
                  finalCalculatedArray = redcBack.map((entryBack) => {
                    const entryLay = redc.find((entry) => entry.request == entryBack.request);

                    if (entryLay) {
                      entryBack.pl += entryLay.pl;
                    }

                    return entryBack;
                  });
                } else {
                  finalCalculatedArray = redc
                }

                console.log("Final Calculated Array : ", finalCalculatedArray)
                fancyBackLayResult.push(finalCalculatedArray)
              }


              console.log("final calc fancy array: ", fancyBackLayResult);
              // Initialize mostNegativeValue
              let mostNegativeValue = 0;

              // Iterate over each sub-array in fancyBackLayResult
              fancyBackLayResult.forEach(subArray => {
                // Find the maximum negative pl value in the current sub-array
                const negativePls = subArray.filter(item => item.pl < 0);
                if (negativePls.length > 0) {
                  const maxNegativePl = Math.min(...negativePls.map(item => item.pl));
                  // Add the maximum negative pl value to mostNegativeValue
                  mostNegativeValue += maxNegativePl;
                }
              });

              console.log("Most negative value: ", mostNegativeValue);


              //console.log("final calc fancy array in header : ", fancyBackLayResult);
              //console.log("Maximum Negative Value in header :", maxNegativeValue);

              const totalLiabilityFancy = filterFancy.reduce((sum, item) => sum + item.liability, 0);

              ////console.log("total Liabality of facny  : ", totalLiabilityFancy)

              exposure += mostNegativeValue;




            });
            // console.log("Final Exposure of MO & BM & Fancy : ", exposure + " Id " + item.Id)

            exposureArr.push({ userId: item.Id, exposure: exposure })

            // setUserExposure(exposure) 
            // console.log("Balance Api Balance is : ", apiBalance)
            // const calcBalance = apiBalance + exposure
            // setuserBalance(calcBalance)
            // Log exposureArr after all fetch requests have completed
            // console.log("Exposure Array : ", exposureArr);

          } else {
            // console.log("Error in fetching Bets exposure api in Header ");
          }
        } catch (error) {
          console.error('Error fetching exposure data:', error);
        }

      });

      // Wait for all promises to resolve
      await Promise.all(promises);

      // Log exposureArr after all fetch requests have completed
      // console.log("Exposure Array outside  : ", exposureArr);

      // Map over the data array and add exposure to each element if its Id matches any userId in exposureArr
      const addExpo = ChildData.map(item => {
        // Find the exposure where userId matches Id
        const matchedExposure = exposureArr.find(exp => exp.userId === item.Id);
        // Add the matched exposure to the item
        return { ...item, exposure: matchedExposure ? matchedExposure.exposure : 0 };
      });

      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentPageData = addExpo.slice(startIndex, endIndex);

      console.log("Add exposure of every data state array element whose Id is == to exposureArr of userId: ", currentPageData);

      setAllData(currentPageData)
      setTotalrows(addExpo.length);

    } catch (error) {
      console.error('Error fetching bets exposure api', error);
    }
  };

  // setClickedUserBalance(currentBalance)

  const NavigatToChildDasboard = (childRoleId, userName, userId, clickBalance, isUpdate) => {
    if (isUpdate === false) {
      SetCurrentUserPages((prev) => [...prev, { fullName: userName, roleId: childRoleId, id: userId, bal: clickBalance }]);
    }
    setCurrentDashboard({ roleId: childRoleId, userId: userId });
    setClickedUserBalance(clickBalance)

    // console.log('current suer state is : ', { fullName: userName, roleId: childRoleId, id: userId, bal: clickBalance })
    // console.log('current suer state is state : ', currentUserPages)
  }
  console.log('current suer state is state outside : ', currentUserPages)
  console.log(' current dashboard  : ', currentDashboard);
  console.log(' current user pages  : ', currentUserPages);



  const updatingCurrentUserPage = (clickedIndex, childRoleId, userId, fullName, bal) => {
    const newData = currentUserPages.slice(0, clickedIndex + 1);
    // Update the state with the new array
    SetCurrentUserPages(newData);
    // console.log("nav Id"+childRoleId);
    setCurrentDashboard({ roleId: childRoleId, userId: userId });
    console.log('updateedd .... ', childRoleId, fullName, userId, bal)
    fetchChildData(childRoleId, fullName, userId, bal, true)
    // setClickedUserBalance(bal)
  }




  const funcToSetSelectedUser = (selectedUsername, selectedRoleId, selectedStatus, selectedParentStatus, selectedId) => {

    console.log("data in funcToSeleUs : ", selectedUsername, selectedRoleId, selectedStatus, selectedParentStatus, selectedId)

    if (selectedParentStatus !== 1) {
      toast.error(`Your Upline is ${selectedParentStatus === 2 ? "Blocked !!" : "Suspended !!"}`, {
        autoClose: 1500,
        theme: "colored",
      });
    }

    const data = {
      name: selectedUsername,
      RoleId: selectedRoleId,
      userstatus: selectedStatus,
      userparentStatus: selectedParentStatus,
      id: selectedId
    }

    console.log("selecte user stting tab : ", data)

    setSelectedUser({
      name: selectedUsername,
      RoleId: selectedRoleId,
      userstatus: selectedStatus,
      userparentStatus: selectedParentStatus,
      id: selectedId
    })
  }

  const openCreditRef = async (id) => {
    try {
      setCreditUserId(id)
      let totalCreditRef = 0;
      const fetchCreditRef = await fetch(`https://api.betcare.live/api/getCreditRef/${id}`)

      const resCreditRef = await fetchCreditRef.json();

      if (resCreditRef.isSuccess && resCreditRef.data.length != 0) {

        resCreditRef.data.map(item => totalCreditRef += Number(item.CreditReference))


      }
      setCurrentCreditRef(totalCreditRef)

      console.log('credit ref console : ', resCreditRef)



    } catch (error) {
      console.log('Error in openCreditRef.. ')
    }
  }

  const saveRequestCreditReference = async () => {
    try {
      const sendCreditRefData = await fetch(`https://api.betcare.live/api/creditRef/${CreditUserId}/${newCreditRef}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ loginId: userIdLS, loginPassword: loggedInPas })
      });

      try {
        const data = await sendCreditRefData.json();
        console.log('sent creditRef data respionse  : ', data)
        if (data.isSuccess) {
          toast.success(`${data.message}`, {
            autoClose: 1500,
            theme: "colored",
          });
          setCreditUserId('');
          setCurrentCreditRef(0);
          setNewCreditRef(0);
          setLoggedInPass('')
          setUpdatePage((prev) => !prev);
          setRefetchUsers(true);

        } else {
          toast.error(`${data.message}`, {
            autoClose: 1500,
            theme: "colored",
          });
          setCreditUserId('');
          setCurrentCreditRef(0);
          setNewCreditRef(0);
          setLoggedInPass('')
        }


      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle parsing error
      }
    } catch (error) {

    }
  }



  const onChangeHandlerForAddUser = (e) => {

    let typedValue = e.target.value.toLowerCase();
    setAddUserName(typedValue);
    console.log(AddUsername);

    let answer = allUsernameList.filter((e) => {
      return e.toLowerCase() === typedValue
    })
    console.log('answer : ', answer)

    if (answer.length !== 0) {
      setUserExist(true);
    } else setUserExist(false)


    if (typedValue.length >= 4 && answer.length === 0) {
      setUserNotExist(true)
    } else setUserNotExist(false)



  }




  const funcToAddNewUser = async (e) => {

    e.preventDefault();
    // console.log(currentDashboard.userId);
    // console.log(currentDashboard.roleId+1);

    // console.log(AddUsername);
    // console.log(AddPassword);
    // console.log(AddConfirmPassword);

    if (AddPassword !== AddConfirmPassword) {
      toast.error(`Password and Confirm Password not matched !`, {
        autoClose: 1500,
        theme: "colored",
      });

      setAddPassword("");
      setAddUserName("");
      setAddConfirmPassword("");
      setUserNotExist(false);

    }
    else {
      try {
        let res = await fetch(`https://api.betcare.live/api/adduser/${currentDashboard.userId}/${currentDashboard.roleId + 1}`, {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username: AddUsername, password: AddPassword })
        });

        toast.success(`User Added Successfully !`, {
          autoClose: 1500,
          theme: "colored",
        });
        setAddPassword("");
        setAddUserName("");
        setAddConfirmPassword("");
        setUserNotExist(false);
        setUpdatePage((prev) => !prev)
        setRefetchUsers(true);

      } catch (error) { console.log('error while create new user : ', error); }
    }
  }





  const updatingStatusOfUser = async () => {

    console.log("Password : ", userTypedPassword)
    console.log("Selected User : ", selectedUser)


    let res = await fetch(`https://api.betcare.live/api/changestatus/users/${selectedUser.id}/${changeStatusTo == 0 ? selectedUser.userparentStatus : changeStatusTo}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ loggedInUserId: userIdLS, password: userTypedPassword })
    });

    let resData = await res.json();

    if (res.status === 200) {
      toast.success(`${resData.message}`, {
        autoClose: 1500,
        theme: "colored",
      });
      setUpdatePage((prev) => !prev);
      setRefetchUsers(true);

    } else {
      toast.error(`${resData.message}`, {
        autoClose: 1500,
        theme: "colored",
      });
    }
  }


  const logoutSetDataFunc = async () => {
    let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
  }


  const calcTotal = () => {
    let calctotalExposure = 0;
    let avaiBalance = 0;
    let balance = 0;

    ChildData.forEach((item) => {
      // Assuming each item has a property named 'currentExposure'
      calctotalExposure += item.exposure != null ? Number(item.exposure) : 0;

      if (item.RoleId !== 8) {
        // Calculate available balance for RoleId other than 8
        avaiBalance += item.ResultAmountU != null ? item.ResultAmountU : 0;
        balance += item.firstChildBal != null ? item.firstChildBal : 0;
      } else {
        // Calculate available balance for RoleId 8
        avaiBalance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
          (item.UserPL != null ? item.UserPL : 0) +
          (item.exposure != null ? Number(item.exposure) : 0);

        balance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
          (item.UserPL != null ? item.UserPL : 0)
      }
    });

    // Now, totalExposure contains the sum of currentExposure of all elements
    console.log("Total Exposure ", calctotalExposure);
    console.log("AvailBalance ", avaiBalance);
    console.log("Balance ", balance);

    settotalExposure(calctotalExposure)
    setavailBalance(avaiBalance)
    setcalcBalance(balance)


  }

  // useEffect(() => {

  //   const pageNavigate = () => {
  //     const startIndex = (page - 1) * pageSize;
  //     const endIndex = startIndex + pageSize;
  //     const currentPageData = data.slice(startIndex, endIndex);

  //     console.log("Add exposure of every data state array element whose Id is == to exposureArr of userId current page : ", currentPageData);
  //     setAllData(currentPageData)
  //     setTotalrows(data.length);
  //   }
  //   pageNavigate()

  // }, [data, page])


  // useEffect(() => {
  //   console.log('context user Data : ', data)
  //   if (data.length < 0) {
  //     setIsLoading(true)
  //   } else {
  //     setIsLoading(false)
  //   }
  // }, data)

  // useEffect(() => {
  //   filterChild();
  // }, [])

  // useEffect(() => {
  //   console.log('context user Data : ', data);
  //   if (data.length === 0 || data.includes('no data') || data.includes('error')) {
  //     setIsLoading(true);
  //   } 
  // }, [data]);

  useEffect(() => {
    if (currentUserPages.length > 0 && allApiUserdata.length > 0) {
      const lastPage = currentUserPages[currentUserPages.length - 1];
      fetchChildData(lastPage.roleId, lastPage.fullName, lastPage.id, lastPage.bal, true);
    }
  }, [allApiUserdata])

  useEffect(() => {
    calcTotal();
  }, [allData])

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    if (isLoggedIn === "false") {
      navigate("/login");
      return;
    }

    if (currentUserPages.length > 0) {
      const lastPage = currentUserPages[currentUserPages.length - 1];
      fetchChildData(lastPage.roleId, lastPage.fullName, lastPage.id, lastPage.bal, true);
    }

  }, [isLoggedIn, updatePage, page, userStatus])




  useEffect(() => {
    ; (async () => {
      let res = await fetch(`https://api.betcare.live/api/getAllUserName/users`);
      let resData = await res.json();

      console.log('all User name : ', resData)
      setAllUsernameList(resData);
    })();

    ; console.log(userStatusId + "winzo");

    loginBalance();
  }, [])





  useEffect(() => {
    if (selectedUser.userparentStatus < selectedUser.userstatus) {
      setCurrentStatus(selectedUser.userstatus)

    } else {
      setCurrentStatus(selectedUser.userparentStatus)
    }
  }, [selectedUser]);


  // useEffect(() => {

  //   const fetchUserStatusCheck = async () => {
  //     let res = await fetch(`https://api.betcare.live/api/isUserIsLoggedIn/userstatus/${userStatusId}`);
  //     let resdata = await res.json();
  //     let userIsLoggedIn = resdata.message;
  //     console.log(userIsLoggedIn + " hey boyy");

  //     if (userIsLoggedIn == "false") {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('RoleId');
  //       localStorage.removeItem('UserName');
  //       localStorage.removeItem("currentDashboard");
  //       localStorage.removeItem("currentUserPages");
  //       // localStorage.removeItem("CurrentCurrency");
  //       setIsLoggedIn(false);
  //       logoutSetDataFunc();
  //       navigate("/login");
  //     }
  //   };

  //   fetchUserStatusCheck();

  // }, [])

  // useEffect(() => {

  //   fetchExposure();
  // }, [ChildData])





  return (

    isLoggedIn &&

    <>

      <style>{`
    div::-webkit-scrollbar {
  display: none;
}
    `}</style>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <main role="main" className="pb-3">
        <div className="download-list">
          <div className="container mxw-100">
            {/* <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div> */}
            <div id="filterDiv">
              <div className="fiter-box">
                <div className="saerch-filter">
                  <div className="form-group position-relative">
                    <span className="serch-icon">
                      <i className="fa fa-search" aria-hidden="true" />
                    </span>
                    <input
                      type="search"
                      placeholder="Find Member.."
                      className="form-control ng-untouched ng-pristine ng-valid"
                      name="SearchTerm"
                      id="Searchingusername"
                    />
                    <button
                      type="submit"
                      className="seacrh-btn"
                      onclick="getUsersListBystatus()"
                      name="SearchTerm"
                    >
                      Search
                    </button>
                  </div>
                  <div className="form-group status-filter position-relative ml-2" style={{ marginRight: '6px' }}>
                    <div className="form-inline ng-untouched ng-pristine ng-valid m-0" style={{ display: 'flex', alignItems: 'center' }}>
                      <div><label className="my-1 mr-2">Status</label></div>

                      <div>   <select
                        name="betStatus"
                        className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                        id="betStatus"
                        value={userStatus}
                        onChange={(e) => { setUserStatus(e.target.value) }}
                      >
                        <option value={0}>All</option>
                        <option value={1}>Active</option>
                        <option value={3}>Suspend</option>
                        <option value={2}>Locked</option>
                      </select></div>

                    </div>
                  </div>
                  <div className="ms-2 agent_path" style={{ width: '35vw', overflowX: 'scroll' }}>
                    <ul
                      id="agentPath"
                      className="agent_path-L mt-0 mb-0 mr-1 mb-0 ng-star-inserted"
                    >
                      {currentUserPages.map((e, index) => {


                        return (
                          <li style={{ cursor: "pointer" }} id="path_3" key={e.fullName}
                            onClick={() => { updatingCurrentUserPage(index, e.roleId, e.id, e.fullName, e.bal) }}
                            className="ng-star-inserted active">
                            <a >
                              <span className={`lv_${e.roleId - 2}`}> {UserRoleShortName[e.roleId]} </span>
                              <strong> {e.fullName}</strong>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="right-add-admin" id="showBtn">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#AddAdmin"
                    onClick={() => { setAddUserState(UserRoleFullName[currentDashboard.roleId + 1]) }}
                    className="light-btn ng-star-inserted"
                  >
                    <i className="fa fa-user-plus mr-2" aria-hidden="true" />
                    <span id="crtBtn">Add {UserRoleFullName[currentDashboard.roleId + 1]}</span>
                  </button>
                  <button type="button" className="light-btn refreshbtn ml-2">
                    <i className="fa fa-history" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div id="filterList">
              <div className="total-entries">
                <div className="total-item">
                  <span>Total Balance</span>
                  <h6>{currentCurrency} {calcBalance !== null ? calcBalance : 0}</h6>
                </div>
                <div className="total-item">
                  <span>Total Exposure</span>
                  <h6>
                    {currentCurrency} <b className="red">{totalExposure !== null ? totalExposure : 0}</b>
                  </h6>
                </div>
                <div className="total-item">
                  <span>Total Avail. bal.</span>
                  <h6>{currentCurrency} {Number(clickedUserBalance !== null ? clickedUserBalance : 0) + Number(availBalance !== null ? availBalance : 0) !== null ? Number(clickedUserBalance !== null ? clickedUserBalance : 0) + Number(availBalance !== null ? availBalance : 0) : 0}</h6>
                </div>
                <div className="total-item">
                  <span>Balance</span>
                  <h6>{currentCurrency} {clickedUserBalance !== null ? clickedUserBalance : 0}</h6>
                </div>
                <div className="total-item">
                  <span>Available Balance</span>
                  <h6>{currentCurrency} {availBalance !== null ? availBalance : 0}</h6>
                </div>
                <div className="total-item">
                  <span>Total Users</span>
                  <h6>{totalrows !== null ? totalrows : 0}</h6>
                </div>
              </div>
              <div className="profile-section mt-3">
                <div className="table-responsive  text-end">
                  <table className="table table01">
                    <thead>
                      <tr>
                        <th className="text-left">Account</th>
                        <th>Credit Ref. </th>
                        <th>Balance</th>
                        <th>Exposure</th>
                        <th>Avail. bal.</th>
                        <th>Ref. P/L</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData.map((item) => {
                        let status = item.parentStatus;
                        if (item.parentStatus < item.Status) {
                          status = item.Status;
                        }

                        let clickBalance = 0

                        if (item.RoleId !== 8) {
                          clickBalance += item.ResultAmountU != null ? item.ResultAmountU : 0;
                        } else {
                          clickBalance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
                            (item.UserPL != null ? item.UserPL : 0)
                        }



                        return (
                          <tr className="ng-star-inserted" key={item.Id}>
                            <td className="text-start ng-star-inserted">
                              <span
                                className="lv_1 account-name-specail-txt"
                                id="number"
                              >
                                {UserRoleShortName[item.RoleId]}
                              </span>{" "}

                              {item.RoleId === 8 ? (
                                <a> {item.UserName}</a>
                              ) : (
                                <Link onClick={(e) => { e.preventDefault(); fetchChildData(item.RoleId, item.UserName, item.Id, clickBalance, false) }}>
                                  {item.UserName}
                                </Link>
                              )
                              }

                            </td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                type="button"
                                className="edit-pass crdReference"
                                data-toggle="modal"
                                data-target='#CreditRefrence'
                                onClick={(e) => { e.preventDefault(); openCreditRef(item.Id) }}

                              >
                                {item.CreditReference != null ? item.CreditReference : 0}
                                <i className="fa fa-pencil" aria-hidden="true" />
                              </a>
                            </td>
                            <td>
                              {item.RoleId !== 8
                                ? item.firstChildBal != null ? item.firstChildBal : 0
                                : ((item.ResultAmountU != null ? item.ResultAmountU : 0) +
                                  (item.UserPL != null ? item.UserPL : 0))}
                              <a
                                href="javascript:void(0);"
                                className="table-expend-plus-btn"
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </a>
                            </td>
                            <td className="red">
                              <span>{item.exposure != null ? item.exposure : 0}</span>
                            </td>
                            <td> {item.RoleId !== 8
                              ? (item.ResultAmountU != null ? item.ResultAmountU : 0)
                              : ((item.ResultAmountU != null ? item.ResultAmountU : 0) +
                                (item.UserPL != null ? item.UserPL : 0) +
                                Number(item.exposure))}</td>
                            <td className={(Number(item.childDWresultAmountU != null ? item.childDWresultAmountU : 0) - Number(item.CreditReference != null ? item.CreditReference : 0)) >= 0 ? "green" : "red"}>({Number(item.childDWresultAmountU != null ? item.childDWresultAmountU : 0) - Number(item.CreditReference != null ? item.CreditReference : 0)})</td>
                            <td>
                              <a className={
                                status === 1 ? "activebtn" : status === 2 ? "blockedbtn" : "suspendbtn"
                              }>
                                {status === 1 && "Active"}
                                {status === 2 && "Blocked"}
                                {status === 3 && "Suspend"}
                              </a>
                            </td>
                            <td>
                              <div className="action-bnt-list justify-content-end align-items-center d-flex">
                                <Link
                                  to={"/bettingprofitloss"}
                                  className="icon d-inline-flex ng-star-inserted"
                                  state={currentDashboard !== null && { id: item.Id, roleId: item.RoleId, selfUser: item.selfUser }}
                                >
                                  <img src="../../../images/profit-btn.png" />
                                </Link>
                                <Link
                                  to={"/bettinghistory"}
                                  className="icon d-inline-flex ng-star-inserted"
                                  state={currentDashboard !== null && { id: item.Id, roleId: item.RoleId, selfUser: item.selfUser }}
                                >
                                  <img src="../../../images/history-btn.png" />
                                </Link>
                                <a
                                  data-toggle="modal"
                                  data-target={item.parentStatus === 1 ? "#changeStatusModal" : ""}
                                  // {name:"",RoleId:0,status:0,parentStatus:0,id:0}
                                  onClick={(e) => { e.preventDefault(); funcToSetSelectedUser(item.UserName, item.RoleId, item.Status, item.parentStatus, item.Id) }}
                                  type="button"
                                  className="icon d-inline-flex usersettingstatus"
                                >
                                  <img src="../../../images/setting-bet.png" />
                                </a>
                                <Link
                                  to={"/accountsummary"}
                                  state={{
                                    id: item.Id,
                                    roleId: item.RoleId,
                                    bal: (item.ResultAmountU != null ? item.ResultAmountU : 0) +
                                      (item.UserPL != null ? item.UserPL : 0),
                                    expo: item.exposure != null ? item.exposure : 0,
                                    availBal: (item.ResultAmountU != null ? item.ResultAmountU : 0) +
                                      (item.UserPL != null ? item.UserPL : 0) +
                                      Number(item.exposure),
                                    selfUser: item.selfUser
                                  }}
                                  onClick={() => { setCurrentDashboard({ userId: item.Id }) }}
                                  className="icon d-inline-flex"
                                >
                                  <img src="../../../images/profile-btn.png" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination-controls className="text-center">
                <pagination-template>
                  <nav aria-label="Pagination">
                    <ul className="ngx-pagination ng-star-inserted">
                      <li
                        className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                      >
                        <button
                          className="ng-star-inserted"
                          style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                          onClick={() => { setPage((prev) => prev - 1) }}
                          disabled={page === 1 && true}
                        >
                          Previous{" "}
                        </button>
                      </li>
                      {/* <li className="current ng-star-inserted">
                              <span
                                aria-live="polite"
                                className="ng-star-inserted"
                              >
                                <span className="show-for-sr">
                                  You're on page{" "}
                                </span>
                                <span>1</span>
                              </span>
                            </li> */}
                      <li
                        className={`pagination-next  ${page === Math.ceil(totalrows / pageSize) ? "disabled" : totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                      >
                        <button
                          // aria-disabled="true"
                          className="ng-star-inserted"
                          style={page === Math.ceil(totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                          onClick={() => setPage((prev) => prev + 1)}
                          disabled={page === Math.ceil(totalrows / pageSize) ? true : totalrows <= 20 ? true : false}
                        >Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </pagination-template>
              </pagination-controls>
              <div
                id="AddAdmin"
                tabIndex={-1}
                data-bs-keyboard="false"
                data-bs-backdrop="static"
                aria-labelledby="AddAdminLabel"
                data-backdrop="static"
                aria-hidden="true"
                className="modal fade changepassword-model"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6
                        id="AddAdminLabel"
                        className="modal-title ng-star-inserted"
                      >
                        Add {addUserState}
                      </h6>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn-close"
                        onClick={() => {
                          setAddUserName("");
                          setAddPassword("");
                          setAddConfirmPassword("");
                          setUserExist(false);
                          setUserNotExist(false)
                        }}
                      >
                        x
                      </button>
                    </div>
                    <div className="ng-untouched ng-pristine ng-invalid">
                      <div className="modal-body">
                        <dl className="form_list">
                          <dt>Username </dt>
                          <dd>
                            <input
                              type="text"
                              placeholder="Enter"
                              id="userName"
                              value={AddUsername}
                              maxLength={20}
                              onChange={onChangeHandlerForAddUser}
                              formcontrolname="user_name"
                              name=""
                              className="ng-untouched ng-pristine ng-invalid"
                            />
                            <span className="must">＊</span>
                            <div className="d-block">
                              <div
                                id="userNameAvailableValidation"
                                style={!userExist ? { color: "#ff6a00", display: "none" } : { color: "#ff6a00", display: "block" }}
                              // style={{ color: "#ff6a00", display: "none" }}
                              >
                                Username is already taken !
                              </div>
                              <div
                                id="userNameNotAvailableValidation"
                                style={!userNotExist ? { color: "#0094ff", display: "none" } : { color: "#0094ff", display: "block" }}
                              >
                                Username is available !
                              </div>
                            </div>
                          </dd>
                          <dt>Password </dt>
                          <dd>
                            <input
                              type="password"
                              placeholder="Enter"
                              value={AddPassword}
                              onChange={(e) => { setAddPassword(e.target.value) }}
                              id="userPassword"
                              formcontrolname="password"
                              className="ng-untouched ng-pristine ng-invalid"
                            />
                            <span className="must">＊</span>
                          </dd>
                          <dt>Confirm Password </dt>
                          <dd>
                            <input
                              type="password"
                              placeholder="Enter"
                              value={AddConfirmPassword}
                              onChange={(e) => { setAddConfirmPassword(e.target.value) }}
                              id="repeatPassword"
                              formcontrolname="confirm_pass"
                              className="ng-untouched ng-pristine ng-invalid"
                            />
                            <span className="must">＊</span>
                          </dd>
                          <dt />
                          <dd className="w-100 text-center">
                            <button
                              id="createBtn"
                              title="Create"
                              disabled={userExist ? true : AddUsername.length < 4 ? true : AddPassword.length > 4 ? false : true}
                              data-dismiss="modal"
                              style={!userExist ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                              onClick={funcToAddNewUser}
                              className="btn btn2 px-5"
                            >
                              Create
                            </button>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="CreditRefrence"
          tabIndex={-1}
          aria-labelledby="CreditRefrenceLabel"
          aria-hidden="true"
          className="modal fade changepassword-model"
        >
          <div className="modal-dialog">
            <form className="ng-untouched ng-pristine ng-invalid">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 id="CreditRefrenceLabel" className="modal-title">
                    Credit Reference Edit
                  </h6>
                  <button type="button" className="btn-close close_pop" data-dismiss="modal">
                    x
                  </button>
                </div>
                <div className="modal-body credit-refrence">
                  <hr className="my-3" />
                  <dl className="form_list">
                    <dt>Current </dt>
                    <dd>
                      <div className="log-div d-flex justify-content-between align-items-center">
                        <span>{currentCreditRef}</span>
                        <b />
                        <button type="button" className="light-btn">
                          Log
                        </button>
                      </div>
                    </dd>
                    <dt>New </dt>
                    <dd>
                      <input type="hidden" name="UserId" id="CRid" />
                      <input
                        type="number"
                        id="new"
                        placeholder="Enter"
                        formcontrolname="reference"
                        className="ng-untouched ng-pristine ng-valid"
                        value={newCreditRef}
                        onChange={(e) => { setNewCreditRef(e.target.value) }}
                      />
                    </dd>
                    <dt>Password </dt>
                    <dd className="mb-2">
                      <input
                        type="password"
                        id="Password"
                        placeholder="Enter"
                        formcontrolname="password"
                        className="ng-untouched ng-pristine ng-invalid"
                        value={loggedInPas}
                        onChange={(e) => { setLoggedInPass(e.target.value) }}
                      />
                    </dd>
                    <hr className="mb-1 mt-1" />
                    <dt />
                    <dd className="w-100 text-center">
                      <a
                        id="changePasswordBtn"
                        title="Submit"
                        onClick={saveRequestCreditReference}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn2 px-5"
                      >
                        Submit
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Change status pop start */}
        <div
          id="changeStatusModal1"
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          style={{ display: "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 id="ChangeStatusLabel" className="modal-title">
                  Change Status
                </h6>
                <button type="button" className="close" data-dismiss="modal">
                  x
                </button>
              </div>
              <div className="modal-body change-status">
                <div className="change-st-box">
                  <div className="chnage-st-name d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      <b id="account_user_name">superadmin3</b>
                    </p>
                    <a
                      href="javascript:void(0);"
                      className="activebtn"
                      id="originalStatus"
                    >
                      <span id="originalStatus123">Active</span>
                    </a>
                  </div>
                  <div className="status-button-group status_but">
                    <input type="hidden" name="UserId" id="statusUserId" />
                    <input type="hidden" name="Status" id="statusStatus" />
                    <a
                      className="status-button but_active"
                      id="activeBtn"
                      href="#active"
                      data-status="active"
                    >
                      <div className="status-active ">
                        <div className="sts-icon">
                          <span className="icon">
                            <i
                              className="fa fa-check-circle-o"
                              aria-hidden="true"
                            />
                          </span>
                        </div>{" "}
                        Active
                      </div>
                    </a>
                    <a
                      className="status-button but_suspend"
                      id="suspendBtn"
                      href="#active"
                      data-status="suspend"
                    >
                      <div className="status-disbled ">
                        <div className="sts-icon">
                          <span className="icon">
                            <i className="fa fa-ban" aria-hidden="true" />
                          </span>
                        </div>{" "}
                        Suspend
                      </div>
                    </a>
                    <a
                      className="status-button but_locked"
                      id="lockedBtn"
                      href="#active"
                      data-status="locked"
                    >
                      <div className="status-locked ">
                        <div className="sts-icon">
                          <span className="icon">
                            <i className="fa fa-lock" aria-hidden="true" />
                          </span>
                        </div>{" "}
                        locked
                      </div>
                    </a>
                  </div>
                </div>
                <dl className="form_list d-flex mt-3">
                  <a>Password </a>
                  <dd className="mb-2">
                    <input
                      type="password"
                      placeholder="Enter"
                      id="changepassword"
                      formcontrolname="password"
                      className="ng-untouched ng-pristine ng-valid"
                    />
                  </dd>
                  <dt />
                  <dd className="w-100 text-center">
                    <button
                      type="submit"
                      id="changeStatusdBtn"
                      title="Change"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="btn btn2 px-4"
                    >
                      Change
                    </button>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div
          id="changeStatusModal"
          tabindex="-1"
          aria-labelledby="ChangeStatusLabel"
          className="modal fade changepassword-model show"
          data-backdrop="static"
          data-keyboard="false"
          role="dialog"
          style={{ display: "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 id="ChangeStatusLabel" className="modal-title">
                  Change Status
                </h6>
                {/* <button
                        type="button" data-bs-dismiss="modal" aria-label="Close"
                        class="btn-close"><i class="bi bi-x">X</i></button> */}
                <button type="button"
                  className="close"
                  onClick={() => {
                    setUserTypesPassword("");
                    setChangeStatusTo(0)
                  }}
                  data-dismiss="modal">
                  X
                </button>
              </div>
              <div className="modal-body change-status">
                <div className="change-st-box">
                  <div className="chnage-st-name d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      {/* selectedUser.RoleId] */}
                      <span className="account-name-specail-txt">{UserRoleShortName[selectedUser.RoleId]}</span>
                      <b>{selectedUser.name}</b>
                      {/* selectedUser.name */}
                    </p>
                    <a className={currentStatus === 1 ? "activebtn" : currentStatus === 2 ? "blockedbtn" : "suspendbtn"}>
                      {currentStatus === 1 && "Active"}
                      {currentStatus === 2 && "Blocked"}
                      {currentStatus === 3 && "Suspend"}
                    </a>
                  </div>

                  <div className="status-button-group status_but">
                    <input type="hidden" name="UserId" id="statusUserId" />
                    <input type="hidden" name="Status" id="statusStatus" />

                    <button
                      className={`status-button but_active ${changeStatusTo === 1 && "open"}`}
                      id="activeBtn"
                      disabled={currentStatus === 1 && true}
                      // href="#active"
                      style={currentStatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      data-status="active"

                      onClick={() => { setChangeStatusTo(1) }}
                    // style={{background:"green"}}
                    >
                      <div className="status-active " style={currentStatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }} >
                        <div className="sts-icon">
                          <span className="icon">
                            <i
                              className="fa fa-check-circle-o"
                              aria-hidden="true"
                            />
                          </span>
                        </div>{" "}
                        Active
                      </div>
                    </button>

                    <button
                      className={`status-button but_suspend ${changeStatusTo === 3 && "open"}`}
                      style={currentStatus === 3 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      disabled={currentStatus === 3 && true}
                      id="suspendBtn"
                      // href="#active"

                      onClick={() => { setChangeStatusTo(3) }}
                      data-status="suspend"
                    >
                      <div className="status-disbled" style={currentStatus === 3 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                        <div className="sts-icon">
                          <span className="icon">
                            <i className="fa fa-ban" aria-hidden="true" />
                          </span>
                        </div>{" "}
                        Suspend
                      </div>
                    </button>

                    <button
                      className={`status-button but_locked ${changeStatusTo === 2 && "open"}`}
                      style={currentStatus === 2 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                      disabled={currentStatus === 2 && true}
                      id="lockedBtn"
                      // href="#active"
                      onClick={() => { setChangeStatusTo(2) }}
                      data-status="locked"
                    >
                      <div className="status-locked" style={currentStatus === 2 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                        <div className="sts-icon">
                          <span className="icon">
                            <i className="fa fa-lock" aria-hidden="true" />
                          </span>
                        </div>{" "}
                        locked
                      </div>
                    </button>
                  </div>
                </div>
                <dl className="form_list d-flex mt-3">
                  <dt>Password </dt>
                  <dd className="mb-2">
                    <input
                      type="password"
                      value={userTypedPassword}
                      onChange={(passi) => { setUserTypesPassword(passi.target.value) }}
                      placeholder="Enter"
                      className="ng-untouched ng-pristine ng-valid"
                    />
                  </dd>
                  <dt />
                  <dd className="w-100 text-center">
                    <a
                      id="changePasswordBtn1"
                      title="Change"
                      onClick={updatingStatusOfUser}
                      className="btn btn2 px-4"
                      data-dismiss="modal"
                    >
                      Change
                    </a>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div id="AddAdminGS" className="modal changepassword-model">
          <div className="modal-dialog modal-lg">
            <div className="modal-content w-100">
              <div className="modal-header">
                <h4 className="modal-title">Global Settings</h4>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close close_pop"
                >
                  x
                </button>
              </div>
              <div className="row-fluid">
                <div className="span12">
                  <div className="widget">
                    <div className="widget-body form">
                      <div className="ng-untouched ng-pristine ng-valid">
                        <div className="global-setting-box mb-3">
                          <input type="hidden" name="GSUserId" id="GSUserId" />
                          <div className="setting-titile"> Limit Setting </div>
                          <div className="setting-table profile-section">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th width="25%" />
                                  <th width="50%">Min</th>
                                  <th width="50%">Max</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Odd</td>
                                  <td>
                                    <input
                                      type="number"
                                      formcontrolname="odds_min"
                                      id="odds_min"
                                      name="odds_min"
                                      placeholder="Min"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      formcontrolname="odds_max"
                                      id="odds_max"
                                      name="odds_max"
                                      placeholder="Max"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Book</td>
                                  <td>
                                    <input
                                      type="number"
                                      formcontrolname="bookmaker_min"
                                      id="bookmaker_min"
                                      name="bookmaker_min"
                                      placeholder="Min"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      formcontrolname="bookmaker_max"
                                      id="bookmaker_max"
                                      name="bookmaker_max"
                                      placeholder="Max"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Fancy</td>
                                  <td>
                                    <input
                                      type="number"
                                      formcontrolname="fancy_min"
                                      id="fancy_min"
                                      name="fancy_min"
                                      placeholder="Min"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      formcontrolname="fancy_max"
                                      id="fancy_max"
                                      name="fancy_max"
                                      placeholder="Max"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="global-setting-box mb-3">
                          <div className="setting-titile"> Delay Setting </div>
                          <div className="setting-table profile-section">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td width="22%">Odds Delay </td>
                                  <td>
                                    <input
                                      type="text"
                                      formcontrolname="odds_delay"
                                      id="odds_delay"
                                      name="odds_delay"
                                      placeholder="Session min limit"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                  <td width="22%">Fancy Delay</td>
                                  <td>
                                    <input
                                      type="text"
                                      formcontrolname="fancy_delay"
                                      id="fancy_delay"
                                      name="fancy_delay"
                                      placeholder="Session max limit"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td width="22%">Bookmaker Delay</td>
                                  <td>
                                    <input
                                      type="text"
                                      formcontrolname="bookmaker_delay"
                                      id="bookmaker_delay"
                                      name="bookmaker_delay"
                                      placeholder="Bookmaker limit"
                                      className="table-input ng-untouched ng-pristine ng-valid"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="global-setting-box mb-5 d-flex justify-content-end px-3">
                          <div className="form-inline">
                            <input
                              type="password"
                              id="GSPassword"
                              formcontrolname="transaction_password"
                              placeholder="Password"
                              className="form-control datatable-search float-right ng-untouched ng-pristine ng-valid"
                            />
                          </div>
                          <button
                            type="submit"
                            onclick="submitAddAdminGS()"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="btn btnblk btn-sm w-auto ms-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="AddAdminSP"
          className="modal changepassword-model"
          aria-hidden="true"
        >
          <input type="hidden" name="spUserId" id="spUserId" />
          <div className="modal-dialog" id="SpList"></div>
        </div>
        {/*<div id="CasinoSetting" class="modal changepassword-model fade">
      <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Casino Settings</h4>
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close">*/}
        {/*x
            </button>
        </div>
        <div class="row-fluid">
            <div class="span12">
                <div class="widget">
                    <div class="widget-body form">
                        <form name="BetPlayer" id="BetPlayer" autocomplete="off" class="ng-untouched ng-pristine ng-valid">
                            <div class="portlet-body">
                                <div class="space15"></div>
                            </div>
                            <table class="table">
                                <thead>
                                <th>Sr.No</th>
                                <th>Casino Name</th>
                                <th>Action</th>
                                </thead>
                                <tbody>
                                    <ngx-spinner type="ball-scale-multiple" class="ng-tns-c31-19 ng-star-inserted">
  
                                    </ngx-spinner>
                                    <tr class="ng-star-inserted">
                                        <td>1</td>
                                        <td>World Casino</td>
                                        <td>
                                            <div class="form-check form-switch text-center">
                                                <input type="checkbox" role="switch" id="flexSwitchCheckChecked" class="form-check-input">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="ng-star-inserted">
                                        <td>2</td>
                                        <td>KINGMAKER</td>
                                        <td>
                                            <div class="form-check form-switch text-center">
                                                <input type="checkbox" role="switch" id="flexSwitchCheckChecked" class="form-check-input">
                                            </div>
                                        </td>
                                    </tr>
  
  
                                    <tr hidden="">
                                        <td colspan="6" style="text-align: center;">No Matches Available !</td>
                                    </tr>
                                </tbody>
                            </table>
  
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
  </div>*/}
      </main>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="../../../images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="../../../images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
    </>

  )
}

export default AdminDashboard
