export const addViewportMetaTag = () => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1.0';
    metaTag.id = 'viewport-meta-tag';
    document.head.appendChild(metaTag);
    console.log('meta tag addedd ...')
};

export const removeViewportMetaTag = () => {
    const metaTag = document.getElementById('viewport-meta-tag');
    if (metaTag) {
        document.head.removeChild(metaTag);
    }

    console.log('meta tag removed ...')
};