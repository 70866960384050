import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../../context/currentUser/currentUser';
import { toast } from "react-toastify"

function PaymentSetting() {


    const context = useContext(currentUser);
    const { isLoggedIn } = context;
    const userIdLS = localStorage.getItem(`UserId`);



    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputPaymentSlip, setInputPaymentSlip] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [displayName, setDisplayName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [personalNumber, setPersonalNumber] = useState('');
    const [paymentType, setPaymentType] = useState('Bkash');
    const [paymentSubType, setPaymentSubType] = useState('Agent');
    const [errMsg, setErrMsg] = useState('');
    const [selectedUser, setSelectedUser] = useState({ name: "", RoleId: 0, userstatus: 0, id: 0, paymentType: '' });
    const [changeActionTo, setChangeActionTo] = useState(3);
    const [fetchedBankDetail, setFetchedBankDetail] = useState([]);


    const fetchPaymentDetails = async () => {
        try {
            setIsLoading(true);

            // Fetch profit loss of all self users users for pagination
            let fetchDetails = await fetch(`https://api.betcare.live/api/admin/selfUser/fetchPaymentDetails`);
            let resData = await fetchDetails.json();

            if (fetchDetails.status === 200) {
                console.log("Payment Details fetched! : ", resData);
                setData(resData?.data);
            } else {
                console.log("Error in Payment Details fetched");
            }

        } catch (error) {
            console.error("Error fetching Payment Details");
        } finally {
            setIsLoading(false);
        }
    };


    const AddPaymentDetail = async (e) => {

        e.preventDefault();
        console.log('displayName : ', displayName, ' accountNumber : ', accountNumber,  ' paymentType : ', paymentType, ' inputPaymentSlip : ', inputPaymentSlip)

        if (!displayName || !accountNumber) {
            setErrMsg('Fill all input fields before submit!')
            return;
        }

        if (!inputPaymentSlip) {
            setErrMsg('Upload Screenshot of QR!')
            return;
        }

        setErrMsg('');

        const formData = new FormData();
        formData.append('userId', userIdLS);
        formData.append('userName', displayName);
        formData.append('accountNumber', accountNumber);
        formData.append('paymentType', paymentType);
        formData.append('paymentSubType', paymentSubType);
        formData.append('image', inputPaymentSlip);


        const res = await fetch(`https://api.betcare.live/api/admin/selfUser/addBankDetail`, {
            method: 'POST',
            body: formData
        });

        console.log('add bank account res : ', res);
        const resData = await res.json();
        console.log('bank added resData : ', resData);
        if (res.status == 200) {
            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
            fetchPaymentDetails();
            setChangeActionTo(3);
            document.getElementById('AddBankAccountModal').style.display = 'none';
            document.body.classList.remove('modal-open');
            document.querySelector('.modal-backdrop').remove();
            setDisplayName('');
            setAccountNumber('');
            setPersonalNumber('');
            setPaymentType('Bkash');
            setPaymentSubType('Agent');
            setInputPaymentSlip(null);
            setImagePreview(null);
        } else {
            toast.error(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
        }

    }

    const updatingStatusOfBankDetail = async () => {

        console.log("Selected User : ", selectedUser);

        if (changeActionTo == 3) {
            return;
        }


        let res = await fetch(`https://api.betcare.live/api/admin/selfUser/changeBankDetailStatus/${selectedUser.id}/${changeActionTo == 3 ? selectedUser.userstatus : changeActionTo}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ srno: selectedUser.srno, paymentType: selectedUser.paymentType })
        });

        let resData = await res.json();

        if (res.status === 200) {
            toast.success(`Status Changed!`, {
                autoClose: 1500,
                theme: "colored",
            });
            setChangeActionTo(3);
            fetchPaymentDetails();
        } else {
            toast.error(`Try again later!`, {
                autoClose: 1500,
                theme: "colored",
            });
            setChangeActionTo(3);
        }
    }

    const fetchBankDetail = async (id, userId) => {
        try {
            const res = await fetch(`https://api.betcare.live/admin/api/selfUser/fetchBankDetail/${id}/${userId}`);
            const resData = await res.json();
            if (res.ok) {
                console.log('fetched payment : ', resData);
                if (resData?.data?.length > 0) {
                    setFetchedBankDetail(resData?.data);
                }
            }
        } catch (error) {
            console.log('Error while fetching detail : ', error);
        }
    }



    useEffect(() => {
        fetchPaymentDetails();
    }, []);

    return (
        <>
            <style>{`
div::-webkit-scrollbar {
display: none;
}
`}</style>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">
                <div className="inner-pages">
                    <div className="ng-untouched ng-pristine ng-valid">
                        <div className="container-fluid">
                            <div className="right-add-admin" id="showBtn" style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
                                <div className="page-title" style={{ fontSize: 16 }}>
                                    <h6 className="my-2">Payment Setting </h6>
                                </div>
                                <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#AddBankAccountModal"
                                    className="light-btn ng-star-inserted mt-2"
                                >
                                    <i className="fa fa-bank" aria-hidden="true" style={{ fontSize: '20px', marginRight: '2px' }} />
                                    <span id="crtBtn">Add Account</span>
                                </button>
                            </div>
                            <div className="download-list">
                                <div className="">
                                    <div className="profile-section mt-3">
                                        <div className="table-responsive  text-end">
                                            <table className="table table01">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Sr No</th>
                                                        <th>Account Name</th>
                                                        <th>Number</th>
                                                        <th>Payment Type</th>
                                                        <th>Account Type</th>
                                                        <th>Detail</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.length > 0 && data?.map((item, index) => (

                                                        <tr className="ng-star-inserted" key={item.id}>
                                                            <td className=" ng-star-inserted">{index + 1}</td>
                                                            <td>
                                                                {item.DisplayName}
                                                            </td>
                                                            <td>{item.AccountNumber}</td>
                                                            <td>{item.PaymentType} <img src={item.PaymentType == 'Rocket' ? "/images/banglaRocketLogo.svg" : item.PaymentType == 'Bkash' ? "/images/BKashLogo.png" : item.PaymentType == 'Nagad' ? "/images/NagadLogo.png" : ''} alt="" srcset="" style={{ width: '50px', height: '30px' }} /></td>
                                                            
                                                            <td>{item.PaymentSubType}</td>
                                                            <td>
                                                                <button className='px-2 py-1 border rounded'
                                                                    style={{
                                                                        backgroundColor: '#71b8e4',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    data-toggle="modal" data-target="#checkBankDetailModal"
                                                                    onClick={() => { fetchBankDetail(item.SrNo, item.UserID) }}
                                                                >
                                                                    Detail
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <span className={item.Status == 1 ? "activebtn" : 'suspendbtn'} style={{ marginLeft: "10px" }}>{item.Status == 1 ? 'Active' : 'Inactive'}</span>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#changeActionModal"
                                                                    onClick={() => { setSelectedUser({ name: item.DisplayName, RoleId: 8, userstatus: item.Status, id: item.UserID, srno: item.SrNo, paymentType: item.PaymentType }) }}
                                                                >
                                                                    Action
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Check Bank detail modal */}
                            <div
                                id="checkBankDetailModal"
                                className="modal fade changepassword-model"
                                style={{ display: "none" }}
                                data-backdrop="static"
                                aria-modal="true"
                                role="dialog"
                            >
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content w-100">
                                        <div className="modal-header">
                                            <h6 id="logmodelLabel" className="modal-title">
                                                Bank Account Detail
                                            </h6>
                                            <button type="button"
                                                className="close p-1"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid',
                                                    fontWeight: 'bolder'
                                                }}
                                                onClick={() => {
                                                    setFetchedBankDetail([])
                                                }}
                                                data-dismiss="modal">
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status" id="statementList">
                                            <div className="profile-section mt-0 unmatech-section">

                                                {fetchedBankDetail?.length > 0 && fetchedBankDetail?.map((item) => (

                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                        <div className='p-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '1rem', width: '100%' }}>
                                                            <div style={{ fontSize: '1.4em' }}>
                                                                <p><b>Display Name : </b>{item.DisplayName}</p>
                                                                <p><b>Number : </b>{item.AccountNumber}</p>
                                                                <p><b>Payment Type : </b>{item.PaymentType}<img src={item.PaymentType == 'Rocket' ? "/images/banglaRocketLogo.svg" : item.PaymentType == 'Bkash' ? "/images/BKashLogo.png" : item.PaymentType == 'Nagad' ? "/images/NagadLogo.png" : ''} alt="" srcset="" style={{ width: '50px', height: '30px' }} /></p>
                                                                <p><b>Account Type : </b>{item.PaymentSubType}</p>
                                                                {/* <p><b>UPI ID : </b>{item.UPIId}</p> */}
                                                                <p><b>Status : </b>{item.Status == 1 ? 'Active' : 'Inactive'}</p>
                                                            </div>
                                                        </div>

                                                        <div style={{ width: '100%', height: '', border: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        >
                                                            <img src={`/bankImage/${item.QRCode}`} alt="" srcset="" style={{
                                                                width: '20em',
                                                                maxWidth: '100%',
                                                                height: '100%',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center',

                                                            }} />

                                                        </div>


                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Action modal */}
                            <div
                                id="changeActionModal"
                                tabindex="-1"
                                aria-labelledby="ChangeStatusLabel"
                                className="modal fade changepassword-model show"
                                data-backdrop="static"
                                data-keyboard="false"
                                role="dialog"
                                style={{ display: "none" }}
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h6 id="ChangeStatusLabel" className="modal-title">
                                                Change Status
                                            </h6>
                                            {/* <button
                        type="button" data-bs-dismiss="modal" aria-label="Close"
                        class="btn-close"><i class="bi bi-x">X</i></button> */}
                                            <button type="button"
                                                className="close p-1"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid',
                                                    fontWeight: 'bolder'
                                                }}
                                                onClick={() => {
                                                    // setUserTypesPassword("");
                                                    setChangeActionTo(3)
                                                }}
                                                data-dismiss="modal"
                                            >
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status">
                                            <div className="change-st-box">
                                                <div className="chnage-st-name d-flex justify-content-between align-items-center">
                                                    <p className="mb-0">
                                                        {/* selectedUser.RoleId] */}
                                                        <span className="account-name-specail-txt">PL</span>
                                                        <b>{selectedUser.name}</b>
                                                        {/* selectedUser.name */}
                                                    </p>
                                                    <a className={selectedUser.userstatus === 1 ? "activebtn" : "suspendbtn"}>
                                                        {selectedUser.userstatus === 1 && "Active"}
                                                        {selectedUser.userstatus === 0 && "Inactive"}
                                                    </a>
                                                </div>

                                                <div className="status-button-group status_but">
                                                    <input type="hidden" name="UserId" id="statusUserId" />
                                                    <input type="hidden" name="Status" id="statusStatus" />

                                                    <button
                                                        className={`status-button but_active ${changeActionTo === 1 && "open"}`}
                                                        id="activeBtn"
                                                        disabled={selectedUser.userstatus === 1 && true}
                                                        // href="#active"
                                                        style={selectedUser.userstatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        data-status="active"

                                                        onClick={() => { setChangeActionTo(1) }}
                                                    // style={{background:"green"}}
                                                    >
                                                        <div className="status-active " style={selectedUser.userstatus === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }} >
                                                            <div className="sts-icon">
                                                                <span className="icon">
                                                                    <i
                                                                        className="fa fa-check-circle-o"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </div>{" "}
                                                            Approve
                                                        </div>
                                                    </button>

                                                    <button
                                                        className={`status-button but_suspend ${changeActionTo === 0 && "open"}`}
                                                        style={selectedUser.userstatus === 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        disabled={selectedUser.userstatus === 0 && true}
                                                        id="suspendBtn"
                                                        // href="#active"

                                                        onClick={() => { setChangeActionTo(0) }}
                                                        data-status="suspend"
                                                    >
                                                        <div className="status-disbled" style={selectedUser.userstatus === 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                                                            <div className="sts-icon">
                                                                <span className="icon">
                                                                    <i className="fa fa-ban" aria-hidden="true" />
                                                                </span>
                                                            </div>{" "}
                                                            Inactive
                                                        </div>
                                                    </button>

                                                </div>
                                            </div>
                                            <dl className="form_list d-flex mt-3">
                                                <dt />
                                                <dd className="w-100 text-center">
                                                    <a
                                                        id="changePasswordBtn1"
                                                        title="Change"
                                                        onClick={updatingStatusOfBankDetail}
                                                        className="btn btn2 px-4"
                                                        data-dismiss="modal"
                                                    >
                                                        Change
                                                    </a>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Add Bank Account Modal */}
                            <div
                                id="AddBankAccountModal"
                                className="modal fade changepassword-model"
                                style={{ display: "none" }}
                                data-backdrop="static"
                                aria-modal="true"
                                role="dialog"
                            >
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content w-100">
                                        <div className="modal-header">
                                            <h6 id="logmodelLabel" className="modal-title">
                                                Add Account
                                            </h6>
                                            <button type="button"
                                                className="close p-1"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid',
                                                    fontWeight: 'bolder'
                                                }}
                                                onClick={() => {
                                                    setChangeActionTo(3)
                                                    setDisplayName('');
                                                    setAccountNumber('');
                                                    setPersonalNumber('');
                                                    setPaymentType('Bkash');
                                                    setInputPaymentSlip(null);
                                                }}
                                                data-dismiss="modal">
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status" id="statementList">
                                            <div className="profile-section mt-0 unmatech-section p-3">
                                                <div className='d-flex flex-column'>
                                                    <div className='mb-1'>
                                                        <label htmlFor="displayname" className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold', }}>Display Name</label>
                                                        <input
                                                            type="text"
                                                            id='displayname'
                                                            className='w-100'
                                                            style={{ outline: 'none', fontSize: '1.2em', border: '1px solid', height: '2.5em' }}
                                                            value={displayName}
                                                            onChange={(e) => {
                                                                setDisplayName(e.target.value);
                                                                setErrMsg('');
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mb-3'>
                                                        <label className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Number</label>
                                                        <input
                                                            type="text"
                                                            className='w-100'
                                                            style={{ outline: 'none', fontSize: '1.2em', border: '1px solid', height: '2.5em' }}
                                                            value={accountNumber}
                                                            pattern="[0-9]*"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setAccountNumber(value);
                                                                setErrMsg('');
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div className='mb-3'>
                                                        <label className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Personal Number</label>
                                                        <input
                                                            type="text"
                                                            className='w-100'
                                                            style={{ outline: 'none', fontSize: '1.2em', border: '1px solid', height: '2.5em' }}
                                                            value={personalNumber}
                                                            pattern="[0-9]*"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setPersonalNumber(value);
                                                                setErrMsg('');
                                                            }}
                                                        />
                                                    </div> */}
                                                    <div className='d-flex flex-column  mb-3'>
                                                        <label htmlFor="displayname" className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold', }}>Payment Type</label>
                                                        <select
                                                            name=""
                                                            id=""
                                                            style={{ fontSize: '1.2em', height: '2.5em', border: '1px solid', borderRadius: '5px' }}
                                                            value={paymentType}
                                                            onChange={(e) => setPaymentType(e.target.value)}
                                                        >
                                                            <option value="Bkash">Bkash</option>
                                                            <option value="Nagad">Nagad</option>
                                                            <option value="Rocket">Rocket</option>
                                                        </select>
                                                    </div>

                                                    <div className='d-flex flex-column  mb-3'>
                                                        <label htmlFor="displayname" className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold', }}>Account Type</label>
                                                        <select
                                                            name=""
                                                            id=""
                                                            style={{ fontSize: '1.2em', height: '2.5em', border: '1px solid', borderRadius: '5px' }}
                                                            value={paymentSubType}
                                                            onChange={(e) => setPaymentSubType(e.target.value)}
                                                        >
                                                            <option value="Agent">Agent</option>
                                                            <option value="Personal">Personal</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md mb-3 d-flex flex-column justify-content-center">
                                                        <label for="formFileLg" className="form-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Upload Screenshot of Qr Code</label>
                                                        <input
                                                            className="form-control form-control-lg"
                                                            id="formFileLg"
                                                            type="file"
                                                            accept="image/png, image/jpeg, image/jpg, image/heic"
                                                            placeholder=''
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file) {
                                                                    setInputPaymentSlip(file);
                                                                    setImagePreview(URL.createObjectURL(file));
                                                                    setErrMsg('');
                                                                } else {
                                                                    setInputPaymentSlip('No file chosen');
                                                                    setImagePreview(null);
                                                                    setErrMsg('Upload not uploaded!');
                                                                }
                                                            }}
                                                            style={{ fontSize: '1.2em', width: '100%', border: '1px solid', borderRadius: '5px' }}
                                                        />

                                                        {imagePreview && (
                                                            <div className='d-flex justify-content-center align-items-center mt-4'>
                                                                <div>
                                                                    <div className='d-flex justify-content-end cursor-pointer'
                                                                        style={{ zIndex: '999', position: 'relative', top: '12px', left: '5px' }}
                                                                        onClick={() => {
                                                                            setInputPaymentSlip('No file chosen');
                                                                            setImagePreview(null);
                                                                        }} >
                                                                        <div className='d-flex justify-content-center align-items-center '
                                                                            style={{ width: '24px', height: '24px', backgroundColor: '#e9ecef', borderRadius: '100%' }}>
                                                                            <img src="/images/utr-remove-icon.svg" alt="" style={{ width: '12px', height: '12px' }} />
                                                                        </div>
                                                                    </div>
                                                                    <img
                                                                        src={imagePreview}
                                                                        alt="Uploaded Preview"
                                                                        className='cursor-pointer'
                                                                        style={{ maxHeight: '200px', maxWidth: '100%' }} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-12 mb-3 mt-3 d-flex flex-column justify-content-center align-items-center ">
                                                        <span style={{ color: 'red', fontSize: '1em', fontWeight: 'bold' }}>{errMsg}</span>
                                                        <button
                                                            className=" w-100"
                                                            type="button"
                                                            style={{
                                                                fontSize: '1.8em',
                                                                fontWeight: 'bold',
                                                                padding: '8px 10px 8px 10px',
                                                                borderRadius: '5px'
                                                            }}
                                                            onClick={(e) => AddPaymentDetail(e)}
                                                        >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default PaymentSetting