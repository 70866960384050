import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css'
import SportSetting from './pages/settingPages/SportSetting';
// import Header from './components/Header';
import MatchSetting from './pages/settingPages/MatchSetting';
import GlobalSetting from './pages/settingPages/GlobalSetting';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/authentication/Login';
import AdminDashboard from './pages/dashboard/AdminDashboard';
import Layout from './components/Layout';
import CurrentUserState from './context/currentUser/CurrentUserState';
import BetList from './pages/BetList';
import BetDeleteHistory from './pages/settingPages/BetDeleteHistory';
import BetListLive from './BetListLive';
import AccountSummary from './pages/Account/AccountSummary';
import ActivityLog from './pages/Account/ActivityLog';
import BettingHistory from './pages/Account/BettingHistory';
import BettingProfitLoss from './pages/Account/BettingProfitLoss';
import TransactionHistory from './pages/Account/TransactionHistory';
import Banking from './pages/Banking';
import ProfitLossReportByDownline from './pages/Report/ProfitLossReportByDownline';
import ProfitLossReportByMarket from './pages/Report/ProfitLossReportByMarket';
import OnlineUser from './pages/settingPages/OnlineUser';
import SeriesSetting from './pages/settingPages/SeriesSetting';
import Login1 from './pages/authentication/Login1';
import Login2 from './pages/authentication/Login2';
import MetaManager from './components/MetaManager';
import Login3 from './pages/authentication/Login3';
import SelfUserList from './pages/SelfUserPages/SelfUserList';
import DepositRequest from './pages/SelfUserPages/DepositRequest';
import WithdrawRequest from './pages/SelfUserPages/WithdrawRequest';
import SelfUserBetHistory from './pages/SelfUserPages/SelfUserBetHistory';
import SelfUserBetListLive from './pages/SelfUserPages/SelfUserBetListLive';
import SelfUserTransactionHistory from './pages/SelfUserPages/SelfUserTransactionHistory';
import SelfUserProfitLoss from './pages/SelfUserPages/SelfUserProfitLoss';
import SelfUserProfitLossByMarket from './pages/SelfUserPages/SelfUserProfitLossByMarket';
import SelfUserDeletedBetHistory from './pages/SelfUserPages/SelfUserDeletedBetHistory';
import PaymentSetting from './pages/SelfUserPages/PaymentSetting';
import Referral from './pages/SelfUserPages/Referral';



function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <BrowserRouter>
        <CurrentUserState>
          <ToastContainer />
          <MetaManager />
          {/* <Header/> */}
          <Routes>

            <Route path='/login' element={isMobile ? <Login3 /> : <Login />} />
            <Route path='*' element={<h1>404 , PAGE NOT EXIST</h1>} />
            <Route path='/' element={<Layout />}>
              <Route index element={<AdminDashboard />} />
              {/* self user routes start*/}
              <Route path='/selfUserList' element={<SelfUserList />} />
              <Route path='/depositRequest' element={<DepositRequest />} />
              <Route path='/withdrawRequest' element={<WithdrawRequest />} />
              <Route path='/selfUserBetHistory' element={<SelfUserBetHistory />} />
              <Route path='/selfUserBetListLive' element={<SelfUserBetListLive />} />
              <Route path='/selfUserTransactionHistory' element={<SelfUserTransactionHistory />} />
              <Route path='/selfUserPL' element={<SelfUserProfitLoss />} />
              <Route path='/selfUserPLByMarket' element={<SelfUserProfitLossByMarket />} />
              <Route path='/selfUserDeletedBet' element={<SelfUserDeletedBetHistory />} />
              <Route path='/PaymentSetting' element={<PaymentSetting />} />
              <Route path='/referral' element={<Referral />} />
              {/* self user routes start*/}
              <Route path='/sportSetting' element={<SportSetting />} />
              <Route path="/matchSetting" element={<MatchSetting />} />
              <Route path="/seriesSetting" element={<SeriesSetting />} />
              <Route path='/globalSetting' element={<GlobalSetting />} />
              <Route path='/onlineUser' element={<OnlineUser />} />
              <Route path="/betdeletehistory" element={<BetDeleteHistory />} />
              <Route path='/betList' element={<BetList />} />
              <Route path='/betlistlive' element={<BetListLive />} />
              <Route path='/banking' element={<Banking />} />
              <Route path="/accountsummary" element={<AccountSummary />} />
              <Route path='/activitylog' element={<ActivityLog />} />
              <Route path='/bettinghistory' element={<BettingHistory />} />
              <Route path='/bettingprofitloss' element={<BettingProfitLoss />} />
              <Route path='/transactionhistory' element={<TransactionHistory />} />
              <Route path='/Report/profitLossReportByDownline' element={<ProfitLossReportByDownline />} />
              <Route path='/Report/profitLossReportByMarket' element={<ProfitLossReportByMarket />} />

            </Route>
          </Routes>


        </CurrentUserState>
      </BrowserRouter>
    </>
  )
}

export default App
