import React, { useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { FiPlusSquare } from "react-icons/fi";
import { FaRegSquareMinus } from "react-icons/fa6";
function SelfUserProfitLossByMarket() {


    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterdat1 = new Date();
    let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let yesterday = YesterdayM.format(`YYYY-MM-DD`);

    const userIdLS = localStorage.getItem(`UserId`);

    const [openMoreIndex, setOpenMoreIndex] = useState(null);
    const [subPL, setSubPL] = useState([])
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [fromDate, setFromDate] = useState(today);
    const [fromTime, setFromTime] = useState(`00:00:00`);
    const [toDate, setToDate] = useState(Tommorrow);
    const [toTime, setToTime] = useState(`23:59:59`);
    const [sportType, setSportType] = useState("all");
    const [loadPage, setLoadPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { data: profitlossMarket, isLoadingg, isError } = useQuery({
        queryKey: [`profit-loss-market`, page, sportType, loadPage], queryFn: async () => {
            try {
                setIsLoading(true)

                let res = await axios.get(`https://api.betcare.live/api/admin/selfUser/getAllSelfUserPLMarket/${sportType}?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`);
                let resData = await res.data;
                // console.log('self user pl by market  : ',resData)
                return resData;
            } catch (error) {
                console.error('Error in fetching PL Market data')
            } finally {
                setIsLoading(false)
            }
        }
    });

    // console.log("profit loss data : ", profitlossMarket)

    // console.log('From Date and Time : ', fromDate, " ", fromTime);
    // console.log('To Date and Time : ', toDate, " ", toTime);
    // console.log('Sports type : ', sportType, page, pageSize)


    const setDateFunc = async (btnName) => {

        if (btnName === 'today') {
            setFromDate(today);
            setFromTime(`00:00:00`);
            setToDate(Tommorrow);
            setToTime(`12:59:59`);
            setPage(1);
            setLoadPage((prev) => !prev);

        } else if (btnName === "yesterday") {

            setFromDate(yesterday);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);

        }
    }

    const onChangeHandler = (e, dateTimeType) => {
        if (dateTimeType === "fromDate") {
            setFromDate(e.target.value);
        }
        else if (dateTimeType === "fromTime") {
            setFromTime(e.target.value);
        }
        else if (dateTimeType === "toDate") {
            setToDate(e.target.value);
        }
        else if (dateTimeType === "toTime") {
            setToTime(e.target.value);
        }
    }

    const openSubEvent = async (index, eventId, marektType, eid, TotalAmountStake, TotalResultAmount, EventName) => {
        if (index == null) {
            setOpenMoreIndex(null)
        } else {
            setOpenMoreIndex(index)
            console.log(eventId, marektType, eid)
            if (marektType == 'Fancy') {
                let res = await axios.get(`https://api.betcare.live/api/admin/getSubPLMarket/${userIdLS}/${eventId}/${marektType}/${eid}`);
                let resData = await res.data;
                setSubPL(resData)
            } else {
                let data = [
                    {

                        "Selection": EventName,
                        "TotalResultAmount": TotalResultAmount,
                        "TotalAmountStake": TotalAmountStake
                    }
                ]
                setSubPL(data)
            }
        }
    }

    async function getPLMarket() {
        setLoadPage((prev) => !prev);
        setPage(1);
    }


    return (
        <>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <div className="inner-pages">
                <div className="container mxw-100">
                    <div className="page-title" style={{ fontSize: 16 }}>
                        <h6 className="my-2">Profit/Loss Report by Market </h6>
                    </div>
                    <div className="ng-untouched ng-pristine ng-valid">
                        <div className="my-report">
                            <div className="time-zone-section">
                                <ul className="list-unstyled">
                                    <li>
                                        <label className="my-1 mr-2">Sports</label>
                                        <select
                                            id="sports"
                                            name="sports"
                                            value={sportType}
                                            onChange={(e) => { setSportType(e.target.value); setPage(1); }}
                                            className="form-select my-1 mr-sm-2"
                                        >
                                            <option value={`all`}>All</option>
                                            <option value={`1`} className="ng-star-inserted">
                                                Soccer
                                            </option>
                                            <option value={2} className="ng-star-inserted">
                                                Tennis
                                            </option>
                                            <option value={4} className="ng-star-inserted">
                                                Cricket
                                            </option>
                                        </select>
                                    </li>
                                    <li>
                                        <label className="my-1 mr-2 fw-normal">Period</label>
                                        <input
                                            type="date"
                                            formcontrolname="periodFormDate"
                                            value={fromDate}
                                            onChange={(e) => { onChangeHandler(e, "fromDate") }}
                                            id="sdate"
                                            className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                            style={{ width: '100%' }}
                                        />
                                        {/* <input
                                    type="time"
                                    placeholder="9:00:00"
                                    formcontrolname="periodFormTime"
                                    value={fromTime}
                                    onChange={(e) => { onChangeHandler(e, "fromTime") }}
                                    id="stime"
                                    ng-model="datetime.time"
                                    className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                /> */}
                                        <span className="d-inline-flex mx-2">To</span>
                                        <input
                                            type="date"
                                            formcontrolname="periodtoDate"
                                            value={toDate}
                                            onChange={(e) => { onChangeHandler(e, "toDate") }}
                                            id="edate"
                                            className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                            style={{ width: '100%' }}
                                        />
                                        {/* <input
                                    type="time"
                                    placeholder="8:59:59"
                                    id="etime"
                                    formcontrolname="periodtotime"
                                    value={toTime}
                                    onChange={(e) => { onChangeHandler(e, "toTime") }}
                                    className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                /> */}
                                    </li>
                                </ul>
                                <ul className="list-unstyled report-btn-group">
                                    <li>
                                        <button
                                            type="button"
                                            className={`${fromDate === today ? toDate === Tommorrow ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                            onClick={() => { setDateFunc(`today`) }}
                                        >
                                            Just For Today
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className={`${fromDate === yesterday ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                            onClick={() => { setDateFunc(`yesterday`) }}
                                        >
                                            From Yesterday
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="submit"
                                            onClick={getPLMarket}
                                            className="btnblk"
                                        >
                                            Get P &amp; L
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="MarketList">
                            <div className="profile-section mt-4 ng-star-inserted">
                                <div className="table-responsive">
                                    <table className="table table-pt ng-star-inserted">
                                        <thead>
                                            <tr>
                                                <th>SrNo</th>
                                                <th className="text-start ng-star-inserted">UID</th>
                                                <th style={{ textAlign: "left" }}>Stake</th>
                                                <th style={{ textAlign: "left" }}>Player P/L </th>
                                                {/* <th style={{ textAlign: "right" }}>Downline P/L </th>
                                                <th style={{ textAlign: "right" }}>Super Comm.</th>
                                                <th style={{ textAlign: "right" }}>Upline P/L</th> */}
                                            </tr>
                                        </thead>
                                        {profitlossMarket?.rows?.map((item, index) => {

                                            return (
                                                <tbody key={item.Id}>
                                                    <tr className="ng-star-inserted">
                                                        <td>{(((page - 1) * pageSize) + (index + 1))}</td>
                                                        <td id={`userFullName_${index}`} className="text-start ng-star-inserted">
                                                            <span>
                                                                {openMoreIndex === index ? (
                                                                    <FaRegSquareMinus
                                                                        className="cursor-pointer"
                                                                        style={{ fontSize: "15px", cursor: "pointer" }}
                                                                        onClick={() => openSubEvent(null, '', '', '', '', '', '')}
                                                                    />
                                                                ) : (
                                                                    <FiPlusSquare
                                                                        className="cursor-pointer"
                                                                        style={{ fontSize: "15px", cursor: "pointer" }}
                                                                        onClick={() => openSubEvent(index, item.EventId, item.Market, item.SportId, item.TotalAmountStake, item.TotalResultAmount, item.EventName)}

                                                                    />
                                                                )}
                                                            </span>
                                                            {/* <span className="lv_1">{UserRoleShortName[item.RoleId]}</span>{" "} */}
                                                            {item.SportId == 1 ? ' SOCCER > ' : item.SportId == 2 ? ' TENNIS > ' : item.SportId == 4 ? ' CRICKET > ' : ''}{item.EventName} ({item.Market})
                                                        </td>
                                                        <td className={item.TotalAmountStake < 0 ? "red" : ""}>
                                                            {item.TotalAmountStake != null ? item.TotalAmountStake : 0}
                                                        </td>
                                                        <td className={item.TotalResultAmount < 0 ? "red" : ""}>
                                                            {item.TotalResultAmount != null ? item.TotalResultAmount : 0}
                                                        </td>
                                                        {/* <td className={item.TotalResultAmount < 0 ? "red" : ""}>
                                                            {item.TotalResultAmount != null ? item.TotalResultAmount : 0}
                                                        </td>
                                                        <td >0</td>
                                                        <td className={item.TotalResultAmount < 0 ? "red" : ""}>
                                                            {item.TotalResultAmount != null ? item.TotalResultAmount : 0}
                                                        </td> */}
                                                    </tr>
                                                    {openMoreIndex === index && subPL.length > 0 && subPL.map((sub, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index} className={`table-secondary`}>
                                                                    <td></td>
                                                                    <td className="text-start ng-star-inserted" style={{ paddingLeft: '9%' }}>{sub.Selection}</td>
                                                                    <td>{sub.TotalAmountStake}</td>
                                                                    <td className={item.TotalResultAmount < 0 ? "red" : ""}>{sub.TotalResultAmount}</td>
                                                                    {/* <td className={item.TotalResultAmount < 0 ? "red" : ""}>{sub.TotalResultAmount}</td>
                                                                    <td>0</td>
                                                                    <td className={item.TotalResultAmount < 0 ? "red" : ""}>{sub.TotalResultAmount}</td> */}
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            )
                                        }
                                        )}

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination-controls className="text-center">
                    <pagination-template>
                        <nav aria-label="Pagination">
                            <ul className="ngx-pagination ng-star-inserted">
                                <li
                                    className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                                >
                                    <button
                                        className="ng-star-inserted"
                                        style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                        onClick={() => { setPage((prev) => prev - 1) }}
                                        disabled={page === 1 && true}
                                    >
                                        Previous{" "}
                                    </button>
                                </li>
                                <li
                                    className={`pagination-next  ${page === Math.ceil(profitlossMarket?.totalrows / pageSize) ? "disabled" : profitlossMarket?.totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                                >
                                    <button
                                        // aria-disabled="true"
                                        className="ng-star-inserted"
                                        style={page === Math.ceil(profitlossMarket?.totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : profitlossMarket?.totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                        onClick={() => setPage((prev) => prev + 1)}
                                        disabled={page === Math.ceil(profitlossMarket?.totalrows / pageSize) ? true : profitlossMarket?.totalrows <= 20 ? true : false}
                                    >Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </pagination-template>
                </pagination-controls>
            </div>
        </>
    )
}

export default SelfUserProfitLossByMarket