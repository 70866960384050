import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import currentUser from '../../context/currentUser/currentUser';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const BettingHistory = () => {

  let today1 = new Date();
  let todayM = moment(today1);
  let today = todayM.format(`YYYY-MM-DD`);

  let Tommorrow1 = new Date();
  let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
  let TommorrowM = moment(Tommorrow12);
  let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

  let Yesterdat1 = new Date();
  let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
  let YesterdayM = moment(Yesterday12);
  let yesterday = YesterdayM.format(`YYYY-MM-DD`);

  let day71 = new Date();
  let day72 = day71.setDate(day71.getDate() - 7);
  let day73 = moment(day72);
  let day7 = day73.format(`YYYY-MM-DD`);

  let day301 = new Date();
  let day302 = day301.setDate(day301.getDate() - 30);
  let day303 = moment(day302);
  let day30 = day303.format(`YYYY-MM-DD`);

  let month21 = new Date();
  let month22 = month21.setDate(month21.getDate() - 60);
  let month23 = moment(month22);
  let month2 = month23.format(`YYYY-MM-DD`);

  let month31 = new Date();
  let month32 = month21.setDate(month31.getDate() - 90);
  let month33 = moment(month32);
  let month3 = month33.format(`YYYY-MM-DD`);





  const [data, setData] = useState([]);
  const [betHistoryData, setBetHistoryData] = useState([]);
  const [sportType, setSportType] = useState("all");
  const [betStatus, setBetStatus] = useState("matched");
  const [dataSearchDate, setDataSearchDate] = useState("today");

  const [periodDate, setPeriodDate] = useState(``);
  const [periodTime, setPeriodTime] = useState(``);

  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [fromDate, setFromDate] = useState(today);
  const [fromTime, setFromTime] = useState(`00:00:00`);
  const [toDate, setToDate] = useState(Tommorrow);
  const [toTime, setToTime] = useState(`23:59:59`);
  const [loadPage, setLoadPage] = useState(false);





  // console.log(finalDateFormat);

  let { state } = useLocation();
  console.log('BettingHistory State : ', state)
  const LoggedInRoleId = localStorage.getItem(`RoleId`);


  const { data: bethistorydata, isLoading, isError } = useQuery({
    queryKey: ['BetHistoryData', page, sportType, loadPage, betStatus], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/admin/betHistory/${state?.id}/${sportType}/${betStatus}?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`);
      let resData = await res.data;
      console.log("res data : ", resData)
      return resData;
    }
  });

  // const betHistoryDataOfSoccer = bethistorydata?.filter((data)=> data.SportId === 1 );
  // const betHistoryDataOfTennis = bethistorydata?.filter((data)=> data.SportId === 2 );
  // const betHistoryDataOfCricket = bethistorydata?.filter((data)=> data.SportId === 4 );



  const context = useContext(currentUser);
  const { currentDashboard } = context;

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  };


  const setDateFunc = async (btnName) => {
    if (btnName === 'today') {
      setFromDate(today);
      setFromTime(`00:00:00`);
      setToDate(Tommorrow);
      setToTime(`12:59:59`);
      // await queryClient.refetchQueries({ type: 'betting-data' });
      // refetch();
      setPage(1);
      setLoadPage((prev) => !prev);

    } else if (btnName === "yesterday") {
      setFromDate(yesterday);
      setFromTime(`00:00:00`);
      setToTime(`12:59:59`);
      setToDate(today);
      setPage(1);
      setLoadPage((prev) => !prev);
    } else if (btnName === "7day") {
      setFromDate(day7);
      setFromTime(`00:00:00`);
      setToTime(`12:59:59`);
      setToDate(today);
      setPage(1);
      setLoadPage((prev) => !prev);
    } else if (btnName === "30day") {
      setFromDate(day30);
      setFromTime(`00:00:00`);
      setToTime(`12:59:59`);
      setToDate(today);
      setPage(1);
      setLoadPage((prev) => !prev);
    } else if (btnName === "2month") {
      setFromDate(month2);
      setFromTime(`00:00:00`);
      setToTime(`12:59:59`);
      setToDate(today);
      setPage(1);
      setLoadPage((prev) => !prev);
    } else if (btnName === "3month") {
      setFromDate(month3);
      setFromTime(`00:00:00`);
      setToTime(`12:59:59`);
      setToDate(today);
      setPage(1);
      setLoadPage((prev) => !prev);
    }
  }

  const onChangeHandler = (e, dateTimeType) => {
    if (dateTimeType === "fromDate") {
      setFromDate(e.target.value);
    }
    else if (dateTimeType === "fromTime") {
      setFromTime(e.target.value);
    }
    else if (dateTimeType === "toDate") {
      setToDate(e.target.value);
    }
    else if (dateTimeType === "toTime") {
      setToTime(e.target.value);
    }
  }


  async function getHistory() {
    setLoadPage((prev) => !prev);
    setPage(1);
  }






  useEffect(() => {

    (async () => {
      let res = await fetch(`https://api.betcare.live/api/getUserDetail/users/${state?.id}`);
      let resData = await res.json();
      setData(resData);
    })()
  }, []);


  return (
    <>
      <main role="main" className="pb-3">
        <div className="account-pages">
          <div className="container mxw-100">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <ul id="agentPath" className="agent_path-L mb-0">
                    <li id="path6">
                      <a href="javascript: void(0);">
                        <span className="lv_0"> {UserRoleShortName[data.length === 0 ? ".." : data[0].RoleId]} </span>
                        <strong> {data.length === 0 ? ".." : data[0].UserName} </strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 pe-0">
                <div className="match-list-inplay">
                  <div className="vertical-match-list">
                    <ul className="list-unstyled">
                      <li className="menu-title">Position</li>
                      <li id="linkActive" className="d-none">
                        <a
                          className="link"
                          href="/User/AccountStatement?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Account Statement
                        </a>
                      </li>
                      <li id="linkActive" className="">
                        <Link
                          className="link"
                          to="/accountsummary"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Account Summary
                        </Link>
                      </li>
                      <li className="menu-title">
                        <a>Performance</a>
                      </li>
                      <li id="linkActiveBetting" className="active">
                        <Link
                          className="link"
                          to="/bettinghistory"
                          state={currentDashboard !== null && { id: state?.id, roleId: state?.roleId, selfUser: state?.selfUser }}
                        >
                          Betting History
                        </Link>
                      </li>
                      <li id="linkActiveLoss" className="">
                        <Link
                          className="link"
                          to="/bettingprofitloss"
                          state={currentDashboard !== null && { id: state?.id, roleId: state?.roleId, selfUser: state?.selfUser }}
                        >
                          Betting Profit &amp; Loss
                        </Link>
                      </li>
                      <li id="linkActiveHistory" className="">
                        <Link
                          className="link"
                          to="/transactionhistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          {" "}
                          Transaction History
                        </Link>
                      </li>
                      <li id="Profile" className="d-none">
                        <a
                          className="link"
                          href="/user/Profile?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Profile
                        </a>
                      </li>
                      <li id="linkActiveLog" className="">
                        <Link
                          className="link"
                          to="/activitylog"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Activity Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="myaccount-page">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="pagettl"> Betting History </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="inner-pages">
                        <div className="container-fluid p-0">
                          <div className="ng-untouched ng-pristine ng-valid">
                            <div className="page-title"> Bet List </div>
                            <ul className="radio-group-list">
                              <li>
                                <input
                                  type="radio"
                                  id="All"
                                  name="sports"
                                  defaultValue={0}
                                  checked={sportType === "all" && true}
                                  onClick={() => { setSportType("all"); setPage(1); }}
                                  formcontrolname="sports"
                                  className="ng-untouched ng-pristine ng-valid"
                                />
                                All
                              </li>
                              <li className="ng-star-inserted">
                                <input
                                  type="radio"
                                  name="sports"
                                  formcontrolname="sports"
                                  checked={sportType === "1" && true}
                                  onClick={() => { setSportType("1"); setPage(1); }}
                                  className="ng-untouched ng-pristine ng-valid"
                                  defaultValue={1}
                                />
                                Soccer
                              </li>
                              <li className="ng-star-inserted">
                                <input
                                  type="radio"
                                  name="sports"
                                  checked={sportType === "2" && true}
                                  onClick={() => { setSportType("2"); setPage(1); }}
                                  formcontrolname="sports"
                                  className="ng-untouched ng-pristine ng-valid"
                                  defaultValue={2}
                                />
                                Tennis
                              </li>
                              <li className="ng-star-inserted">
                                <input
                                  type="radio"
                                  name="sports"
                                  formcontrolname="sports"
                                  checked={sportType === "4" && true}
                                  onClick={() => { setSportType("4"); setPage(1); }}
                                  className="ng-untouched ng-pristine ng-valid"
                                  defaultValue={4}
                                  defaultChecked=""
                                />
                                Cricket
                              </li>
                            </ul>
                            <div className="my-report">
                              <div className="time-zone-section">
                                <ul className="list-unstyled">
                                  <li>
                                    <label className="my-1 mr-2 fw-normal">
                                      Bet Status
                                    </label>
                                    <select
                                      formcontrolname="betStatus"
                                      id="betStatus"
                                      value={betStatus}
                                      onChange={(e) => { setBetStatus(e.target.value) }}
                                      className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                    >
                                      <option value={"matched"} >Matched</option>
                                      <option value={"settlement"} >Settled</option>
                                      <option value={"voided"} style={{ display: LoggedInRoleId > 2 ? 'none' : 'block' }}>Voided</option>
                                    </select>
                                  </li>
                                  <li>
                                    <label className="my-1 mr-2 fw-normal">
                                      Period
                                    </label>
                                    <input
                                      type="date"
                                      formcontrolname="periodFormDate"
                                      value={fromDate}
                                      onChange={(e) => { onChangeHandler(e, "fromDate") }}
                                      id="sdate"
                                      className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                      style={{ width: '100%' }}
                                    />
                                    {/* <input
                                      type="time"
                                      placeholder="9:00:00"
                                      formcontrolname="periodFormTime"
                                      value={fromTime}
                                      onChange={(e) => { onChangeHandler(e, "fromTime") }}
                                      id="stime"
                                      ng-model="datetime.time"
                                      className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                    /> */}
                                    <span className="d-inline-flex mx-2">To</span>
                                    <input
                                      type="date"
                                      formcontrolname="periodtoDate"
                                      value={toDate}
                                      onChange={(e) => { onChangeHandler(e, "toDate") }}
                                      id="edate"
                                      className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                      style={{ width: '100%' }}
                                    />
                                    {/* <input
                                      type="time"
                                      placeholder="8:59:59"
                                      value={toTime}
                                      onChange={(e) => { onChangeHandler(e, "toTime") }}
                                      id="etime"
                                      formcontrolname="periodtotime"
                                      className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                    /> */}
                                  </li>
                                </ul>
                                <ul className="list-unstyled report-btn-group">
                                  <li>
                                    <button
                                      type="button"
                                      className={`${fromDate === today ? toDate === Tommorrow ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                      onClick={() => { setDateFunc("today") }}

                                    >
                                      Just For Today
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`${fromDate === yesterday ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                      onClick={() => { setDateFunc("yesterday") }}
                                    >
                                      From Yesterday
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`${fromDate === day7 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                      onClick={() => { setDateFunc("7day") }}
                                    >
                                      Last 7 Day History
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`${fromDate === day30 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                      onClick={() => { setDateFunc("30day") }}
                                    >
                                      Last 30 Day History
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`${fromDate === month2 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                      onClick={() => { setDateFunc("2month") }}
                                    >
                                      Last 2 Month History
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`${fromDate === month3 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                      onClick={() => { setDateFunc("3month") }}
                                    >
                                      Last 3 Month History
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="submit"
                                      onClick={getHistory}
                                      className="btnblk"
                                    >
                                      Get History
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="profile-section mt-3 unmatech-section">
                            <div id="betList">
                              <table className="table ng-star-inserted">
                                <thead>
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>User Name</th>
                                    <th>Bet Taken</th>
                                    <th>Selection Name</th>
                                    <th>Match Name</th>
                                    <th>Market Name</th>
                                    <th>Market Id</th>
                                    <th>Type</th>
                                    <th>Odds</th>
                                    <th>Stack</th>
                                    <th>P/L</th>
                                    <th>Bet Id</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoading && <tr><td>Data is Loading...</td></tr>}
                                  {bethistorydata?.rows?.map((bet, index) => {
                                    const SettleDateTime = moment(bet.PlaceTime);
                                    const SettleDateF = SettleDateTime.format('DD/MM/YYYY');
                                    const SettleTimeF = SettleDateTime.format('HH:MM:SS');
                                    return <tr key={index} className={`${bet.Type} ng-star-inserted`}>
                                      <td>{(((page - 1) * pageSize) + (index + 1))}</td>
                                      {/* <td>{}</td> */}
                                      <td>{bet.username}</td>
                                      <td>{SettleDateF + " " + SettleTimeF}</td>
                                      <td>{bet.Selection}</td>
                                      <td>{bet.EventName}</td>
                                      <td>{bet.Market}</td>
                                      <td>{bet.MarketId}</td>
                                      <td>{bet.Type}</td>
                                      <td>{bet.OddsRequest}</td>
                                      <td>{bet.AmountStake}</td>
                                      <td>{bet.IsSettlement === 1 ? bet.ResultAmount : ""} </td>
                                      <td>{bet.BetId}</td>
                                    </tr>
                                  })
                                  }
                                  <tr colSpan={11} className="ng-star-inserted">
                                    <td
                                      colSpan={11}
                                      className="text-center"
                                      id="pagination"
                                    >
                                      <pagination-controls className="text-center">
                                        <pagination-template>
                                          <nav
                                            role="navigation"
                                            aria-label="Pagination"
                                          >
                                            <ul className="ngx-pagination ng-star-inserted">
                                              <li className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}>
                                                <button
                                                  className="ng-star-inserted"
                                                  style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                                  onClick={() => { setPage((prev) => prev - 1) }}
                                                  disabled={page === 1 ? true : false}
                                                >
                                                  Previous{" "}
                                                </button>
                                              </li>
                                              <li className={`pagination-next  ${page === Math.ceil(bethistorydata?.totalrows / pageSize) ? "disabled" : bethistorydata?.totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}>
                                                <button
                                                  // aria-disabled="true"
                                                  className="ng-star-inserted"
                                                  style={page === Math.ceil(bethistorydata?.totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : bethistorydata?.totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                                  onClick={() => setPage((prev) => prev + 1)}
                                                  disabled={page === Math.ceil(bethistorydata?.totalrows / pageSize) ? true : bethistorydata?.totalrows <= 20 ? true : false}
                                                >Next
                                                </button>
                                              </li>
                                            </ul>
                                          </nav>
                                        </pagination-template>
                                      </pagination-controls>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="editpop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="editpopLabel"
          aria-hidden="true"
          className="modal fade changepassword-model"
        >
          <div className="modal-dialog">
            <div className="ng-untouched ng-pristine ng-invalid">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 id="editpopLabel" className="modal-title">
                    Change Password
                  </h6>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="btn-close"
                  >
                    X
                  </button>
                </div>
                <div className="modal-body">
                  <dl className="form_list">
                    <dt>New Password </dt>
                    <dd>
                      <input
                        id="newPassword"
                        type="password"
                        placeholder="Enter"
                        aria-autocomplete="list"
                        formcontrolname="new_password"
                        className="ng-untouched ng-pristine ng-invalid"
                      />
                      <span className="must">＊</span>
                    </dd>
                    <div
                      id="isnewPassword"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Please enter your password.
                    </div>
                    <dt>New Password Confirm </dt>
                    <dd>
                      <input
                        id="newPasswordConfirm"
                        type="password"
                        placeholder="Enter"
                        formcontrolname="confirm_password"
                        className="ng-untouched ng-pristine ng-invalid"
                      />
                      <span className="must">＊</span>
                    </dd>
                    <div
                      id="isnewPasswordConfirm"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Please enter yourconfirm password.
                    </div>
                    <div
                      id="isPassword"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Your Password Not Matched.
                    </div>
                    <dt>Your Password </dt>
                    <dd>
                      <input
                        id="ParentPassword"
                        type="password"
                        placeholder="Enter"
                        formcontrolname="master_password"
                        className="ng-untouched ng-pristine ng-invalid"
                      />
                      <span className="must">＊</span>
                    </dd>
                    <div
                      id="isChange"
                      className="d-none"
                      style={{ color: "#ff6a00" }}
                    >
                      Please enter your password.
                    </div>
                    <dt />
                    <dd>
                      <button
                        id="changePasswordBtn"
                        type="submit"
                        onclick="changePassword()"
                        className="btn btn2"
                      >
                        Change
                      </button>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
    </>

  )
}

export default BettingHistory
