import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment';

function Referral() {


    const navigate = useNavigate();
    const context = useContext(currentUser);
    const { isLoggedIn } = context;
    const userIdLS = localStorage.getItem(`UserId`);



    const [allData, setAllData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [totalrows, setTotalrows] = useState(0);
    const [changeActionTo, setChangeActionTo] = useState(3);
    const [selectedUser, setSelectedUser] = useState({ name: "", userstatus: 0, id: 0 });
    const [utrNo, setUtrNo] = useState('');
    const [comment, setComment] = useState('');
    const [fetchedPaymentDetail, setFetchedPaymentDetail] = useState([]);
    const [userName, setUserName] = useState('')
    const [newReferralCode, setNewReferralCode] = useState('');
    const [errMsg, setErrMsg] = useState('');


    const fetchReferral = async () => {

        try {
            setIsLoading(true);
            var res = await fetch(`https://api.betcare.live/admin/api/selfUser/fetchReferral`);
            let resData = await res.json();

            console.log("fetching referral data : ", resData)

            setAllData(resData.data)
            setTotalrows(resData.data.length);

        } catch (error) {
            console.error("Error in fetching referral : ", error);
        } finally {
            setIsLoading(false)
        }
    }

    const updatingStatusOfReferral = async () => {

        console.log("Selected User : ", selectedUser)


        let res = await fetch(`https://api.betcare.live/admin/api/selfUser/updateReferral`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name: selectedUser.name, status: changeActionTo == 3 ? selectedUser.userstatus : changeActionTo, srno: selectedUser.srno })
        });

        let resData = await res.json();

        if (res.status === 201) {
            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
            fetchReferral();
            setChangeActionTo(3);

        } else {
            toast.error(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
            setChangeActionTo(3);
        }
    }

    const generateNewReferral = async () => {

        try {
            setIsLoading(true);
            var res = await fetch(`https://api.betcare.live/admin/api/selfUser/generateReferralCode`);
            let resData = await res.json();

            console.log("New Referral Code : ", resData);
            if (res.status == 200) {
                setNewReferralCode(resData?.referralCode);
            }


        } catch (error) {
            console.error("Error in New Referral Code  : ", error);
        } finally {
            setIsLoading(false)
        }
    }

    const AddNewReferral = async (e) => {

        e.preventDefault();
        console.log();

        if (!userName || !newReferralCode) {
            setErrMsg('Fill all input fields before submit!')
            return;
        }

        setErrMsg('');


        const res = await fetch(`https://api.betcare.live/admin/api/selfUser/addNewReferral`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userName, newReferralCode })
        });

        console.log('add new referral res : ', res);
        const resData = await res.json();
        console.log('new referral resData : ', resData);
        if (res.status == 201) {
            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
            fetchReferral();
            setChangeActionTo(3);
            document.getElementById('AddReferralModal').style.display = 'none';
            document.body.classList.remove('modal-open');
            document.querySelector('.modal-backdrop').remove();
            setUserName('');
            setNewReferralCode('');

        } else {
            toast.error(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });
        }

    }

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
            return;
        }

        if (isLoggedIn === "false") {
            navigate("/login");
            return;
        }

        fetchReferral();

    }, [isLoggedIn, page])


    return (
        <>
            <style>{`
div::-webkit-scrollbar {
display: none;
}
`}</style>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <main role="main" className="pb-3">
                <div className="inner-pages">
                    <div className="ng-untouched ng-pristine ng-valid">
                        <div className="container-fluid">
                            <div className="right-add-admin" id="showBtn" style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
                                <div className="page-title" style={{ fontSize: 16 }}>
                                    <h6 className="my-2">Referral </h6>
                                </div>
                                <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#AddReferralModal"
                                    className="light-btn ng-star-inserted mt-2"
                                    onClick={generateNewReferral}
                                >
                                    <i className="fa fa-bank" aria-hidden="true" style={{ fontSize: '20px', marginRight: '2px' }} />
                                    <span id="crtBtn">Add Referral</span>
                                </button>
                            </div>
                            <div className="download-list">
                                <div className="">
                                    <div className="profile-section mt-3">
                                        <div className="table-responsive  text-end">
                                            <table className="table table01">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Sr No</th>
                                                        <th>User Name</th>
                                                        <th>Referral Code</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData?.length > 0 && allData?.map((item, index) => (

                                                        <tr className="ng-star-inserted" key={item.id}>
                                                            <td className=" ng-star-inserted">{index + 1}</td>
                                                            <td>{item.userName}</td>
                                                            <td>{item.ReferralCode}</td>
                                                            <td>
                                                                <span className={item.Status == 1 ? "activebtn" : 'suspendbtn'} style={{ marginLeft: "10px" }}>{item.Status == 1 ? 'Active' : 'Inactive'}</span>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#changeWithdrawActionModal"
                                                                    onClick={() => { setSelectedUser({ name: item.userName, userstatus: item.Status, srno: item.SrNo }) }}>Action</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Add Bank Account Modal */}
                            <div
                                id="AddReferralModal"
                                className="modal fade changepassword-model"
                                style={{ display: "none" }}
                                data-backdrop="static"
                                aria-modal="true"
                                role="dialog"
                            >
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content w-100">
                                        <div className="modal-header">
                                            <h6 id="logmodelLabel" className="modal-title">
                                                Add Referral
                                            </h6>
                                            <button type="button"
                                                className="close p-1"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid',
                                                    fontWeight: 'bolder'
                                                }}
                                                onClick={() => {
                                                    setNewReferralCode('');
                                                }}
                                                data-dismiss="modal">
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status" id="statementList">
                                            <div className="profile-section mt-0 unmatech-section p-3">
                                                <div className='d-flex flex-column'>
                                                    <div className='mb-1'>
                                                        <label htmlFor="displayname" className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold', }}>User Name</label>
                                                        <input
                                                            type="text"
                                                            id='displayname'
                                                            className='w-100'
                                                            style={{ outline: 'none', fontSize: '1.2em', border: '1px solid', height: '2.5em' }}
                                                            value={userName}
                                                            onChange={(e) => {
                                                                setUserName(e.target.value);
                                                                setErrMsg('');
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mb-3'>
                                                        <label className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Referral Code</label>
                                                        <input
                                                            type="text"
                                                            className='w-100'
                                                            style={{ outline: 'none', fontSize: '1.2em', border: '1px solid', height: '2.5em' }}
                                                            // value={accountNumber}
                                                            pattern="[0-9]*"
                                                            value={newReferralCode}
                                                            readOnly
                                                        // onInput={(e) => {
                                                        //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        // }}
                                                        // onChange={(e) => {
                                                        //     const value = e.target.value;
                                                        //     setAccountNumber(value);
                                                        //     setErrMsg('');
                                                        // }}
                                                        />
                                                    </div>
                                                    {/* <div className='d-flex flex-column  mb-3'>
                                                        <label htmlFor="displayname" className='p-1' style={{ fontSize: '1.2em', fontWeight: 'bold', }}>Payment Type</label>
                                                        <select
                                                            name=""
                                                            id=""
                                                            style={{ fontSize: '1.2em', height: '2.5em', border: '1px solid', borderRadius: '5px' }}
                                                        // value={paymentType}
                                                        // onChange={(e) => setPaymentType(e.target.value)}
                                                        >
                                                            <option value="Bkash">Bkash</option>
                                                            <option value="Nagad">Nagad</option>
                                                            <option value="Rocket">Rocket</option>
                                                        </select>
                                                    </div> */}


                                                    <div className="col-12 mb-3 mt-3 d-flex flex-column justify-content-center align-items-center ">
                                                        <span style={{ color: 'red', fontSize: '1em', fontWeight: 'bold' }}>{errMsg}</span>
                                                        <button
                                                            className=" w-100"
                                                            type="button"
                                                            style={{
                                                                fontSize: '1.8em',
                                                                fontWeight: 'bold',
                                                                padding: '8px 10px 8px 10px',
                                                                borderRadius: '5px'
                                                            }}
                                                            onClick={(e) => AddNewReferral(e)}
                                                        >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Change status pop start */}
                            <div
                                id="changeWithdrawActionModal"
                                tabindex="-1"
                                aria-labelledby="ChangeStatusLabel"
                                className="modal fade changepassword-model show"
                                data-backdrop="static"
                                data-keyboard="false"
                                role="dialog"
                                style={{ display: "none" }}
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h6 id="ChangeStatusLabel" className="modal-title">
                                                Change Status
                                            </h6>
                                            {/* <button
              type="button" data-bs-dismiss="modal" aria-label="Close"
              class="btn-close"><i class="bi bi-x">X</i></button> */}
                                            <button
                                                type="button"
                                                className="close p-1"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '1px solid',
                                                    fontWeight: 'bolder'
                                                }}
                                                data-dismiss="modal"
                                                onClick={() => {
                                                    // setUserTypesPassword("");
                                                    setChangeActionTo(3)
                                                    setComment('');
                                                }}
                                            >
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body change-status">
                                            <div className="change-st-box">
                                                <div className="chnage-st-name d-flex justify-content-between align-items-center">
                                                    <p className="mb-0">
                                                        {/* selectedUser.RoleId] */}
                                                        <span className="account-name-specail-txt">PL</span>
                                                        <b>{selectedUser.name}</b>
                                                        {/* selectedUser.name */}
                                                    </p>
                                                    <a className={selectedUser.userstatus == 1 ? "activebtn" : "suspendbtn"}>
                                                        {selectedUser.userstatus == 1 && "Active"}
                                                        {selectedUser.userstatus == 0 && "Inactive"}
                                                    </a>
                                                </div>

                                                <div className="status-button-group status_but">
                                                    <input type="hidden" name="UserId" id="statusUserId" />
                                                    <input type="hidden" name="Status" id="statusStatus" />

                                                    <button
                                                        className={`status-button but_active ${changeActionTo == 1 && "open"}`}
                                                        id="activeBtn"
                                                        disabled={selectedUser.userstatus == 1 && true}
                                                        // href="#active"
                                                        style={selectedUser.userstatus == 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        data-status="active"

                                                        onClick={() => { setChangeActionTo(1) }}
                                                    // style={{background:"green"}}
                                                    >
                                                        <div className="status-active " style={selectedUser.userstatus == 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }} >
                                                            <div className="sts-icon">
                                                                <span className="icon">
                                                                    <i
                                                                        className="fa fa-check-circle-o"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </div>{" "}
                                                            Active
                                                        </div>
                                                    </button>

                                                    <button
                                                        className={`status-button but_suspend ${changeActionTo == 0 && "open"}`}
                                                        style={selectedUser.userstatus == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                                        disabled={selectedUser.userstatus == 0 && true}
                                                        id="suspendBtn"
                                                        // href="#active"

                                                        onClick={() => { setChangeActionTo(0) }}
                                                        data-status="suspend"
                                                    >
                                                        <div className="status-disbled" style={selectedUser.userstatus == 0 ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                                                            <div className="sts-icon">
                                                                <span className="icon">
                                                                    <i className="fa fa-ban" aria-hidden="true" />
                                                                </span>
                                                            </div>{" "}
                                                            Inactive
                                                        </div>
                                                    </button>

                                                </div>
                                            </div>
                                            <dl className="form_list d-flex flex-column mt-3">
                                                <dd className="w-100 text-center">
                                                    <a
                                                        id="changePasswordBtn1"
                                                        title="Change"
                                                        onClick={updatingStatusOfReferral}
                                                        className="btn btn2 px-4"
                                                        data-dismiss="modal"
                                                    >
                                                        Change
                                                    </a>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Referral