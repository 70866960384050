import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

function SelfUserBetHistory() {

    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterdat1 = new Date();
    let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let yesterday = YesterdayM.format(`YYYY-MM-DD`);

    let day71 = new Date();
    let day72 = day71.setDate(day71.getDate() - 7);
    let day73 = moment(day72);
    let day7 = day73.format(`YYYY-MM-DD`);

    let day301 = new Date();
    let day302 = day301.setDate(day301.getDate() - 30);
    let day303 = moment(day302);
    let day30 = day303.format(`YYYY-MM-DD`);

    let month21 = new Date();
    let month22 = month21.setDate(month21.getDate() - 60);
    let month23 = moment(month22);
    let month2 = month23.format(`YYYY-MM-DD`);

    let month31 = new Date();
    let month32 = month21.setDate(month31.getDate() - 90);
    let month33 = moment(month32);
    let month3 = month33.format(`YYYY-MM-DD`);





    const [data, setData] = useState([]);
    const [betHistoryData, setBetHistoryData] = useState([]);
    const [sportType, setSportType] = useState("all");
    const [betStatus, setBetStatus] = useState("matched");


    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [fromDate, setFromDate] = useState(today);
    const [fromTime, setFromTime] = useState(`00:00:00`);
    const [toDate, setToDate] = useState(Tommorrow);
    const [toTime, setToTime] = useState(`23:59:59`);
    const [loadPage, setLoadPage] = useState(false);
    const [updatePage, setUpdatePage] = useState(false);





    // console.log(finalDateFormat);

    let { state } = useLocation();
    console.log('BettingHistory State : ', state)
    const LoggedInRoleId = localStorage.getItem(`RoleId`);


    const { data: bethistorydata, isLoading, isError } = useQuery({
        queryKey: ['BetHistoryData', page, sportType, loadPage, betStatus, updatePage], queryFn: async () => {
            let res = await axios.get(`https://api.betcare.live/api/admin/selfUser/betHistoryselfUser/1/${sportType}/${betStatus}?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`);
            let resData = await res.data;
            console.log("res data : ", resData)
            return resData;
        }
    });

    const setDateFunc = async (btnName) => {
        if (btnName === 'today') {
            setFromDate(today);
            setFromTime(`00:00:00`);
            setToDate(Tommorrow);
            setToTime(`12:59:59`);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setLoadPage((prev) => !prev);

        } else if (btnName === "yesterday") {
            setFromDate(yesterday);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);
        } else if (btnName === "7day") {
            setFromDate(day7);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);
        } else if (btnName === "30day") {
            setFromDate(day30);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);
        } else if (btnName === "2month") {
            setFromDate(month2);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);
        } else if (btnName === "3month") {
            setFromDate(month3);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            setPage(1);
            setLoadPage((prev) => !prev);
        }
    }

    const onChangeHandler = (e, dateTimeType) => {
        if (dateTimeType === "fromDate") {
            setFromDate(e.target.value);
        }
        else if (dateTimeType === "fromTime") {
            setFromTime(e.target.value);
        }
        else if (dateTimeType === "toDate") {
            setToDate(e.target.value);
        }
        else if (dateTimeType === "toTime") {
            setToTime(e.target.value);
        }
    }


    async function getHistory() {
        setLoadPage((prev) => !prev);
        setPage(1);
    }

    const funcToUpdateBetStatusToVoid = (betId) => {

        Swal.fire({
            title: "Conformation",
            text: "Are you Sure ?",
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (!result.value) return;

            const res = await fetch(`https://api.betcare.live/api/updateBetStatusToVoid/bets/${betId}`, {
                method: 'PUT'
            });

            const resData = await res.json();

            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });

            setUpdatePage((prev) => !prev)


        })
    }




    const funcToUpdateBetStatusToDelete = (betIdForDelete) => {
        Swal.fire({
            title: "Conformation",
            text: "Are you Sure ?",
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (!result.value) return;

            const res = await fetch(`https://api.betcare.live/api/updateBetStatusToDelete/bets/${betIdForDelete}`, {
                method: 'PUT'
            });

            const resData = await res.json();

            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });

            setUpdatePage((prev) => !prev)


        })
    }




    const funcToUpdateBetStatusToRollBack = (betIdForDelete) => {
        Swal.fire({
            title: "Conformation",
            text: "Are you Sure ?",
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then(async (result) => {
            if (!result.value) return;

            const res = await fetch(`https://api.betcare.live/api/updateBetStatusToRollback/bets/${betIdForDelete}`, {
                method: 'PUT'
            });

            const resData = await res.json();

            toast.success(`${resData.message}`, {
                autoClose: 1500,
                theme: "colored",
            });

            setUpdatePage((prev) => !prev)


        })
    }




    return (
        <main role="main" className="pb-3">
            {/* <div className="download-list">
          <div className="container mxw-100">
            <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div>
          </div>
        </div> */}
            <div className="col-lg-12">
                <div className="inner-pages">
                    <div className="container-fluid">
                        <div className="ng-untouched ng-pristine ng-valid">
                            <div className="page-title" style={{ fontSize: 16 }}>
                                <h6 className="my-2">Bet History </h6>
                            </div>
                            <ul className="radio-group-list">
                                <li>
                                    <input
                                        type="radio"
                                        id="All"
                                        name="sports"
                                        defaultValue={0}
                                        checked={sportType === "all" && true}
                                        onClick={() => { setSportType("all"); setPage(1); }}
                                        formcontrolname="sports"
                                        className="ng-untouched ng-pristine ng-valid"
                                    />
                                    All
                                </li>
                                <li className="ng-star-inserted">
                                    <input
                                        type="radio"
                                        name="sports"
                                        formcontrolname="sports"
                                        checked={sportType === "1" && true}
                                        onClick={() => { setSportType("1"); setPage(1); }}
                                        className="ng-untouched ng-pristine ng-valid"
                                        defaultValue={1}
                                    />
                                    Soccer
                                </li>
                                <li className="ng-star-inserted">
                                    <input
                                        type="radio"
                                        name="sports"
                                        checked={sportType === "2" && true}
                                        onClick={() => { setSportType("2"); setPage(1); }}
                                        formcontrolname="sports"
                                        className="ng-untouched ng-pristine ng-valid"
                                        defaultValue={2}
                                    />
                                    Tennis
                                </li>
                                <li className="ng-star-inserted">
                                    <input
                                        type="radio"
                                        name="sports"
                                        formcontrolname="sports"
                                        checked={sportType === "4" && true}
                                        onClick={() => { setSportType("4"); setPage(1); }}
                                        className="ng-untouched ng-pristine ng-valid"
                                        defaultValue={4}
                                        defaultChecked=""
                                    />
                                    Cricket
                                </li>
                            </ul>
                            <div className="my-report">
                                <div className="time-zone-section">
                                    <ul className="list-unstyled">
                                        <li>
                                            <label className="my-1 mr-2 fw-normal">
                                                Bet Status
                                            </label>
                                            <select
                                                formcontrolname="betStatus"
                                                id="betStatus"
                                                value={betStatus}
                                                onChange={(e) => { setBetStatus(e.target.value) }}
                                                className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                                            >
                                                {/* <option value={"matched"} >Matched</option> */}
                                                <option value={"settlement"} >Settled</option>
                                                <option value={"matched"}>UnSettled</option>
                                                <option value={"voided"} style={{ display: LoggedInRoleId > 2 ? 'none' : 'block' }}>Voided</option>
                                            </select>
                                        </li>
                                        <li>
                                            <label className="my-1 mr-2 fw-normal">
                                                Period
                                            </label>
                                            <input
                                                type="date"
                                                formcontrolname="periodFormDate"
                                                value={fromDate}
                                                onChange={(e) => { onChangeHandler(e, "fromDate") }}
                                                id="sdate"
                                                className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                                style={{ width: '100%' }}
                                            />
                                            {/* <input
                          type="time"
                          placeholder="9:00:00"
                          formcontrolname="periodFormTime"
                          value={fromTime}
                          onChange={(e) => { onChangeHandler(e, "fromTime") }}
                          id="stime"
                          ng-model="datetime.time"
                          className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                        /> */}
                                            <span className="d-inline-flex mx-2">To</span>
                                            <input
                                                type="date"
                                                formcontrolname="periodtoDate"
                                                value={toDate}
                                                onChange={(e) => { onChangeHandler(e, "toDate") }}
                                                id="edate"
                                                className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                                style={{ width: '100%' }}
                                            />
                                            {/* <input
                          type="time"
                          placeholder="8:59:59"
                          value={toTime}
                          onChange={(e) => { onChangeHandler(e, "toTime") }}
                          id="etime"
                          formcontrolname="periodtotime"
                          className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                        /> */}
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled report-btn-group">
                                        <li>
                                            <button
                                                type="button"
                                                className={`${fromDate === today ? toDate === Tommorrow ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                                onClick={() => { setDateFunc("today") }}

                                            >
                                                Just For Today
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={`${fromDate === yesterday ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                                onClick={() => { setDateFunc("yesterday") }}
                                            >
                                                From Yesterday
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={`${fromDate === day7 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                                onClick={() => { setDateFunc("7day") }}
                                            >
                                                Last 7 Day
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={`${fromDate === day30 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                                onClick={() => { setDateFunc("30day") }}
                                            >
                                                Last 30 Day
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={`${fromDate === month2 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                                onClick={() => { setDateFunc("2month") }}
                                            >
                                                Last 2 Month
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                className={`${fromDate === month3 ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                                onClick={() => { setDateFunc("3month") }}
                                            >
                                                Last 3 Month
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="submit"
                                                onClick={getHistory}
                                                className="btnblk"
                                            >
                                                Get History
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="profile-section mt-3 unmatech-section">
                            <div id="betList">
                                <table className="table ng-star-inserted">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>User Name</th>
                                            <th>Bet Taken</th>
                                            <th>Selection Name</th>
                                            <th>Match Name</th>
                                            <th>Market Name</th>
                                            <th>Market Id</th>
                                            <th>Type</th>
                                            <th>Odds</th>
                                            <th>Stack</th>
                                            <th>P/L</th>
                                            <th>Bet Id</th>
                                            {LoggedInRoleId <= 2 && (<th>Action</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading && <tr><td>Data is Loading...</td></tr>}
                                        {bethistorydata?.rows?.map((bet, index) => {
                                            const SettleDateTime = moment(bet.PlaceTime);
                                            const SettleDateF = SettleDateTime.format('DD/MM/YYYY');
                                            const SettleTimeF = SettleDateTime.format('HH:MM:SS');
                                            return <tr key={index} className={`${bet.Type} ng-star-inserted`}>
                                                <td>{(((page - 1) * pageSize) + (index + 1))}</td>
                                                {/* <td>{}</td> */}
                                                <td>{bet.username}</td>
                                                <td>{SettleDateF + " " + SettleTimeF}</td>
                                                <td>{bet.Selection}</td>
                                                <td>{bet.EventName}</td>
                                                <td>{bet.Market}</td>
                                                <td>{bet.MarketId}</td>
                                                <td>{bet.Type}</td>
                                                <td>{bet.OddsRequest}</td>
                                                <td>{bet.AmountStake}</td>
                                                <td>{bet.IsSettlement === 1 ? bet.ResultAmount : ""} </td>
                                                <td>{bet.BetId}</td>
                                                {LoggedInRoleId <= 2 && (
                                                    betStatus != 'voided' ? (
                                                        <td >
                                                            <button onClick={() => { funcToUpdateBetStatusToVoid(bet.Id) }} style={{ padding: "5px 10px", background: "red", border: "2px solid red", color: "white" }}>Void</button>
                                                        </td>)
                                                        : (
                                                            <td style={{ display: "flex", gap: "5px" }}>
                                                                <button onClick={() => { funcToUpdateBetStatusToDelete(bet.Id) }} style={{ padding: "5px 10px", background: "red", border: "2px solid red", color: "white" }}>Delete</button>
                                                                <button onClick={() => { funcToUpdateBetStatusToRollBack(bet.Id) }} style={{ padding: "5px 10px", background: "green", border: "2px solid green", color: "white" }}>Rollback</button>
                                                            </td>
                                                        )

                                                )}
                                            </tr>
                                        })
                                        }
                                        <tr colSpan={11} className="ng-star-inserted">
                                            <td
                                                colSpan={11}
                                                className="text-center"
                                                id="pagination"
                                            >
                                                <pagination-controls className="text-center">
                                                    <pagination-template>
                                                        <nav
                                                            role="navigation"
                                                            aria-label="Pagination"
                                                        >
                                                            <ul className="ngx-pagination ng-star-inserted">
                                                                <li className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}>
                                                                    <button
                                                                        className="ng-star-inserted"
                                                                        style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                                                        onClick={() => { setPage((prev) => prev - 1) }}
                                                                        disabled={page === 1 && true}
                                                                    >
                                                                        Previous{" "}
                                                                    </button>
                                                                </li>
                                                                <li className={`pagination-next  ${page === Math.ceil(bethistorydata?.totalrows / pageSize) ? "disabled" : bethistorydata?.totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}>
                                                                    <button
                                                                        // aria-disabled="true"
                                                                        className="ng-star-inserted"
                                                                        style={page === Math.ceil(bethistorydata?.totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : bethistorydata?.totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                                                        onClick={() => setPage((prev) => prev + 1)}
                                                                        disabled={page === Math.ceil(bethistorydata?.totalrows / pageSize) ? true : bethistorydata?.totalrows <= 20 ? true : false}
                                                                    >Next
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </pagination-template>
                                                </pagination-controls>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SelfUserBetHistory