import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import currentUser from '../../context/currentUser/currentUser';
import { Link, useNavigate } from 'react-router-dom';
import { FiPlusSquare } from "react-icons/fi";
import { FaRegSquareMinus } from "react-icons/fa6";


function ProfitLossReportByDownline() {

    let userId = localStorage.getItem('UserId');
    const loggedInUserName = localStorage.getItem('UserName').replace(/^"(.*)"$/, '$1');


    let today1 = new Date();
    let todayM = moment(today1);
    let today = todayM.format(`YYYY-MM-DD`);

    let Tommorrow1 = new Date();
    let Tommorrow12 = Tommorrow1.setDate(Tommorrow1.getDate() + 1);
    let TommorrowM = moment(Tommorrow12);
    let Tommorrow = TommorrowM.format(`YYYY-MM-DD`);

    let Yesterdat1 = new Date();
    let Yesterday12 = Yesterdat1.setDate(Yesterdat1.getDate() - 1);
    let YesterdayM = moment(Yesterday12);
    let yesterday = YesterdayM.format(`YYYY-MM-DD`);


    const [ChildData, setChildData] = useState([]);
    const [allData, setAllData] = useState([])
    const [allDataLengthm, setAllDataLength] = useState(0)
    const [openMoreIndex, setOpenMoreIndex] = useState(null);
    const [subProfitLossData, setSubProfitLossData] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [fromDate, setFromDate] = useState(today);
    const [fromTime, setFromTime] = useState(`00:00:00`);
    const [toDate, setToDate] = useState(Tommorrow);
    const [toTime, setToTime] = useState(`23:59:59`);
    const [sportType, setSportType] = useState("all");
    const [loadPage, setLoadPage] = useState(false);
    const [data, setData] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [accData, setAccData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [allUsernameList, setAllUsernameList] = useState([]);
    const [userExist, setUserExist] = useState(null);
    const [userNotExist, setUserNotExist] = useState(null);
    const [selectedUser, setSelectedUser] = useState({ name: "", RoleId: 0, userstatus: 0, userparentStatus: 0, id: 0 });
    const [addUserState, setAddUserState] = useState("");
    const [updatePage, setUpdatePage] = useState(true);
    const [AddUsername, setAddUserName] = useState("");
    const [AddPassword, setAddPassword] = useState("");
    const [AddConfirmPassword, setAddConfirmPassword] = useState("");
    const [currentStatus, setCurrentStatus] = useState(selectedUser.userparentStatus);
    const [changeStatusTo, setChangeStatusTo] = useState(0);
    const [userTypedPassword, setUserTypesPassword] = useState("");
    const [accStatement, setAccStatement] = useState([])
    const [submit, setSubmit] = useState(false)
    const [totalStake, settotalStake] = useState(0);
    const [totalUserPLAmount, setTotalUserPLAmount] = useState(0);
    const [calcBalance, setcalcBalance] = useState(0);
    const [clickedUserBalance, setClickedUserBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [totalrows, setTotalrows] = useState(0)
    const [currentPageApi, setCurrentPageApi] = useState('1')



    const context = useContext(currentUser);
    const { isLoggedIn, roleId, fullName, name, setIsLoggedIn, userStatusId, setFullName, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages, Currency, mainBalance } = context;

    const userIdLS = localStorage.getItem(`UserId`);
    const navigate = useNavigate();
    //  //console.log(name);

    const UserRoleShortName = {
        1: "DM",
        2: "TA",
        3: "SA",
        4: "SB",
        5: "SS",
        6: "SU",
        7: "MA",
        8: "PL"
    }

    const UserRoleFullName = {
        1: "Domain",
        2: "TechAdmin",
        3: "SuperAdmin",
        4: "SubAdmin",
        5: "SuperSuper",
        6: "Super",
        7: "Master",
        8: "User"
    }


    // function to fetch the child data of user
    // const fetchChildData = async () => {
    //     //  //console.log(currentDashboard);
    //     try {
    //         setIsLoading(true)
    //         let res = await fetch(`https://api.betcare.live/getUsers/${currentDashboard.userId}`);
    //         let resData = await res.json();
    //         console.log("user data : ", resData)

    //         setChildData(resData.data);

    //         let fetchExpo = await fetch(`https://api.betcare.live/calcExposOfAllUserForPLD?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`)
    //         let fetchExpoData = await fetchExpo.json();

    //         if (fetchExpoData.isSuccess) {
    //             console.log("Calc expos call..! : ", fetchExpoData)
    //             const filterUser = fetchExpoData.data.filter(item => resData.data.some(user => item.Id == user.Id));
    //             console.log('filterUser data length : ', filterUser.length)
    //             console.log('filterUser data : ', filterUser)
    //             setAllData(filterUser)
    //             setTotalrows(filterUser.length)

    //         } else {
    //             console.log("Error in calcExposOfAllUser")
    //             // fetchExposure();
    //         }

    //     } catch (error) {
    //         console.error("Error fetching downline")
    //     } finally {
    //         setIsLoading(false)
    //     }

    // }

    const fetchChildData = async (childRoleId, userName, userId, isUpdate) => {
        try {
            setIsLoading(true);

            // Fetch ChildData from API
            var res;
            if (userId !== undefined) {
                res = await fetch(`https://api.betcare.live/getUsers/${userId}`);
            } else {
                res = await fetch(`https://api.betcare.live/getUsers/${userIdLS}`);
            }
            let resData = await res.json();
            console.log("user data : ", resData);

            if (resData.data.length > 0) {
                if (userId !== undefined) {
                    NavigatToChildDasboard(childRoleId, userName, userId, isUpdate)
                }
                // // Fetch 20 data at a time from allData based on pagination
                // const startIndex = (page - 1) * pageSize;
                // const endIndex = startIndex + pageSize;
                // const currentPageData = allData.slice(startIndex, endIndex);
                // setChildData(currentPageData);

                // Fetch expos of users for pagination
                let fetchExpo = await fetch(`https://api.betcare.live/newcalcExposOfAllUserForPLD/${userIdLS}?page=${page}&totalpage=${pageSize}&fromDateTime=${fromDate} ${fromTime}&toDateTime=${toDate} ${toTime}`);
                let fetchExpoData = await fetchExpo.json();

                if (fetchExpoData.isSuccess) {
                    console.log("Calc expos call..! : ", fetchExpoData);
                    const filterUser = fetchExpoData.data.filter(item => resData.data.some(user => item.Id == user.Id));
                    console.log('filterUser data length : ', filterUser.length);
                    console.log('filterUser data : ', filterUser);

                    const startIndex = (page - 1) * pageSize;
                    const endIndex = startIndex + pageSize;
                    const currentPageData = filterUser.slice(startIndex, endIndex);
                    setAllData(currentPageData);
                    setTotalrows(filterUser.length);
                } else {
                    console.log("Error in calcExposOfAllUser");
                }
            }

        } catch (error) {
            console.error("Error fetching downline");
        } finally {
            setIsLoading(false);
        }
    };



    const calcTotal = () => {
        let calctotalStake = 0;
        let totalUserPL = 0;
        let balance = 0;

        allData.forEach((item) => {
            // Assuming each item has a property named 'currentExposure'
            calctotalStake += item.UserStake != null ? Number(item.UserStake) : 0;
            totalUserPL += item.UserPL != null ? Number(item.UserPL) : 0;
        });

        settotalStake(calctotalStake)
        setTotalUserPLAmount(totalUserPL)
        setcalcBalance(balance)


    }

    const fetchMatched = async () => {
        try {
            const fetched = await fetch(`https://api.betcare.live/usersChild/${userId}`);
            const response = await fetched.json();
            // console.log("Get userChild matches data: " + JSON.stringify(response.Alldata));
            // setAllData(response.Alldata)
            // console.log("Get userChild Account Statement matches data: " + JSON.stringify(response.AccStatement));

            if (Array.isArray(response.AccStatement) && Array.isArray(response.Alldata)) {

                // console.log("Get userChild Account Statement matches data: " + JSON.stringify(response.AccStatement[0]));
                // console.log("Response data before filter unique : " + response.Alldata.length)



                // Unique data for Match Odds 


                // Use a Map to keep track of unique eventNames
                // Use a Map to keep track of unique eventIds
                const uniqueEventId = new Map();
                const eventIdToResultAmount = new Map(); // To store EventId to corresponding ResultAmount

                // Iterate through data and populate uniqueEventId and eventIdToResultAmount
                response.Alldata.forEach(item => {
                    if (item.Market === "Matchodds") {
                        const eventId = item.EventId;
                        if (!uniqueEventId.has(eventId)) {
                            uniqueEventId.set(eventId, true);
                            eventIdToResultAmount.set(eventId, item.ResultAmount);
                        } else {
                            // If eventId already exists, add up the ResultAmount
                            eventIdToResultAmount.set(eventId, eventIdToResultAmount.get(eventId) + item.ResultAmount);
                        }
                    }
                });

                // Set ResultAmount for each unique EventId to corresponding matches
                const filteredData = response.Alldata.reduce((filtered, item) => {
                    if (item.Market === "Matchodds" && uniqueEventId.get(item.EventId)) {
                        // Set the ResultAmount
                        item.ResultAmount = eventIdToResultAmount.get(item.EventId);
                        // Remove the EventId from uniqueEventId to prevent duplicates
                        uniqueEventId.delete(item.EventId);
                        filtered.push(item);
                    }
                    return filtered;
                }, []);

                // console.log("Filtered unique Data  : " + JSON.stringify(filteredData))
                console.log("Filtered unique Data length  : " + JSON.stringify(filteredData.length))



                // Unique Data for BookMaker


                // Use a Map to keep track of unique eventIds for BookMaker market
                const uniqueBookMakerEventId = new Map();
                const bookMakerEventIdToResultAmount = new Map(); // To store EventId to corresponding ResultAmount for BookMaker market

                // Iterate through data and populate uniqueBookMakerEventId and bookMakerEventIdToResultAmount
                response.Alldata.forEach(item => {
                    if (item.Market === "BookMaker") {
                        const eventId = item.EventId;
                        if (!uniqueBookMakerEventId.has(eventId)) {
                            uniqueBookMakerEventId.set(eventId, true);
                            bookMakerEventIdToResultAmount.set(eventId, item.ResultAmount);
                        } else {
                            // If eventId already exists, add up the ResultAmount
                            bookMakerEventIdToResultAmount.set(eventId, bookMakerEventIdToResultAmount.get(eventId) + item.ResultAmount);
                        }
                    }
                });

                // Set ResultAmount for each unique EventId to corresponding matches in BookMaker market
                const filteredBookMakerData = response.Alldata.reduce((filtered, item) => {
                    if (item.Market === "BookMaker" && uniqueBookMakerEventId.get(item.EventId)) {
                        // Set the ResultAmount
                        item.ResultAmount = bookMakerEventIdToResultAmount.get(item.EventId);
                        // Remove the EventId from uniqueBookMakerEventId to prevent duplicates
                        uniqueBookMakerEventId.delete(item.EventId);
                        filtered.push(item);
                    }
                    return filtered;
                }, []);

                // console.log("Filtered unique BookMaker Data: " + JSON.stringify(filteredBookMakerData))
                console.log("Filtered unique BookMaker Data length: " + JSON.stringify(filteredBookMakerData.length))


                // Unique Data for Fancy


                const beforFilterFancy = response.Alldata.filter(item => item.Market == "Fancy");

                console.log("before filter fancy length : " + beforFilterFancy.length)

                // Use a Map to keep track of unique eventIds for Fancy market
                const uniqueFancyEventId = new Map();
                const fancyEventIdToResultAmount = new Map(); // To store EventId to corresponding ResultAmount for Fancy market

                // Iterate through data and populate uniqueFancyEventId and fancyEventIdToResultAmount
                response.Alldata.forEach(item => {
                    if (item.Market === "Fancy") {
                        const eventId = item.EventId;
                        const selectionId = item.SelectionId; // Also check for SelectionId
                        const key = `${eventId}_${selectionId}`; // Create a unique key combining EventId and SelectionId

                        if (!uniqueFancyEventId.has(key)) {
                            uniqueFancyEventId.set(key, true);
                            fancyEventIdToResultAmount.set(key, item.ResultAmount);
                        } else {
                            // If key already exists, add up the ResultAmount
                            fancyEventIdToResultAmount.set(key, fancyEventIdToResultAmount.get(key) + item.ResultAmount);
                        }
                    }
                });

                // Set ResultAmount for each unique EventId and SelectionId pair to corresponding matches in Fancy market
                const filteredFancyData = response.Alldata.reduce((filtered, item) => {
                    if (item.Market === "Fancy") {
                        const eventId = item.EventId;
                        const selectionId = item.SelectionId;
                        const key = `${eventId}_${selectionId}`;

                        if (uniqueFancyEventId.get(key)) {
                            // Set the ResultAmount
                            item.ResultAmount = fancyEventIdToResultAmount.get(key);
                            // Remove the key from uniqueFancyEventId to prevent duplicates
                            uniqueFancyEventId.delete(key);
                            filtered.push(item);
                        }
                    }
                    return filtered;
                }, []);

                // console.log("Filtered unique Fancy Data: ", filteredFancyData);
                console.log("Filtered unique Fancy Data length: ", filteredFancyData.length);



                // Combine filteredData and filteredBookMakerData into a single array
                const combinedFilteredUniqueData = filteredData.concat(filteredBookMakerData, filteredFancyData);

                console.log("Combined filtered Data length: " + combinedFilteredUniqueData.length);
                console.log("Combined filtered Data: " + JSON.stringify(combinedFilteredUniqueData));


                // filterOut Unique User Ids With calculative ResultAmount

                const filterCombined = combinedFilteredUniqueData.filter(item => item.UserId == 26)

                console.log('Filter by id : ', filterCombined)

                // Group bets by UserId and calculate combined ResultAmount for all users
                const uniqueUserBets = combinedFilteredUniqueData.reduce((accumulator, currentValue) => {
                    const userId = currentValue.UserId;
                    if (!accumulator[userId]) {
                        accumulator[userId] = {
                            UserId: userId,
                            AmountStack: 0,
                            ResultAmountTotal: 0
                        };
                    }

                    accumulator[userId].AmountStack += currentValue.AmountStake;
                    accumulator[userId].ResultAmountTotal += currentValue.ResultAmount;

                    return accumulator;
                }, {});

                // Convert the object of unique users and their combined ResultAmount to an array
                const uniqueUserBetsArray = Object.values(uniqueUserBets);

                console.log('Combined ResultAmount for each UserId:', uniqueUserBetsArray);


            }

        } catch (err) {
            console.error("Error in fetching Userschild Api: " + err);
        }
    }



    const NavigatToChildDasboard = (childRoleId, userName, userId, isUpdate) => {
        if (isUpdate === false) {
            SetCurrentUserPages((prev) => [...prev, { fullName: userName, roleId: childRoleId, id: userId }]);
        }
        setCurrentDashboard({ roleId: childRoleId, userId: userId });
        // setClickedUserBalance(clickBalance)
    }

    const updatingCurrentUserPage = (clickedIndex, childRoleId, userId, fullName) => {
        const newData = currentUserPages.slice(0, clickedIndex + 1);
        // Update the state with the new array
        SetCurrentUserPages(newData);
        // console.log("nav Id"+childRoleId);
        setCurrentDashboard({ roleId: childRoleId, userId: userId });

        fetchChildData(childRoleId, fullName, userId, true)
    }

    // const getSubProfitLossDataFunc = async (eventID) => {
    //     console.log(`enteredd the func`);
    //     console.log(eventID);
    //     if (OpenMore?.id === eventID) {
    //         console.log(`open more to close`);
    //         setOpenMore({})
    //     } else {
    //         setOpenMore({ id: eventID });
    //         console.log(`open more to open`);

    //         try {
    //             console.log(`entered the try log!!`);
    //             let res = await axios.get(`https://api.betcare.live/api/admin/subProfitLossData/${eventID}/${betType}`);
    //             console.log(`after res`);
    //             let resData = await res.data;
    //             console.log(`after resData`);
    //             setSubProfitLossData(resData);
    //             let backResult = subProfitLossData?.reduce((acc, obj) => obj.Type === 'back' ? acc + obj.ResultAmount : acc, 0);

    //             console.log(resData);
    //             setBackTotal(backResult);

    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }


    // const funcToSetSelectedUser = (selectedUsername, selectedRoleId, selectedStatus, selectedParentStatus, selectedId) => {

    //     console.log("data in funcToSeleUs : ", selectedUsername, selectedRoleId, selectedStatus, selectedParentStatus, selectedId)

    //     if (selectedParentStatus !== 1) {
    //         toast.error(`Your Upline is ${selectedParentStatus === 2 ? "Blocked !!" : "Suspended !!"}`, {
    //             autoClose: 1500,
    //             theme: "colored",
    //         });
    //     }

    //     const data = {
    //         name: selectedUsername,
    //         RoleId: selectedRoleId,
    //         userstatus: selectedStatus,
    //         userparentStatus: selectedParentStatus,
    //         id: selectedId
    //     }

    //     console.log("selecte user stting tab : ", data)

    //     setSelectedUser({
    //         name: selectedUsername,
    //         RoleId: selectedRoleId,
    //         userstatus: selectedStatus,
    //         userparentStatus: selectedParentStatus,
    //         id: selectedId
    //     })
    // }

    const setDateFunc = async (btnName) => {

        if (btnName === 'today') {
            setFromDate(today);
            setFromTime(`00:00:00`);
            setToDate(Tommorrow);
            setToTime(`12:59:59`);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setLoadPage((prev) => !prev);

        } else if (btnName === "yesterday") {

            setFromDate(yesterday);
            setFromTime(`00:00:00`);
            setToTime(`12:59:59`);
            setToDate(today);
            // await queryClient.refetchQueries({ type: 'betting-data' });
            // refetch();
            setPage(1);
            setLoadPage((prev) => !prev);

        }
    }

    const onChangeHandler = (e, dateTimeType) => {
        if (dateTimeType === "fromDate") {
            setFromDate(e.target.value);

            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "fromTime") {
            setFromTime(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "toDate") {
            setToDate(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
        else if (dateTimeType === "toTime") {
            setToTime(e.target.value);
            // setLoadPage((prev)=>!prev);
            // refetch();
        }
    }

    async function getPLMarket() {
        // await queryClient.refetchQueries({ type: 'betting-data' });
        setLoadPage((prev) => !prev);
        setPage(1);
        console.log('current user pages : ', currentUserPages)
    }

    useEffect(() => {
        if (currentUserPages.length > 0) {
            const lastPage = currentUserPages[currentUserPages.length - 1];
            fetchChildData(lastPage.roleId, lastPage.fullName, lastPage.id, true);
        }
        // fetchMatched();
    }, [isLoggedIn, updatePage, submit, loadPage, page])


    useEffect(() => {
        calcTotal();
    }, [allData])



    return (
        <>
            {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
            <div className="inner-pages">
                <div className="container mxw-100">
                    <div className="page-title"> Profit/Loss Report by Downline </div>
                    <div className="my-report">
                        <div className="time-zone-section">
                            <ul className="list-unstyled">
                                <li>
                                    <label className="my-1 mr-2 fw-normal">Period</label>
                                    <input
                                        type="date"
                                        formcontrolname="periodFormDate"
                                        value={fromDate}
                                        onChange={(e) => { onChangeHandler(e, "fromDate") }}
                                        id="sdate"
                                        className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                        style={{ width: '100%' }}
                                    />
                                    {/* <input
                                        type="time"
                                        placeholder="9:00:00"
                                        formcontrolname="periodFormTime"
                                        value={fromTime}
                                        onChange={(e) => { onChangeHandler(e, "fromTime") }}
                                        id="stime"
                                        ng-model="datetime.time"
                                        className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                    /> */}
                                    <span className="d-inline-flex mx-2">To</span>
                                    <input
                                        type="date"
                                        formcontrolname="periodtoDate"
                                        value={toDate}
                                        onChange={(e) => { onChangeHandler(e, "toDate") }}
                                        id="edate"
                                        className="form-control me-3 cal-date ng-untouched ng-pristine ng-valid"
                                        style={{ width: '100%' }}
                                    />
                                    {/* <input
                                        type="time"
                                        placeholder="8:59:59"
                                        id="etime"
                                        formcontrolname="periodtotime"
                                        value={toTime}
                                        onChange={(e) => { onChangeHandler(e, "toTime") }}
                                        className="form-control time-date ng-untouched ng-pristine ng-valid w-100"
                                    /> */}
                                </li>
                            </ul>
                            <ul className="list-unstyled report-btn-group">
                                <li>
                                    <button
                                        type="button"
                                        className={`${fromDate === today ? toDate === Tommorrow ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                        onClick={() => { setDateFunc(`today`) }}
                                    >
                                        Just For Today
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className={`${fromDate === yesterday ? toDate === today ? "bg-black text-white" : "light-btn" : "light-btn"}`}
                                        onClick={() => { setDateFunc(`yesterday`) }}
                                    >
                                        From Yesterday
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="submit"
                                        onClick={getPLMarket}
                                        className="btnblk"
                                    >
                                        Get P &amp; L
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div />
                    </div>
                    <div>
                        <ul id="agentPath" className="agent_path-L mb-0 ng-star-inserted">
                            {currentUserPages.map((e, index) => {
                                return (
                                    <li style={{ cursor: "pointer" }} id="path_3" key={e.fullName} onClick={() => { updatingCurrentUserPage(index, e.roleId, e.id, e.fullName) }} className="ng-star-inserted active">
                                        <a >
                                            <span className={`lv_${e.roleId - 2}`}> {UserRoleShortName[e.roleId]} </span>
                                            <strong> {e.fullName}</strong>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div id="DownlineList">
                        <div className="profile-section mt-4 ng-star-inserted">
                            <div className="table-responsive">
                                <table className="table table-pt ng-star-inserted">
                                    <thead>
                                        <tr>
                                            <th>UID</th>
                                            <th style={{ textAlign: "right" }}>Stake</th>
                                            <th style={{ textAlign: "right" }}>Player P/L </th>
                                            <th style={{ textAlign: "right" }} className="ng-star-inserted">
                                                Downline PL
                                            </th>
                                            <th style={{ textAlign: "right" }}>Super Comm.</th>
                                            <th style={{ textAlign: "right" }}>Upline P/L</th>
                                        </tr>
                                    </thead>
                                    {allData.map((item, index) => {
                                        let status = item.parentStatus;
                                        if (item.parentStatus < item.Status) {
                                            status = item.Status;
                                        }

                                        // let clickBalance = 0

                                        // if (item.RoleId !== 8) {
                                        //     clickBalance += item.ResultAmountU != null ? item.ResultAmountU : 0;
                                        // } else {
                                        //     clickBalance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
                                        //         (item.UserPL != null ? item.UserPL : 0)
                                        // }



                                        return (
                                            <tbody key={item.Id}>
                                                <tr className="ng-star-inserted">
                                                    <td id={`userFullName_${index}`} className="text-start ng-star-inserted">
                                                        <span>
                                                            {openMoreIndex === index ? (
                                                                <FaRegSquareMinus
                                                                    className="cursor-pointer"
                                                                    style={{ fontSize: "15px", cursor: "pointer" }}
                                                                    onClick={() => setOpenMoreIndex(null)}
                                                                />
                                                            ) : (
                                                                <FiPlusSquare
                                                                    className="cursor-pointer"
                                                                    style={{ fontSize: "15px", cursor: "pointer" }}
                                                                    onClick={() => setOpenMoreIndex(index)}
                                                                />
                                                            )}
                                                        </span>
                                                        <span className="lv_1">{UserRoleShortName[item.RoleId]}</span>{" "}
                                                        <a
                                                            href="javascript:void(0);"
                                                            onclick="getSubUsersList('SA','superadmin',3,3)"
                                                        >
                                                            {item.RoleId === 8 ? (
                                                                <a> {item.UserName}</a>
                                                            ) : (
                                                                <Link
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        fetchChildData(
                                                                            item.RoleId,
                                                                            item.UserName,
                                                                            item.Id,
                                                                            false
                                                                        )
                                                                    }
                                                                    }
                                                                >
                                                                    {item.UserName}
                                                                </Link>
                                                            )}
                                                        </a>
                                                    </td>
                                                    <td className={item.UserStake < 0 ? "red" : ""}>
                                                        {item.UserStake != null ? item.UserStake : 0}
                                                    </td>

                                                    <td className={item.UserPL < 0 ? "red" : ""}>
                                                        {item.UserPL != null ? item.UserPL : 0}
                                                    </td>
                                                    <td className={item.UserPL < 0 ? "red" : ""}>
                                                        {item.UserPL != null ? item.UserPL : 0}
                                                    </td>
                                                    <td>0.00</td>
                                                    <td className={item.UserPL < 0 ? "red" : ""}>
                                                        {item.UserPL != null ? item.UserPL : 0}
                                                    </td>
                                                </tr>
                                                {openMoreIndex === index && (
                                                    <>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "left" }}> TENNIS </td>
                                                            <td > {item.TennisStake != null ? item.TennisStake : 0}</td>
                                                            <td className={item.TennisPL < 0 ? 'red' : ''}>{item.TennisPL != null ? item.TennisPL : 0}</td>
                                                            <td className={item.TennisPL < 0 ? 'red' : ''}>{item.TennisPL != null ? item.TennisPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.TennisPL < 0 ? 'red' : ''}>{item.TennisPL != null ? item.TennisPL : 0}</td>
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "left" }}> SOCCER </td>
                                                            <td > {item.SoccerStake != null ? item.SoccerStake : 0}</td>
                                                            <td className={item.SoccerPL < 0 ? 'red' : ''}>{item.SoccerPL != null ? item.SoccerPL : 0}</td>
                                                            <td className={item.SoccerPL < 0 ? 'red' : ''}>{item.SoccerPL != null ? item.SoccerPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.SoccerPL < 0 ? 'red' : ''}>{item.SoccerPL != null ? item.SoccerPL : 0}</td>
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "left" }}> Cricket-Bookmaker </td>
                                                            <td > {item.CricBMStake != null ? item.CricBMStake : 0}</td>
                                                            <td className={item.CricBMPL < 0 ? 'red' : ''}>{item.CricBMPL != null ? item.CricBMPL : 0}</td>
                                                            <td className={item.CricBMPL < 0 ? 'red' : ''}>{item.CricBMPL != null ? item.CricBMPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.CricBMPL < 0 ? 'red' : ''}>{item.CricBMPL != null ? item.CricBMPL : 0}</td>
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start' style={{ textAlign: "right" }}> Cricket-Fancy </td>
                                                            <td> {item.CricFCStake != null ? item.CricFCStake : 0}</td>
                                                            <td className={item.CricFCPL < 0 ? 'red' : ''}>{item.CricFCPL != null ? item.CricFCPL : 0}</td>
                                                            <td className={item.CricFCPL < 0 ? 'red' : ''}>{item.CricFCPL != null ? item.CricFCPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.CricFCPL < 0 ? 'red' : ''}>{item.CricFCPL != null ? item.CricFCPL : 0}</td>
                                                        </tr>
                                                        <tr key={index} className={`table-secondary`}>
                                                            <td className='text-start ' style={{ textAlign: "right" }}> Cricket </td>
                                                            <td > {item.CricMOStake != null ? item.CricMOStake : 0}</td>
                                                            <td className={item.CricMOPL < 0 ? 'red' : ''}>{item.CricMOPL != null ? item.CricMOPL : 0}</td>
                                                            <td className={item.CricMOPL < 0 ? 'red' : ''}>{item.CricMOPL != null ? item.CricMOPL : 0}</td>
                                                            <td> 0</td>
                                                            <td className={item.CricMOPL < 0 ? 'red' : ''}>{item.CricMOPL != null ? item.CricMOPL : 0}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        )
                                    })}
                                    <tr className="total fw-bold banking-table-footer">
                                        <td className="align-L">Total</td>
                                        <td id="totalBalance"> {totalStake}</td>
                                        <td id="totalAvailableBalance">{totalUserPLAmount}</td>
                                        <td id="totalExposure">{totalUserPLAmount}</td>
                                        <td
                                            id="totalCreditReference"
                                            colSpan={2}
                                            className="text-center"
                                        >
                                            {totalUserPLAmount}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <pagination-controls className="text-center">
                    <pagination-template>
                        <nav aria-label="Pagination">
                            <ul className="ngx-pagination ng-star-inserted">
                                <li
                                    className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                                >
                                    <button
                                        className="ng-star-inserted"
                                        style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                        onClick={() => { setPage((prev) => prev - 1) }}
                                        disabled={page === 1 && true}
                                    >
                                        Previous{" "}
                                    </button>
                                </li>
                                {/* <li className="current ng-star-inserted">
                              <span
                                aria-live="polite"
                                className="ng-star-inserted"
                              >
                                <span className="show-for-sr">
                                  You're on page{" "}
                                </span>
                                <span>1</span>
                              </span>
                            </li> */}
                                <li
                                    className={`pagination-next  ${page === Math.ceil(totalrows / pageSize) ? "disabled" : totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                                >
                                    <button
                                        // aria-disabled="true"
                                        className="ng-star-inserted"
                                        style={page === Math.ceil(totalrows / pageSize) ? { cursor: "not-allowed", border: "0px" } : totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                                        onClick={() => setPage((prev) => prev + 1)}
                                        disabled={page === Math.ceil(totalrows / pageSize) ? true : totalrows <= 20 ? true : false}
                                    >Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </pagination-template>
                </pagination-controls>
            </div>
        </>

    )
}

export default ProfitLossReportByDownline