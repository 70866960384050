import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import currentUser from '../../context/currentUser/currentUser';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';

const TransactionHistory = () => {

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const totalPages = 20;

  let { state } = useLocation();

  const { data: transactiondata, isLoading, isError } = useQuery({
    queryKey: [`transactionHistory`, page], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/admin/getTransactionData/${state?.id}?selfUser=${!state?.selfUser ? 0 : state?.selfUser}&page=${page}&totalpage=${totalPages}`);
      let resData = res.data;
      console.log('transaction history resData : ', resData);
      return resData;
    }
  });

  const context = useContext(currentUser);
  const { isLoggedIn, roleId, fullName, name, userStatusId, setIsLoggedIn, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages } = context;

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  }


  useEffect(() => {
    (async () => {
      let res = await fetch(`https://api.betcare.live/api/getUserDetail/users/${state?.id}`);
      let resData = await res.json();
      setData(resData);
    })()

    //  ;(async()=>{
    //     let res = await fetch(`https://api.betcare.live/api/admin/getTransactionData/${state?.id}`);
    //     let resData = await res.json();
    //     setTransactionData(resData);
    //  })()

  }, [])


  return (
    <>

      <main role="main" className="pb-3">
        <div className="account-pages">
          <div className="container mxw-100">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <ul id="agentPath" className="agent_path-L mb-0">
                    <li id="path6">
                      <a href="javascript: void(0);">
                        <span className="lv_0"> {UserRoleShortName[data?.length === 0 ? ".." : data[0].RoleId]} </span>
                        <strong>  {data.length === 0 ? ".." : data[0].UserName}  </strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 pe-0">
                <div className="match-list-inplay">
                  <div className="vertical-match-list">
                    <ul className="list-unstyled">
                      <li className="menu-title">Position</li>
                      <li id="linkActive" className="d-none">
                        <a
                          className="link"
                          href="/User/AccountStatement?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Account Statement
                        </a>
                      </li>
                      <li id="linkActive" className="">
                        <Link
                          className="link"
                          to="/accountsummary"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Account Summary
                        </Link>
                      </li>
                      <li className="menu-title">
                        <a>Performance</a>
                      </li>
                      <li id="linkActiveBetting" className="">
                        <Link
                          className="link"
                          to="/bettinghistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting History
                        </Link>
                      </li>
                      <li id="linkActiveLoss" className="">
                        <Link
                          className="link"
                          to="/bettingprofitloss"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Betting Profit &amp; Loss
                        </Link>
                      </li>
                      <li id="linkActiveHistory" className="active">
                        <Link
                          className="link"
                          to="/transactionhistory"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          {" "}
                          Transaction History
                        </Link>
                      </li>
                      <li id="Profile" className="d-none">
                        <a
                          className="link"
                          href="/user/Profile?UserId=3&shortname=SA&username=superadmin&IsByList=True"
                        >
                          Profile
                        </a>
                      </li>
                      <li id="linkActiveLog" className="">
                        <Link
                          className="link"
                          to="/activitylog"
                          state={currentDashboard !== null && { id: state?.id, selfUser: state?.selfUser }}
                        >
                          Activity Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="myaccount-page">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="pagettl"> Transaction History </div>
                      <div className="d-flex justify-content-between align-items-end my-2">
                        <div className="button-grp">
                          <button
                            tabIndex={0}
                            aria-controls="example"
                            type="button"
                            className="dt-button buttons-excel buttons-html5 buttons-pdf"
                          >
                            <span>
                              <i className="fa fa-file-text-o" aria-hidden="true" />{" "}
                              PDF
                            </span>
                          </button>
                          <button
                            tabIndex={0}
                            aria-controls="example"
                            type="button"
                            className="dt-button buttons-excel buttons-html5"
                          >
                            <span>
                              <i className="fa fa-file-text-o" aria-hidden="true" />{" "}
                              Excel
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="profile-section">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Date/Time</th>
                                <th style={{ textAlign: "right" }}>Deposit</th>
                                <th style={{ textAlign: "right" }}>Withdraw</th>
                                <th style={{ textAlign: "right" }}>Balance</th>
                                <th style={{ textAlign: "right" }}>Remark</th>
                                <th style={{ textAlign: "right" }}>From/To</th>
                              </tr>
                            </thead>
                            <tbody>
                              { }

                              {state?.selfUser != null ?
                                state?.selfUser == 0 ?
                                  transactiondata?.rows?.map((row) => {

                                    const parsedTimestamp = moment(row.Date);

                                    // Format date and time
                                    const formattedDate = parsedTimestamp.format('DD/MM/YYYY');
                                    const formattedTime = parsedTimestamp.format('HH:mm:ss');

                                    return <tr key={row.Id} >
                                      <td>{formattedDate + " " + formattedTime}</td>
                                      <td>{row.Deposit}</td>
                                      <td>{row.Withdraw}</td>
                                      <td>{row.Balance}</td>
                                      <td>{`Balance ${row.Deposit === 0 ? "Deposit" : "Withdraw"} to ${row.UserName} by ${row.ByUserName}`}</td>
                                      <td>{`${row.Deposit === 0 ? data?.length === 0 ? ".." : data[0].UserName : row.UserName} > ${row.Deposit !== 0 ? data.length === 0 ? ".." : data[0].UserName : row.UserName}`}</td>
                                    </tr>
                                  })
                                  :
                                  transactiondata?.rows?.map((row) => {

                                    const parsedTimestamp = moment(row.StatusChangeDate);

                                    // Format date and time
                                    const formattedDate = parsedTimestamp.format('DD/MM/YYYY');
                                    const formattedTime = parsedTimestamp.format('HH:mm:ss');

                                    return <tr key={row.Id} >
                                      <td>{formattedDate + " " + formattedTime}</td>
                                      <td>{row.type == 'deposit' ? row.Amount : 0}</td>
                                      <td>{row.type == 'withdraw' ? row.Amount : 0}</td>
                                      <td>{ }</td>
                                      <td>{`Balance ${row.type === 'withdraw' ? "Deposit" : "Withdraw"} to ${row.UserName} by ${row.approverName}`}</td>
                                      <td>{`${row.type === 'withdraw' ? row.UserName : row.approverName} > ${row.type !== 'withdraw' ? row.UserName : row.approverName}`}</td>
                                    </tr>
                                  })
                                :
                                transactiondata?.rows?.map((row) => {

                                  const parsedTimestamp = moment(row.Date);

                                  // Format date and time
                                  const formattedDate = parsedTimestamp.format('DD/MM/YYYY');
                                  const formattedTime = parsedTimestamp.format('HH:mm:ss');

                                  return <tr key={row.Id} >
                                    <td>{formattedDate + " " + formattedTime}</td>
                                    <td>{row.Deposit}</td>
                                    <td>{row.Withdraw}</td>
                                    <td>{row.Balance}</td>
                                    <td>{`Balance ${row.Deposit === 0 ? "Deposit" : "Withdraw"} to ${row.UserName} by ${row.ByUserName}`}</td>
                                    <td>{`${row.Deposit === 0 ? data?.length === 0 ? ".." : data[0].UserName : row.UserName} > ${row.Deposit !== 0 ? data.length === 0 ? ".." : data[0].UserName : row.UserName}`}</td>
                                  </tr>
                                })
                              }



                              {/* <tr className="ng-star-inserted">
                            <td>6 /3/2024 3:42:33 </td>
                            <td>0</td>
                            <td>10000</td>
                            <td>1501000</td>
                            <td />
                            <td>tech_admin &gt; superadmin </td>
                          </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>
        <pagination-controls className="text-center">
          <pagination-template>
            <nav aria-label="Pagination">
              <ul className="ngx-pagination ng-star-inserted">
                <li
                  className={`pagination-previous ${page === 1 ? "disabled" : ""} ng-star-inserted`}
                >
                  <button
                    className="ng-star-inserted"
                    style={page === 1 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                    onClick={() => setPage((prev) => prev - 1)}
                    disabled={page === 1 && true}
                  >
                    Previous{" "}
                  </button>
                </li>
                {/* <li className="current ng-star-inserted">
                              <span
                                aria-live="polite"
                                className="ng-star-inserted"
                              >
                                <span className="show-for-sr">
                                  You're on page{" "}
                                </span>
                                <span>1</span>
                              </span>
                            </li> */}
                <li
                  className={`pagination-next  ${page === Math.ceil(transactiondata?.totalrows / totalPages) ? "disabled" : transactiondata?.totalrows <= 20 ? "disabled" : ""} ng-star-inserted`}
                >
                  <button
                    // aria-disabled="true"
                    className="ng-star-inserted"
                    style={page === Math.ceil(transactiondata?.totalrows / totalPages) ? { cursor: "not-allowed", border: "0px" } : transactiondata?.totalrows <= 20 ? { cursor: "not-allowed", border: "0px" } : { cursor: "pointer", border: "0px" }}
                    onClick={() => setPage((prev) => prev + 1)}
                    disabled={page === Math.ceil(transactiondata?.totalrows / totalPages) ? true : transactiondata?.totalrows <= 20 ? true : false}
                  >Next
                  </button>
                </li>
              </ul>
            </nav>
          </pagination-template>
        </pagination-controls>
      </div>
      <div className="donloadicon-list">
        <ul>
          <li>
            <div className="icon">
              <img src="images/doller-btn.png" />
            </div>
            <div className="title">Bank</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profit-btn.png" />
            </div>
            <div className="title">Betting Profit &amp; Loss</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/history-btn.png" />
            </div>
            <div className="title">Betting History</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/profile-btn.png" />
            </div>
            <div className="title">Profile</div>
          </li>
          <li>
            <div className="icon">
              <img src="images/setting-bet.png" />
            </div>
            <div className="title">Change Status</div>
          </li>
        </ul>
      </div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
    </>

  )
}

export default TransactionHistory
