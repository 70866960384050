import React, { useContext, useEffect, useState } from 'react'
import currentUser from '../context/currentUser/currentUser';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function Banking() {


  const loggedInUserName = localStorage.getItem('UserName').replace(/^"(.*)"$/, '$1');


  // to store the all child data
  const [ChildData, setChildData] = useState([]);

  const [allData, setAllData] = useState([])

  const [userStatus, setUserStatus] = useState(0);

  // to store all the username in array
  const [allUsernameList, setAllUsernameList] = useState([]);

  // to store if the typed user exist or not
  const [userExist, setUserExist] = useState(null);
  const [userNotExist, setUserNotExist] = useState(null);

  // to store the detail of selected user in case of changing status
  const [selectedUser, setSelectedUser] = useState({ name: "", RoleId: 0, userstatus: 0, userparentStatus: 0, id: 0 });

  // to store the current user type
  const [addUserState, setAddUserState] = useState("");

  // for reloading the data 
  const [updatePage, setUpdatePage] = useState(true);



  // data state for adding the New User
  const [AddUsername, setAddUserName] = useState("");
  const [AddPassword, setAddPassword] = useState("");
  const [AddConfirmPassword, setAddConfirmPassword] = useState("");





  // //console.log(selectedUser);

  // it will store the current status for the selected user
  const [currentStatus, setCurrentStatus] = useState(selectedUser.userparentStatus);

  // it will store the status that user need
  const [changeStatusTo, setChangeStatusTo] = useState(0);

  // it will store the password that user typed in status change model
  const [userTypedPassword, setUserTypesPassword] = useState("");

  //store deposit withdraw data of clicked log button
  const [accStatement, setAccStatement] = useState([])
  const [submit, setSubmit] = useState(false)

  const [totalExposure, settotalExposure] = useState(0);
  const [availBalance, setavailBalance] = useState(0);
  const [calcBalance, setcalcBalance] = useState(0);
  const [clickedUserBalance, setClickedUserBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [currentCreditRef, setCurrentCreditRef] = useState(0)
  const [newCreditRef, setNewCreditRef] = useState(0);
  const [loggedInPas, setLoggedInPass] = useState('');
  const [CreditUserId, setCreditUserId] = useState('');

  const [totalCR, settotalCR] = useState(0); // total Credit Reference
  const [totalRPL, setRPL] = useState(0); // total Reference PL



  const context = useContext(currentUser);
  const { isLoggedIn, roleId, fullName, name, setIsLoggedIn, userStatusId, setFullName, currentDashboard, setCurrentDashboard, currentUserPages, SetCurrentUserPages, Currency, mainBalance, allApiUserdata, setRefetchUsers, reloadOn } = context;

  const userIdLS = localStorage.getItem(`UserId`);
  const navigate = useNavigate();
  //  //console.log(name);

  const UserRoleShortName = {
    1: "DM",
    2: "TA",
    3: "SA",
    4: "SB",
    5: "SS",
    6: "SU",
    7: "MA",
    8: "PL"
  }

  const UserRoleFullName = {
    1: "Domain",
    2: "TechAdmin",
    3: "SuperAdmin",
    4: "SubAdmin",
    5: "SuperSuper",
    6: "Super",
    7: "Master",
    8: "User"
  }

  useEffect(() => {
    console.log('reloadOn User ; ', reloadOn)
    setIsLoading(reloadOn)

  }, [reloadOn]);



  const loginBalance = async () => {
    try {
      //console.log("User Id : ", userIdLS)
      const res = await fetch(`https://api.betcare.live/myBalance/${currentDashboard.userId}`)
      const resData = await res.json();

      if (resData.isSuccess) {

        //console.log("login Balance : ", resData.mainBalance[0].ResultAmountP)
        setClickedUserBalance(resData.mainBalance[0].ResultAmountP)
      }

    } catch (error) {
      console.error("Error Fetching Login Balance..!")
    }
  }

  // function to fetch the child data of user
  const fetchChildData = async () => {
    try {
      // setIsLoading(true)

      console.log(' current dashboard  : ', currentDashboard);
      console.log(' current user pages  : ', currentUserPages);

      let res = await fetch(`https://api.betcare.live/getUsers/${currentDashboard.userId}`);
      let resData = await res.json();
      console.log("user data : ", resData)

      setChildData(resData.data);

      let filterUser;
      if (userStatus != 0) {
        filterUser = allApiUserdata.filter((item) => {
          return resData.data.some(user => item.Id == user.Id && item.ASBusers == userStatus)
        });
      } else {
        filterUser = allApiUserdata.filter((item) => {
          return resData.data.some(user => item.Id == user.Id)
        });
      }
      console.log('filterUser data length : ', filterUser?.length)
      console.log('filterUser data : ', filterUser)
      setAllData(filterUser)


    } catch (err) {

    } finally {
      // setIsLoading(false)
    }
  }

  const fetchChildBalance = async () => {
    let res = await fetch(`https://api.betcare.live/api/getDepositWithdraw/${currentDashboard.userId}`)
    let resData = await res.json();
    //console.log(" Account Statement of user Id : ", currentDashboard.userId, " is : ", resData);
  }

  const fetchExposure = async () => {
    try {

      const responseRunner = await fetch(`https://api.betcare.live/getRunners`);
      const resultRunner = await responseRunner.json();

      const runnersSelectionIds = [];

      if (resultRunner.isSuccess && resultRunner.data.length > 0) {
        // Extracting SelectionId and Market from each item in the resultRunner.data array
        const filterRunnerSId = resultRunner.data.map(item => ({
          eventId: item.EventId,
          selectionId: item.SelectionId,
          market: item.Market
        }));

        runnersSelectionIds.push(...filterRunnerSId);
      }

      // //console.log("Runner Selection ID in fetch Exposure function  : ", runnersSelectionIds);

      const matchOddsBetsByRunner = runnersSelectionIds.filter(runner => runner.market === "Matchodds")

      const SBetsByRunner = matchOddsBetsByRunner.filter(runner => runner.selectionId);

      // //console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunner);


      const BookMakerBetsByRunner = runnersSelectionIds.filter(runner => runner.market === "BookMaker")

      const SBetsByRunnerBM = BookMakerBetsByRunner.filter(runner => runner.selectionId);

      // //console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunnerBM);

      let exposureArr = [];

      // //console.log("Data before exposure : ", ChildData)

      const promises = ChildData.map(async item => {
        try {

          const response = await fetch(`https://api.betcare.live/getExposure/${item.Id}`);
          const result = await response.json();

          if (result.isSuccess) {
            // //console.log("Exposure Api data for Id:", item.Id, " is : ", result.data);


            let exposure = 0;

            // Iterate over each unique EventId
            result.data.forEach(event => {
              const eventId = event.eventId;
              const eventSelections = [];
              const eventSelectionsBM = [];


              // For BACK !!!!!!!

              const filtermatchOdds = event.data.filter(item => item.market == "Matchodds" && SBetsByRunner.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

              const totalProfit = filtermatchOdds.reduce((sum, item) => sum + item.profit, 0);

              // //console.log("Total Profit for back: ", totalProfit);
              const totalLoss = filtermatchOdds.reduce((sum, item) => sum + item.liability, 0);

              // //console.log("Total Loss for back: ", totalLoss);

              // //console.log("Match Odd filter : " + JSON.stringify(filtermatchOdds))

              // For LAY !!!!!!

              const filtermatchOddsLay = event.data.filter(item => item.market == "Matchodds" && SBetsByRunner.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

              const totalProfitLay = filtermatchOddsLay.reduce((sum, item) => sum + item.profit, 0);

              // //console.log("Total Loss for Lay : ", totalProfitLay);
              const totalLossLay = filtermatchOddsLay.reduce((sum, item) => sum + item.liability, 0);

              // //console.log("Total Profit for Lay : ", totalLossLay);

              // //console.log("Match Odd filter for lay : " + JSON.stringify(filtermatchOddsLay))

              // NEXT TEAM For BACK /////////////

              const filterODD = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "Matchodds" &&
                  item.type === "back" &&
                  SBetsByRunner.some(oddsitem => oddsitem.selectionId == item.selectionId);
              });

              // //console.log("filter ODD Next team : ", filterODD)

              // Get unique selectionIds from filterODDLay
              const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

              // //console.log("Filter odd SelectionIds ssss : ", filterODDSelectionIds)

              const filterOddAgainForOdd = SBetsByRunner.filter(item => !filterODDSelectionIds.has(item.selectionId)
                && filterODD.some(odds => odds.eventId == item.eventId));

              // //console.log("Filter odd again for odd ssss : ", filterOddAgainForOdd)

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // //console.log("Filter odd again for odd with profit loss ssss : ", filterOddAgainWithProfitLossOdd)
              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

              // //console.log("Next Team for Odd: " + JSON.stringify(combinedArrayForOdd));

              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionId = {};
              const totalLossBySelectionId = {};

              // Calculate net profit for each selectionId
              combinedArrayForOdd.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionId[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
                  totalLossBySelectionId[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionId[selectionId] += profit;
                totalLossBySelectionId[selectionId] += loss;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionId = {};
              Object.keys(totalProfitBySelectionId).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

                const profit = totalProfitBySelectionId[selectionId];
                const loss = totalLossBySelectionId[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
              });

              // // Example usage:
              // //console.log("Total Profit by SelectionId for Odd: ", totalProfitBySelectionId);
              // //console.log("Total Loss by SelectionId for Odd : ", totalLossBySelectionId);
              // //console.log("Net Profit by SelectionId for Odd : ", netProfitBySelectionId);

              // NEXT TEAM For LAY /////////////

              const filterODDLay = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "Matchodds" &&
                  item.type === "lay" &&
                  SBetsByRunner.some(oddsitem => oddsitem.selectionId === item.selectionId);
              });

              // //console.log("filterODDLay fltered data : ", filterODDLay)
              // Get unique selectionIds from filterODDLay
              const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

              // Filter items from odds based on selectionId not present in filterODDLay
              const filterOddAgain = SBetsByRunner.filter(item => !filterODDLaySelectionIds.has(item.selectionId) && filterODDLay.some(odds => odds.eventId == item.eventId));

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // //console.log("filter odd Again : " + JSON.stringify(filterOddAgainWithProfitLoss));

              // //console.log("Next Team for Lay : " + JSON.stringify(filterODDLay));

              // //console.log("Odds item : " + JSON.stringify(SBetsByRunner.length))

              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

              // //console.log("Combined Both Array: " + JSON.stringify(combinedArray));

              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionIdLay = {};
              const totalLossBySelectionIdLay = {};

              // Calculate net profit for each selectionId
              combinedArray.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionIdLay[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
                  totalLossBySelectionIdLay[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionIdLay[selectionId] += loss;
                totalLossBySelectionIdLay[selectionId] += profit;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionIdLay = {};
              Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

                const profit = totalProfitBySelectionIdLay[selectionId];
                const loss = totalLossBySelectionIdLay[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
              });


              // //console.log("Total Profit by SelectionId Odd: ", netProfitBySelectionId)
              // //console.log("Total Loss by SelectionId Odd: ", totalLossBySelectionId)
              // //console.log("Total Profit by SelectionId Lay: ", totalProfitBySelectionIdLay);
              // //console.log("Total Loss by SelectionId Lay: ", totalLossBySelectionIdLay);
              // //console.log("Net Profit by SelectionId Lay: ", netProfitBySelectionIdLay);

              // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
              const finalProfitMatchOdd = {};

              Object.keys(netProfitBySelectionId).forEach(selectionId => {
                // Sum the corresponding values for each selectionId
                finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
              });

              // //console.log("Final Profit for Match Odd: ", finalProfitMatchOdd);

              const negativeProfits = Object.values(finalProfitMatchOdd).filter(profit => profit < 0);

              // If there are negative profits, find the maximum among them
              if (negativeProfits.length > 0) {
                const maxNegativeProfit = Math.min(...negativeProfits);
                // //console.log("Maximum negative profit from finalProfitMatchOdd: ", maxNegativeProfit);

                exposure += maxNegativeProfit;
              } else {
                // //console.log("No negative profits found in finalProfitMatchOdd.");
              }

              // For Book Maker

              // //console.log("///////   Book Maker /////")
              // For BACK !!!!!!!

              const filtermatchOddsBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

              const totalProfitBM = filtermatchOddsBM.reduce((sum, item) => sum + item.profit, 0);

              // //console.log("Total Profit for back BookMaker : ", totalProfitBM);
              const totalLossBM = filtermatchOddsBM.reduce((sum, item) => sum + item.liability, 0);

              // //console.log("Total Loss for back BookMaker : ", totalLossBM);

              // //console.log("Match Odd filter BookMaker : " + JSON.stringify(filtermatchOddsBM))

              // For LAY !!!!!!

              const filtermatchOddsLayBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

              const totalProfitLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.profit, 0);

              // //console.log("Total Loss for Lay  BookMaker : ", totalProfitLayBM);
              const totalLossLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.liability, 0);

              // //console.log("Total Profit for Lay BookMaker : ", totalLossLayBM);

              // //console.log("Match Odd filter for lay BookMaker : " + JSON.stringify(filtermatchOddsLayBM))

              // NEXT TEAM For BACK /////////////

              const filterODDBM = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "BookMaker" &&
                  item.type === "back" &&
                  SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId == item.selectionId);
              });

              // //console.log("filter ODD Next team BookMaker : ", filterODDBM)

              // Get unique selectionIds from filterODDLay
              const filterODDSelectionIdsBM = new Set(filterODDBM.map(item => item.selectionId));

              // //console.log("Filter odd SelectionIds ssss BookMaker : ", filterODDSelectionIdsBM)

              const filterOddAgainForOddBM = SBetsByRunnerBM.filter(item => !filterODDSelectionIdsBM.has(item.selectionId)
                && filterODDBM.some(odds => odds.eventId == item.eventId));



              // //console.log("Filter odd again for odd ssss BookMaker : ", filterOddAgainForOddBM)

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLossOddBM = filterOddAgainForOddBM.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // //console.log("Filter odd again for odd with profit loss ssss BookMaker : ", filterOddAgainWithProfitLossOddBM)
              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArrayForOddBM = [...filterODDBM, ...filterOddAgainWithProfitLossOddBM];

              // //console.log("Next Team for Odd BookMaker : " + JSON.stringify(combinedArrayForOddBM));
              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionIdBM = {};
              const totalLossBySelectionIdBM = {};

              // Calculate net profit for each selectionId
              combinedArrayForOddBM.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionIdBM.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionIdBM[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionIdBM.hasOwnProperty(selectionId)) {
                  totalLossBySelectionIdBM[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionIdBM[selectionId] += profit;
                totalLossBySelectionIdBM[selectionId] += loss;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionIdBM = {};
              Object.keys(totalProfitBySelectionIdBM).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdBM)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBM[otherSelectionId], 0);

                const profit = totalProfitBySelectionIdBM[selectionId];
                const loss = totalLossBySelectionIdBM[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionIdBM[selectionId] = profit + totalLossToOtherSelectionsBM;
              });


              // // Example usage:
              // //console.log("Total Profit by SelectionId for Odd BookMaker: ", totalProfitBySelectionIdBM);
              // //console.log("Total Loss by SelectionId for Odd BookMaker : ", totalLossBySelectionIdBM);
              // //console.log("Net Profit by SelectionId for Odd  BookMaker : ", netProfitBySelectionIdBM);

              // NEXT TEAM For LAY /////////////

              const filterODDLayBM = event.data.filter(item => {
                // Check if the market is "Matchodds", type is "back", 
                // and there is at least one matching selectionId in the odds array
                return item.market === "BookMaker" &&
                  item.type === "lay" &&
                  SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId === item.selectionId);
              });

              // //console.log("filterODDLay fltered data BookMaker : ", filterODDLayBM)
              // Get unique selectionIds from filterODDLay
              const filterODDLaySelectionIdsBM = new Set(filterODDLayBM.map(item => item.selectionId));

              // Filter items from odds based on selectionId not present in filterODDLay
              const filterOddAgainBM = SBetsByRunnerBM.filter(item => !filterODDLaySelectionIdsBM.has(item.selectionId) && filterODDLayBM.some(odds => odds.eventId == item.eventId));

              // Add profit = 0 and loss = 0 to the items in filterOddAgain
              const filterOddAgainWithProfitLossBM = filterOddAgainBM.map(item => ({
                ...item,
                profit: 0,
                liability: 0,
              }));

              // //console.log("filter odd Again BookMaker : " + JSON.stringify(filterOddAgainWithProfitLossBM));

              // //console.log("Next Team for Lay BookMaker : " + JSON.stringify(filterODDLayBM));

              // //console.log("Odds item BookMaker : " + JSON.stringify(SBetsByRunnerBM.length))

              // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
              const combinedArrayBM = [...filterODDLayBM, ...filterOddAgainWithProfitLossBM];

              // //console.log("Combined Both Array BookMaker : " + JSON.stringify(combinedArrayBM));

              // Create an object to store total profit for each selectionId
              const totalProfitBySelectionIdLayBM = {};
              const totalLossBySelectionIdLayBM = {};

              // Calculate net profit for each selectionId
              combinedArrayBM.forEach(item => {
                const selectionId = item.selectionId;
                const profit = item.profit;
                const loss = item.liability;

                // If selectionId is not in the object, add it
                if (!totalProfitBySelectionIdLayBM.hasOwnProperty(selectionId)) {
                  totalProfitBySelectionIdLayBM[selectionId] = 0;
                }

                // If selectionId is not in the object, add it
                if (!totalLossBySelectionIdLayBM.hasOwnProperty(selectionId)) {
                  totalLossBySelectionIdLayBM[selectionId] = 0;
                }

                // Add profit to the total for this selectionId
                totalProfitBySelectionIdLayBM[selectionId] += loss;
                totalLossBySelectionIdLayBM[selectionId] += profit;
              });

              // Calculate the net profit (profit - loss) for each selectionId
              const netProfitBySelectionIdLayBM = {};
              Object.keys(totalProfitBySelectionIdLayBM).forEach(selectionId => {
                // Calculate total loss to other selectionIds
                const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdLayBM)
                  .filter(otherSelectionId => otherSelectionId !== selectionId)
                  .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBM[otherSelectionId], 0);

                const profit = totalProfitBySelectionIdLayBM[selectionId];
                const loss = totalLossBySelectionIdLayBM[selectionId];

                // Subtract total loss to other selectionIds from the profit
                netProfitBySelectionIdLayBM[selectionId] = profit + totalLossToOtherSelectionsBM;
              });


              // //console.log("Total Profit by SelectionId Odd BookMaker : ", netProfitBySelectionIdBM)
              // //console.log("Total Loss by SelectionId Odd BookMaker : ", totalLossBySelectionIdBM)
              // //console.log("Total Profit by SelectionId Lay BookMaker : ", totalProfitBySelectionIdLayBM);
              // //console.log("Total Loss by SelectionId Lay BookMaker : ", totalLossBySelectionIdLayBM);
              // //console.log("Net Profit by SelectionId Lay BookMaker : ", netProfitBySelectionIdLayBM);

              // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
              const finalProfitMatchOddBM = {};

              Object.keys(netProfitBySelectionIdBM).forEach(selectionId => {
                // Sum the corresponding values for each selectionId
                finalProfitMatchOddBM[selectionId] = (netProfitBySelectionIdBM[selectionId] || 0) + (netProfitBySelectionIdLayBM[selectionId] || 0);
              });

              // //console.log("Final Profit for Book Maker: ", finalProfitMatchOddBM);

              const negativeProfitsBM = Object.values(finalProfitMatchOddBM).filter(profit => profit < 0);

              // If there are negative profits, find the maximum among them
              if (negativeProfitsBM.length > 0) {
                const maxNegativeProfit = Math.min(...negativeProfitsBM);
                // //console.log("Maximum negative profit from finalProfitMatchOddBM BookMaker : ", maxNegativeProfit);
                exposure += maxNegativeProfit;
              } else {
                // //console.log("No negative profits found in finalProfitMatchOddBM BookMaker.");
              }

              //  FOR Fancy ///////
              // //console.log("//// Fancy ///")

              const filterFancy = event.data.filter(item => item.market == 'Fancy')

              // //console.log("filter fancy data : ", filterFancy)

              // //console.log(" Filter fancy liability : ", filterFancy.liability)

              const totalLiabilityFancy = filterFancy.reduce((sum, item) => sum + item.liability, 0);

              // //console.log("total Liabality of facny  : ", totalLiabilityFancy)

              exposure += totalLiabilityFancy;



            });
            // //console.log("Final Exposure of MO & BM & Fancy : ", exposure + " Id " + item.Id)

            exposureArr.push({ userId: item.Id, exposure: exposure })

            // setUserExposure(exposure) 
            // //console.log("Balance Api Balance is : ", apiBalance)
            // const calcBalance = apiBalance + exposure
            // setuserBalance(calcBalance)
            // Log exposureArr after all fetch requests have completed
            // //console.log("Exposure Array : ", exposureArr);

          } else {
            // //console.log("Error in fetching Bets exposure api in Header ");
          }
        } catch (error) {
          console.error('Error fetching exposure data:', error);
        }

      });

      // Wait for all promises to resolve
      await Promise.all(promises);

      // Log exposureArr after all fetch requests have completed
      // //console.log("Exposure Array outside  : ", exposureArr);

      // Map over the data array and add exposure to each element if its Id matches any userId in exposureArr
      const addExpo = ChildData.map(item => {
        // Find the exposure where userId matches Id
        const matchedExposure = exposureArr.find(exp => exp.userId === item.Id);
        // Add the matched exposure to the item
        return { ...item, exposure: matchedExposure ? matchedExposure.exposure : 0 };
      });

      //console.log("Add exposure of every data state array element whose Id is == to exposureArr of userId: ", addExpo);

      setAllData(addExpo)

    } catch (error) {
      console.error('Error fetching bets exposure api', error);
    }
  };

  const calcTotal = () => {
    let calctotalExposure = 0;
    let avaiBalance = 0;
    let balance = 0;
    let CR = 0;
    let RPL = 0;

    allData.forEach((item) => {
      // Assuming each item has a property named 'currentExposure'
      calctotalExposure += item.exposure != null ? Number(item.exposure) : 0;
      CR += item.CreditReference != null ? Number(item.CreditReference) : 0;RPL += Number(item.childDWresultAmountU != null ? item.childDWresultAmountU : 0) - Number(item.CreditReference != null ? item.CreditReference : 0);

      if (item.RoleId !== 8) {
        // Calculate available balance for RoleId other than 8
        avaiBalance += item.ResultAmountU != null ? item.ResultAmountU : 0;
        balance += item.ResultAmountU != null ? item.ResultAmountU : 0;
      } else {
        // Calculate available balance for RoleId 8
        avaiBalance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
          (item.UserPL != null ? item.UserPL : 0) +
          (item.exposure != null ? Number(item.exposure) : 0);

        balance += (item.ResultAmountU != null ? item.ResultAmountU : 0) +
          (item.UserPL != null ? item.UserPL : 0)
      }
    });

    // Now, totalExposure contains the sum of currentExposure of all elements
    //console.log("Total Exposure ", calctotalExposure);
    //console.log("AvailBalance ", avaiBalance);
    //console.log("Balance ", balance);

    settotalExposure(calctotalExposure)
    settotalCR(CR)
    setRPL(RPL)
    setavailBalance(avaiBalance)
    setcalcBalance(balance)


  }

  const NavigatToChildDasboard = (childRoleId, userName, userId, clickBalance) => {
    SetCurrentUserPages((prev) => [...prev, { fullName: userName, roleId: childRoleId, id: userId }]);
    setCurrentDashboard({ roleId: childRoleId, userId: userId });
    setClickedUserBalance(clickBalance)
  }

  const updatingCurrentUserPage = (clickedIndex, childRoleId, userId) => {
    const newData = currentUserPages.slice(0, clickedIndex + 1);
    // Update the state with the new array
    SetCurrentUserPages(newData);
    // //console.log("nav Id"+childRoleId);
    setCurrentDashboard({ roleId: childRoleId, userId: userId });
  }

  const handleAcc = async (userId, userName, parentName) => {
    let res = await fetch(`https://api.betcare.live/api/childAccStatement/${userId}`);
    let resData = await res.json();

    resData.data.sort((a, b) => {
      const dateComparison = new Date(b.Date) - new Date(a.Date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return a.setAcc - b.setAcc;
    });

    //console.log("Account Statement of user Id: ", userId, " is: ", resData.data);
    setAccStatement(resData.data);
  }


  let userActions = [];
  let selectedRows = []

  function DepositClick(id, index) {

    //console.log("User Id is : ", id)
    var element = document.getElementById('pay_' + index);
    var inputElem = document.getElementById('amount_' + index);
    var fullBtnElem = document.getElementById('fullBtn_' + index);

    fullBtnElem.classList.add("disable");

    if (element.classList.contains("select-D") || element.classList.contains("select-W")) {
      if (element.classList.contains("select-W")) {
        element.classList.remove("select-W");
        element.classList.add("select-D");
        inputElem.removeAttribute('readonly');
        document.getElementById("amount_" + index).value = 0;
      } else if (element.classList.contains("select-D")) {
        element.classList.remove("select-D");
        inputElem.setAttribute('readonly', true);
        document.getElementById("amount_" + index).value = 0;
      }
    } else {
      element.classList.add("select-D");
      inputElem.removeAttribute('readonly');
      document.getElementById("amount_" + index).value = 0;
    }

    // //console.log("UserId : ",id," : Amount is : ", inputElem.value)
  }

  function WithdrawClick(id, index) {

    //console.log("User Id is : ", id)
    var element = document.getElementById('pay_' + index);
    var inputElem = document.getElementById('amount_' + index);
    var fullBtnElem = document.getElementById('fullBtn_' + index);

    if (element.classList.contains("select-D") || element.classList.contains("select-W")) {
      if (element.classList.contains("select-D")) {
        element.classList.remove("select-D");
        element.classList.add("select-W");
        inputElem.removeAttribute('readonly');
        fullBtnElem.classList.remove("disable");
        document.getElementById('amount_' + index).value = 0;
      } else if (element.classList.contains("select-W")) {
        element.classList.remove("select-W");
        inputElem.setAttribute('readonly', true);
        fullBtnElem.classList.add("disable");
        document.getElementById('amount_' + index).value = 0;
      }
    } else {
      element.classList.add("select-W");
      inputElem.removeAttribute('readonly');
      fullBtnElem.classList.remove("disable");
      document.getElementById('amount_' + index).value = 0;
    }
    //console.log("UserId : ", id, " : Amount is : ", inputElem.value)
  }

  function AssignBalance(id, index, parent) {
    //console.log("User Id is : ", id)
    var inputElem = document.getElementById('amount_' + index);
    var element = document.getElementById('pay_' + index);
    if (element.classList.contains("select-W")) {
      document.getElementById('amount_' + index).value = parseInt(document.getElementById("availableBalance_" + index).innerText);
      //console.log("UserId : ", id, " : Assigned full Amount is : ", inputElem.value)


      if (inputElem.value > 0) {

        // Check if an object with the same userId already exists
        const existingIndex = userActions.findIndex(action => action.userId === id);

        if (existingIndex !== -1) {
          // Update the amount in the existing object
          userActions[existingIndex].amount = inputElem.value;
          // Update the action in the existing object if needed
          userActions[existingIndex].action = 'Withdraw'; // Update action if necessary
        } else {
          // Push a new object to the array
          userActions.push({
            userId: id,
            action: 'Withdraw',
            amount: inputElem.value,
            parentId: parent,
            byUser: loggedInUserName
          });

          selectedRows.push(index)
        }
      }
    }
  }




  function CheckAvailableBalance(AvBalIndex, index, id, parent) {

    var inputElem = document.getElementById('pay_' + index);
    var availabaleBalanceIndex = document.getElementById(AvBalIndex).textContent;
    //console.log('avail text is : ', availabaleBalanceIndex)

    var AvBal = Number(availabaleBalanceIndex)

    var amount = parseFloat(document.getElementById("amount_" + index).value);
    if (inputElem.classList.contains("select-W")) {

      //console.log("After full : ", amount)

      if (isNaN(amount)) {
        // Handle non-numeric input
        return;
      }

      if (amount > 0) {
        if (amount > AvBal) {
          toast.error("Insufficient Available balance..!", {
            autoClose: 1500,
            theme: "colored",
          });
          document.getElementById("amount_" + index).value = 0;
        } else {
          //console.log("UserId : ", id, " : withdrawal part amount is : ", amount)

          // Check if an object with the same userId already exists
          const existingIndex = userActions.findIndex(action => action.userId === id);

          if (existingIndex !== -1) {
            // Update the amount in the existing object
            userActions[existingIndex].amount = amount;
            // Update the action in the existing object if needed
            userActions[existingIndex].action = 'Withdraw'; // Update action if necessary
          } else {
            // Push a new object to the array
            userActions.push({
              userId: id,
              action: 'Withdraw',
              amount: amount,
              parentId: parent,
              byUser: loggedInUserName
            });
            selectedRows.push(index)
          }
        }
      }
    }
    if (inputElem.classList.contains("select-D")) {
      //console.log("UserId : ", id, " : Deposit part amout is : " + amount)
      //console.log("Parent balance : ", AvBal)

      if (amount > 0) {

        if (amount <= clickedUserBalance) {

          // Check if an object with the same userId already exists
          const existingIndex = userActions.findIndex(action => action.userId === id);

          if (existingIndex !== -1) {
            // Update the amount in the existing object
            userActions[existingIndex].amount = amount;
            // Update the action in the existing object if needed
            userActions[existingIndex].action = 'Deposit'; // Update action if necessary
          } else {
            // Push a new object to the array
            userActions.push({
              userId: id,
              action: 'Deposit',
              amount: amount,
              parentId: parent,
              byUser: loggedInUserName
            });
            selectedRows.push(index)
          }
        } else {
          toast.error("Insufficient Balance..!", {
            autoClose: 1500,
            theme: "colored",
          })

        }
      }


    }
  }


  const handleSubmit = async () => {
    //console.log("User Action object is : ", userActions);
    //console.log("Selected Rows : ", selectedRows)

    const LoggedInPass = document.getElementById('paymentPassword').value;

    //console.log("LoggedIn PassWord is : ", LoggedInPass);

    if (userActions.length != 0) {

      const requestData = {
        userActions: userActions,
        loggedInUserName: loggedInUserName,
        LoggedInPass: LoggedInPass
      };

      const res = await fetch(`https://api.betcare.live/api/postDWAmount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      try {
        const data = await res.json();
        if (data.isSuccess === true) {
          setRefetchUsers(true)
          //console.log("Request succeeded");
          setTimeout(() => {
            if (!isLoading) {
              toast.success(`${data.message}`, {
                autoClose: 2000,
                theme: "colored",
              });
            }
          }, 2000)

          // Clear userActions array after submission
          userActions = [];


          selectedRows.forEach(row => {

            var element = document.getElementById(`pay_${row}`);

            if (element.classList.contains("select-D") || element.classList.contains("select-W")) {
              if (element.classList.contains("select-W")) {
                element.classList.remove("select-W");
                document.getElementById("amount_" + row).value = 0;
              }
              else if (element.classList.contains("select-D")) {
                element.classList.remove("select-D");
                document.getElementById("amount_" + row).value = 0;
              }
            }
          })

          // setPostDW(prev => !prev)
          setSubmit(prev => !prev)








          // Handle success
        } else {
          //console.log("Request failed");
          // Handle failure

          toast.error(`${data.message}`, {
            autoClose: 1500,
            theme: "colored",
          });
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle parsing error
      }


    } else {
      toast.error(`Please Enter Amount of Selected User!`, {
        autoClose: 2000,
        theme: "colored",
      });
    }

  };

  const openCreditRef = async (id) => {
    try {
      console.log('Id : ', id)
      setCreditUserId(id)
      let totalCreditRef = 0;
      const fetchCreditRef = await fetch(`https://api.betcare.live/api/getCreditRef/${id}`)

      const resCreditRef = await fetchCreditRef.json();

      if (resCreditRef.isSuccess && resCreditRef.data.length != 0) {

        resCreditRef.data.map(item => totalCreditRef += Number(item.CreditReference))


      }
      setCurrentCreditRef(totalCreditRef)

      console.log('credit ref console : ', resCreditRef)



    } catch (error) {
      console.log('Error in openCreditRef.. ')
    }
  }

  const saveRequestCreditReference = async () => {
    try {
      const sendCreditRefData = await fetch(`https://api.betcare.live/api/creditRef/${CreditUserId}/${newCreditRef}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ loginId: userIdLS, loginPassword: loggedInPas })
      });

      try {
        const data = await sendCreditRefData.json();
        console.log('sent creditRef data respionse  : ', data)
        if (data.isSuccess) {
          toast.success(`${data.message}`, {
            autoClose: 1500,
            theme: "colored",
          });
          setCreditUserId('');
          setCurrentCreditRef(0);
          setNewCreditRef(0);
          setLoggedInPass('')
          setUpdatePage((prev) => !prev);
          setRefetchUsers(true);

        } else {
          toast.error(`${data.message}`, {
            autoClose: 1500,
            theme: "colored",
          });
          setCreditUserId('');
          setCurrentCreditRef(0);
          setNewCreditRef(0);
          setLoggedInPass('')
        }


      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle parsing error
      }
    } catch (error) {

    }
  }


  useEffect(() => {

    fetchChildData();
    fetchChildBalance();

  }, [isLoggedIn, currentDashboard, updatePage, submit, userStatus, currentUserPages, allApiUserdata])

  // useEffect(() => {
  //   fetchChildData();
  // }, [userStatus])


  useEffect(() => {
    if (selectedUser.userparentStatus < selectedUser.userstatus) {
      setCurrentStatus(selectedUser.userstatus)

    } else {
      setCurrentStatus(selectedUser.userparentStatus)
    }
  }, [selectedUser]);

  // useEffect(() => {
  //   fetchExposure();
  // }, [ChildData])

  useEffect(() => {
    calcTotal();
  }, [allData])

  useEffect(() => {
    loginBalance();
  }, [currentDashboard, submit])



  return (
    <>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <main role="main" className="pb-3">
        {/* <div className="download-list">
          <div className="container mxw-100">
            <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div>
          </div>
        </div> */}

        <div className="inner-pages banking">
          <div className="container-fluid">
            <div className="page-title"> Banking </div>
            <div id="filterDiv">
              <div className="fiter-box">
                <div className="saerch-filter">
                  <div className="form-group position-relative">
                    <span className="serch-icon">
                      <i className="fa fa-search" aria-hidden="true" />
                    </span>
                    <input
                      type="search"
                      placeholder="Find Member.."
                      className="form-control ng-untouched ng-pristine ng-valid"
                      name="SearchTerm"
                      id="Searchingusername"
                    />
                    <button
                      type="submit"
                      className="seacrh-btn"
                      onclick="getUsersListBystatus()"
                      name="SearchTerm"
                    >
                      Search
                    </button>
                  </div>
                  <div className="form-group status-filter position-relative ml-2">
                    <div className="form-inline ng-untouched ng-pristine ng-valid m-0">
                      <label className="my-1 mr-2">Status</label>
                      <select
                        name="betStatus"
                        className="my-1 mr-sm-2 ng-untouched ng-pristine ng-valid"
                        id="betStatus"
                        fdprocessedid="d6e5mkr"
                        value={userStatus}
                        onChange={(e) => { setUserStatus(e.target.value) }}
                      >
                        <option value={0}>All</option>
                        <option value={1}>Active</option>
                        <option value={3}>Suspend</option>
                        <option value={2}>Locked</option>
                      </select>
                    </div>
                  </div>
                  <div className="ms-2 agent_path">
                    <ul
                      id="agentPath"
                      className="agent_path-L mt-0 mb-0 mr-1 mb-0 ng-star-inserted"
                    >
                      {currentUserPages.map((e, index) => {


                        return (
                          <li style={{ cursor: "pointer" }} id="path_3" key={e.fullName} onClick={() => { updatingCurrentUserPage(index, e.roleId, e.id) }} className="ng-star-inserted active">
                            <a >
                              <span className={`lv_${e.roleId - 2}`}> {UserRoleShortName[e.roleId]} </span>
                              <strong> {e.fullName}</strong>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="right-add-admin d-none" id="showBtn">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#AddAdmin"
                    className="light-btn ng-star-inserted"
                  >
                    <i className="fa fa-user-plus mr-2" aria-hidden="true" />
                    <span id="crtBtn" />
                  </button>
                  <button type="button" className="light-btn refreshbtn ml-2">
                    <i className="fa fa-history" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div id="BankingList">
              <div className="my-report mt-2">
                <div className="time-zone-section banking-balance">
                  <ul className="list-unstyled">
                    <li>
                      <label className="my-1 mr-2">Your Balance</label>
                      <div className="your-balance d-flex align-items-center">
                        <p className="mb-0">{Currency}</p>
                        <h2 className="mb-0" id="Balance">
                          {clickedUserBalance}
                        </h2>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="profile-section mt-4 unmatech-section banking-table-dd">
                <div className="table-responsive">
                  <table
                    id="table_transfer"
                    className="table01 tab-transfer tab-banking table banking-table-padding"
                  >
                    <colgroup>
                      <col span={1} width={190} />
                      <col span={1} width={120} />
                      <col span={1} width={120} />
                      <col span={1} width={120} />
                      <col span={1} width={300} />
                      <col span={1} width={230} />
                      <col span={1} width={200} />
                      <col span={1} width={120} />
                      <col span={1} width={80} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="align-L sort-this">UID</th>
                        <th>Balance</th>
                        <th>Available D/W</th>
                        <th>Exposure</th>
                        <th className="align-C border-l">Deposit / Withdraw</th>
                        <th className="border-l text-end">Credit Reference</th>
                        <th className="text-end">Reference P/L</th>
                        <th className="border-l">Remark</th>
                        <th className="full-amount border-l">
                          <a href="javascript:void(0);" className="btnblk">
                            All Log
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData.map((e, index) => {
                        let status = e.parentStatus;
                        if (e.parentStatus < e.Status) {
                          status = e.Status;
                        }

                        let clickBalance = 0

                        if (e.RoleId !== 8) {
                          clickBalance += e.ResultAmountU != null ? e.ResultAmountU : 0;
                        } else {
                          clickBalance += (e.ResultAmountU != null ? e.ResultAmountU : 0) +
                            (e.UserPL != null ? e.UserPL : 0)
                        }


                        return (
                          <tr
                            id="userdemoo_0"
                            main_userid="userdemoo"
                            main_site={1}
                            className="ng-star-inserted"
                            key={e.Id}
                          >

                            <td
                              id="userFullName_0"
                              className="text-start ng-star-inserted"
                            >
                              <span className="lv_1">{UserRoleShortName[e.RoleId]}</span>{" "}
                              <a
                                href="javascript:void(0);"
                                onclick="getSubUsersList('SA','superadmin',3,3)"
                              >
                                {e.RoleId === 8 ? (
                                  <a> {e.UserName}</a>
                                ) : (
                                  <Link onClick={() => NavigatToChildDasboard(e.RoleId, e.UserName, e.Id, clickBalance)}>
                                    {e.UserName}
                                  </Link>
                                )
                                }
                              </a>
                            </td>
                            <td id={`balance_${index}`}>{e.RoleId !== 8
                              ? (e.ResultAmountU != null ? e.ResultAmountU : 0)
                              : ((e.ResultAmountU != null ? e.ResultAmountU : 0) +
                                (e.UserPL != null ? e.UserPL : 0))}</td>
                            <td id={`availableBalance_${index}`}> {e.RoleId !== 8
                              ? (e.ResultAmountU != null ? e.ResultAmountU : 0)
                              : ((e.ResultAmountU != null ? e.ResultAmountU : 0) +
                                (e.UserPL != null ? e.UserPL : 0) +
                                Number(e.exposure))}</td>
                            <td id={`userExposure_${index}`} className="red">
                              {e.exposure != null ? e.exposure : 0}
                            </td>
                            <td className="DW-amount border-l" id="DW-amount_0">
                              <div className="dw-amount-box d-flex align-items-center">
                                <ul
                                  id={`pay_${index}`}
                                  className="btn_list-DW d-flex align-items-center"
                                >
                                  <li className="d-flex">
                                    <a
                                      id="dBtn_0"
                                      onClick={() => { DepositClick(e.Id, index) }}
                                      className="light-btn diposit-btn"
                                    >
                                      D
                                    </a>
                                  </li>
                                  <li className="d-flex">
                                    <a
                                      id="wBtn_0"
                                      onClick={() => { WithdrawClick(e.Id, index) }}
                                      className="light-btn windrol-btn"
                                    >
                                      W
                                    </a>
                                  </li>
                                </ul>
                                <div
                                  id="deposite"
                                  className="deposite-white-form d-inline-flex"
                                >
                                  <input
                                    id={`amount_${index}`}
                                    placeholder={0}
                                    name="amount"
                                    type="number"
                                    d="dBtn"
                                    className="form-control mx-2 text-end ng-untouched ng-pristine ng-valid"
                                    maxLength={18}
                                    onBlur={() => { CheckAvailableBalance(`availableBalance_${index}`, index, e.Id, e.ParentId) }}
                                  />
                                </div>
                                <a
                                  id={`fullBtn_${index}`}
                                  href="javascript:void(0);"
                                  className="light-btn disable"
                                  onClick={() => { AssignBalance(e.Id, index, e.ParentId) }}
                                >
                                  Full
                                </a>
                              </div>
                            </td>
                            <td className="credit-amount border-l text-end">
                              <div className="d-flex align-items-center justify-content-end">
                                <a
                                  href="javascript:void(0);"
                                  className="mx-1 ng-star-inserted"
                                >
                                  {e.CreditReference != null ? e.CreditReference : 0}
                                </a>
                                <a
                                  id="editCreditReferenceBtn_0"
                                  href="javascript:void(0);"
                                  className="light-btn ng-star-inserted"
                                  type="button"
                                  data-toggle="modal"
                                  data-target='#CreditRefrence'
                                  onClick={(ev) => { ev.preventDefault(); openCreditRef(e.Id) }}
                                >
                                  Edit
                                </a>
                              </div>
                            </td>
                            <td id="userReferencePL_0" className={(Number(e.childDWresultAmountU != null ? e.childDWresultAmountU : 0) - Number(e.CreditReference != null ? e.CreditReference : 0)) >= 0 ? "text-end green" : "text-end red"}>
                              ({Number(e.childDWresultAmountU != null ? e.childDWresultAmountU : 0) - Number(e.CreditReference != null ? e.CreditReference : 0)})
                            </td>
                            <td className="border-l">
                              <input
                                id="remark_0"
                                type="text"
                                placeholder="Remark"
                                formcontrolname="remark"
                                className="form-control text-end ng-untouched ng-pristine ng-valid"
                              />
                            </td>
                            <td className="full-amount border-l">
                              <a id="log_0"
                                data-toggle="modal"
                                data-target="#logmodel"
                                onClick={() => { handleAcc(e.Id, e.UserName, e.ParentName) }}
                                className="light-btn">
                                Log
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                      <tr className="total fw-bold banking-table-footer">
                        <td className="align-L">Total</td>
                        <td id="totalBalance"> {calcBalance}</td>
                        <td id="totalAvailableBalance">{availBalance}</td>
                        <td id="totalExposure">{totalExposure}</td>
                        <td
                          id="totalCreditReference"
                          colSpan={2}
                          className="text-end"
                        >
                          {totalCR}
                        </td>
                        <td className="text-end" id="totalReferencePL">
                          {totalRPL}
                        </td>
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination-controls className="ng-star-inserted">
                <pagination-template>
                  <nav role="navigation" aria-label="Pagination">
                    <ul className="ngx-pagination ng-star-inserted">
                      <li className="pagination-previous disabled ng-star-inserted">
                        <span aria-disabled="true" className="ng-star-inserted">
                          Previous <span className="show-for-sr">page</span>
                        </span>
                      </li>
                      <li className="small-screen"> 1 / 1 </li>
                      <li className="current ng-star-inserted">
                        <span aria-live="polite" className="ng-star-inserted">
                          <span className="show-for-sr">You're on page </span>
                          <span>1</span>
                        </span>
                      </li>
                      <li className="pagination-next ng-star-inserted disabled">
                        <span aria-disabled="true" className="ng-star-inserted">
                          Next <span className="show-for-sr">page</span>
                        </span>
                      </li>
                    </ul>
                  </nav>
                </pagination-template>
              </pagination-controls>
              <div id="clearAllBtn" className="clear-all-password">
                <button type="button" className="light-btn">
                  Clear All
                </button>
                <div className="password-submit">
                  <input
                    id="paymentPassword"
                    type="password"
                    autoComplete="off"
                    placeholder="Password"
                    formcontrolname="password"
                    className="text-center form-control ng-untouched ng-pristine ng-valid"
                  />
                  <div className="btnblk submit-payment submit-payment-disbled">
                    <button
                      href="javascript:void(0);"
                      title="Submit"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <span id="submitCount" className="Pyament-value">
                      0
                    </span>
                    <button type="button" title="Pyament" onclick="SubmitDW()">
                      Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="CreditRefrence"
          tabIndex={-1}
          aria-labelledby="CreditRefrenceLabel"
          aria-hidden="true"
          className="modal fade changepassword-model"
        >
          <div className="modal-dialog">
            <form className="ng-untouched ng-pristine ng-invalid">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 id="CreditRefrenceLabel" className="modal-title">
                    Credit Reference Edit
                  </h6>
                  <button type="button" className="btn-close close_pop" data-dismiss="modal">
                    x
                  </button>
                </div>
                <div className="modal-body credit-refrence">
                  <hr className="my-3" />
                  <dl className="form_list">
                    <dt>Current </dt>
                    <dd>
                      <div className="log-div d-flex justify-content-between align-items-center">
                        <span>{currentCreditRef}</span>
                        <b />
                        <button type="button" className="light-btn">
                          Log
                        </button>
                      </div>
                    </dd>
                    <dt>New </dt>
                    <dd>
                      <input type="hidden" name="UserId" id="CRid" />
                      <input
                        type="number"
                        id="new"
                        placeholder="Enter"
                        formcontrolname="reference"
                        className="ng-untouched ng-pristine ng-valid"
                        value={newCreditRef}
                        onChange={(e) => { setNewCreditRef(e.target.value) }}
                      />
                    </dd>
                    <dt>Password </dt>
                    <dd className="mb-2">
                      <input
                        type="password"
                        id="Password"
                        placeholder="Enter"
                        formcontrolname="password"
                        className="ng-untouched ng-pristine ng-invalid"
                        value={loggedInPas}
                        onChange={(e) => { setLoggedInPass(e.target.value) }}
                      />
                    </dd>
                    <hr className="mb-1 mt-1" />
                    <dt />
                    <dd className="w-100 text-center">
                      <a
                        id="changePasswordBtn"
                        title="Submit"
                        onClick={saveRequestCreditReference}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn2 px-5"
                      >
                        Submit
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          id="logmodel"
          className="modal fade changepassword-model"
          style={{ display: "none" }}
          data-backdrop="static"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content w-100">
              <div className="modal-header">
                <h6 id="logmodelLabel" className="modal-title">
                  Account Statement
                </h6>
                <button type="button" className="btn-close close_pop" data-dismiss="modal">
                  <i className="fa fa-times" aria-hidden="true" />
                </button>
              </div>
              <div className="modal-body change-status" id="statementList">
                <div className="profile-section mt-0 unmatech-section">
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th />
                          <th>Date/Time</th>
                          <th style={{ textAlign: "right" }}>Balance</th>
                          <th style={{ textAlign: "right" }}>Remark</th>
                          <th style={{ textAlign: "right" }}>From/To</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accStatement.length > 0 && accStatement.map((item, index) => (

                          <tr className="ng-star-inserted" key={item.id}>
                            <td>{index + 1}</td>
                            <td>{new Date(item.Date).toLocaleString()} </td>
                            <td>{item.Deposit != 0 ? item.Deposit : item.Withdraw}</td>
                            <td>{item.Deposit != 0 ? "Deposit" : "Withdraw"}</td>
                            <td>{item.Deposit != 0 ? item.ParentUserName : item.userName} &gt; {item.Deposit != 0 ? item.userName : item.ParentUserName} </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </main>
    </>
  )
}

export default Banking