import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment';
import axios from 'axios';

function SelfUserTransactionHistory() {

  const navigate = useNavigate();
  const context = useContext(currentUser);
  const { isLoggedIn } = context;
  const userIdLS = localStorage.getItem(`UserId`);

  const [fetchedPaymentDetail, setFetchedPaymentDetail] = useState([]);
  const [page, setPage] = useState(1);
  const [updatePage, setUpdatePage] = useState(false);
  const totalPages = 20;

  const { data: allData, isLoading, isError } = useQuery({
    queryKey: [`transactionHistory`, page, updatePage], queryFn: async () => {
      let res = await axios.get(`https://api.betcare.live/api/admin/selfUser/getAllSelfUserTransactionData?page=${page}&totalpage=${totalPages}`);
      let resData = res.data;
      console.log('transaction history resData : ', allData);
      return resData;
    }
  });

  const fetchPaymentDetail = async (id, userId, type) => {
    try {
      const res = await fetch(`https://api.betcare.live/admin/api/selfUser/fetchDWPayDetail/${id}/${userId}/${type}`);
      const resData = await res.json();
      if (res.ok) {
        console.log('fetched payment : ', resData);
        if (resData?.data?.length > 0) {
          setFetchedPaymentDetail(resData?.data);
        }
      }
    } catch (error) {
      console.log('Error while fetching detail : ', error);
    }
  }


  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    if (isLoggedIn === "false") {
      navigate("/login");
      return;
    }

    setUpdatePage((prev) => !prev)

  }, [isLoggedIn, page])

  return (
    <>
      <style>{`
div::-webkit-scrollbar {
display: none;
}
`}</style>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <main role="main" className="pb-3">
        <div className="inner-pages">
          <div className="ng-untouched ng-pristine ng-valid">
            <div className="container-fluid">
              <div className="page-title" style={{ fontSize: 16 }}>
                <h6 className="my-2">Transaction History </h6>
              </div>
              <div className="download-list">
                <div className="">
                  <div className="profile-section mt-3">
                    <div className="table-responsive  text-end">
                      <table className="table table01">
                        <thead>
                          <tr>
                            <th className="text-left">Sr No</th>
                            <th>Date</th>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Amount</th>
                            <th>Transaction Type</th>
                            <th>Payment Detail</th>
                            <th>Status</th>
                            <th>ApprovedBy</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allData?.rows?.length > 0 && allData?.rows?.map((item, index) => (

                            <tr className="ng-star-inserted" key={item.id}>
                              <td className=" ng-star-inserted">{index + 1}</td>
                              <td>
                                {moment(item.StatusChangeDate).format('DD MMM h:mm A')}
                              </td>
                              <td>{item.UserID}</td>
                              <td>{item.UserName}</td>
                              <td>{item.Amount}</td>
                              <td style={{ textTransform: 'uppercase' }}>{item.type}</td>
                              <td>
                                <button className='px-2 py-1 border rounded'
                                  style={{
                                    backgroundColor: '#71b8e4',
                                    fontWeight: 'bold'
                                  }}
                                  data-toggle="modal" data-target="#checkPaymentDetailModal"
                                  onClick={() => { fetchPaymentDetail(item.SrNo, item.UserID, item.type == 'deposit' ? 'deposit' : 'withdraw') }}
                                >
                                  Detail
                                </button>
                              </td>
                              <td>
                                <span className={item.Status == 1 ? "activebtn" : item.Status == 2 ? 'pendingbtn' : item.Status == 3 ? 'suspendbtn' : 'activebtn'} style={{ marginLeft: "10px" }}>{item.Status == 1 ? 'Approved' : item.Status == 2 ? 'Pending' : item.Status == 3 ? 'Rejected' : 'Status'}</span>
                              </td>
                              <td>{item.approverName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Check payment detail modal */}
              <div
                id="checkPaymentDetailModal"
                className="modal fade changepassword-model"
                style={{ display: "none" }}
                data-backdrop="static"
                aria-modal="true"
                role="dialog"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content w-100">
                    <div className="modal-header">
                      <h6 id="logmodelLabel" className="modal-title">
                        Payment Detail
                      </h6>
                      <button type="button"
                        className="close p-1"
                        style={{
                          borderRadius: '5px',
                          border: '1px solid',
                          fontWeight: 'bolder'
                        }}
                        onClick={() => {
                          setFetchedPaymentDetail([])
                        }}
                        data-dismiss="modal">
                        X
                      </button>
                    </div>
                    <div className="modal-body change-status" id="statementList">
                      <div className="profile-section mt-0 unmatech-section">

                        {fetchedPaymentDetail?.length > 0 && fetchedPaymentDetail?.map((item) => (

                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <div className='p-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '1rem', width: '100%' }}>
                              <div style={{ fontSize: '1.4em' }}>
                                <p><b>Approved Date : </b>{moment(item.StatusChangeDate).format('DD MMM h:mm A')}</p>
                                <p><b>Deposit Amount : </b>{item.Amount}</p>
                                <p><b>Display Name : </b>{item.DisplayName}</p>
                                <p><b>Number : </b>{item.AccountNumber}</p>
                                <p><b>Payment Type : </b>{item.Bank}</p>
                                <p><b>Account Type : </b>{item.PaymentSubType}</p>
                                {/* <p><b>IFCS Code : </b>{item.IFSC}</p>
                                <p><b>UPI ID : </b>{item.UPIID}</p>
                                <p><b>UTR Number : </b>{item.UTRNo}</p> */}
                                <p><b>Transaction Id : </b>{item.UTRNo}</p>
                              </div>
                            </div>

                            {item.fileName &&
                              <div style={{ width: '100%', height: '', border: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              >
                                <img src={`/payment/${item.fileName}`} alt="" srcset="" style={{
                                  width: '20em',
                                  maxWidth: '100%',
                                  height: '100%',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',

                                }} />

                              </div>
                            }


                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default SelfUserTransactionHistory