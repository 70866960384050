import React, { useState, useEffect, useContext } from 'react'
import { toast } from "react-toastify";
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';

const SportSetting = () => {

  const navigate = useNavigate();

  const [sportsNames, setSportsNames] = useState([]);
  const [isActive, setIsActive] = useState(true);

  const context = useContext(currentUser);
  const { userStatusId, setIsLoggedIn } = context

  //  function to load the data!!
  const fetchSportsName = async () => {
    let response = await fetch(`https://api.betcare.live/api/getSports`);
    let fetchdata = await response.json();
    setSportsNames(fetchdata);
  }



  // function to update the status of sports
  const updateSportStatus = async (event, id) => {


    let status;

    console.log(event.target.checked);
    event.target.checked ? status = 1 : status = 0;

    console.log(status);




    setIsActive((prev => !prev));
    toast.success(`Your work has been saved !!`, {
      autoClose: 1500,
      theme: "colored",
    })

    setTimeout(() => {
      window.location.reload();

    }, 2000)

    try {

      let res = await fetch(`https://api.betcare.live/api/updateSportStatus/${id}/${status}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    }
    catch (error) {
      console.log("error in connecting the api");
    }
    // })
  };



  const logoutSetDataFunc = async () => {
    let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
  }

  useEffect(() => {
    fetchSportsName();
  }, [isActive])


  // useEffect(() => {

  //   const fetchUserStatusCheck = async () => {
  //     let res = await fetch(`https://api.betcare.live/api/isUserIsLoggedIn/userstatus/${userStatusId}`);
  //     let resdata = await res.json();
  //     let userIsLoggedIn = resdata.message;
  //     console.log(userIsLoggedIn + " hey boyy");

  //     if (userIsLoggedIn == "false") {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('RoleId');
  //       localStorage.removeItem('UserName');
  //       localStorage.removeItem("currentDashboard");
  //       localStorage.removeItem("currentUserPages");
  //       // localStorage.removeItem("CurrentCurrency");
  //       setIsLoggedIn(false);
  //       logoutSetDataFunc();
  //       navigate("/login");
  //     }
  //   };

  //   fetchUserStatusCheck();

  // }, [])



  return (
    <>
      <main role="main" className="pb-3">
        {/* <div className="download-list">
          <div className="container mxw-100">
            <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div>
          </div>
        </div> */}
        <div className="inner-pages banking">
          <div className="container-fluid">
            <div className="page-title" style={{ fontSize: 16 }}>
              <h6 className="my-2">Sport Details</h6>
            </div>
            <div className="profile-section mt-3 unmatech-section">
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Sport Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sportsNames.map((e, index) => {
                      return (

                        <tr className="ng-star-inserted" key={e.SportId}>
                          <td>{index + 1}</td>
                          <td>{e.SportName}</td>
                          <td>
                            <div className="form-check form-switch text-center">
                              <input
                                type="checkbox"
                                checked={e.Status}
                                onClick={(event) => updateSportStatus(event, e.SportId)}
                                role="switch"
                                id="flexSwitchCheckChecked_1"
                                className="form-check-input flexSwitchCheckChecked"

                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list"></div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
    </>


  )
}

export default SportSetting
