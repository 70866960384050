import React, { useState, useEffect, useContext } from 'react'
// import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import currentUser from '../../context/currentUser/currentUser';
import { useNavigate } from 'react-router-dom';


const GlobalSetting = () => {

  const navigate = useNavigate();

  const [MatchStack, setMatchStack] = useState(0);
  const [SessionStack, setSessionStack] = useState(0);
  const [dataUpdated, setDataUpdated] = useState(true);


  const context = useContext(currentUser);
  const { isLoggedIn, setIsLoggedIn, userStatusId, roleId, fullName, pageLoad, setPageLoad, siteNotice, setSiteNotice } = context;




  // FOR CURRENCY UPDATES
  const [currency, setCurrency] = useState("");
  const [currencyData, setCurrencydata] = useState([]);

  const [dataLoad, setDataLoad] = useState(false);

  // odds values
  const [MinOdds, setMinOdds] = useState(0);
  const [MaxOdds, setMaxOdds] = useState(0);
  const [DelayOdds, setDelayOdds] = useState(0);

  // Bookmaker values
  const [MinBookmaker, setMinBookmaker] = useState(0);
  const [MaxBookmaker, setMaxBookmaker] = useState(0);
  const [DelayBookmaker, setDelayBookmaker] = useState(0);

  // Session values
  const [MinSession, setMinSession] = useState(0);
  const [MaxSession, setMaxSession] = useState(0);
  const [DelaySession, setDelaySession] = useState(0);



  // storing the img of site logo
  const [SiteLogoImg, setSiteLogoImg] = useState();

  // storing the img of site icon
  const [SiteIconImg, setSiteIconImg] = useState();

  // setting data from master data

  // all master data
  //const [masterData, setMasterData] = useState([]);

  // whatsapp Link data
  const [WhatsAppLink1, setWhatsAppLink1] = useState("");
  const [WhatsAppLink2, setWhatsAppLink2] = useState("");

  // telegram Link data
  const [TelegramLink1, setTelegramLink1] = useState("");
  const [TelegramLink2, setTelegramLink2] = useState("");


  // other setting data
  const [startTime, setStartTime] = useState(0);
  const [noticeMessage, setNoticeMessage] = useState("");



  const fetchMasterData = async () => {
    let res = await fetch("https://api.betcare.live/api/getMasterData");
    let resData = await res.json();
    console.log('master global data : ', resData[0].SiteMessage);
    setSiteNotice(resData[0].SiteMessage)
    // setting data
    setWhatsAppLink1(resData[0].Whatsapp1Link);
    setWhatsAppLink2(resData[0].Whatsapp2Link);
    setTelegramLink1(resData[0].Telegram1Link);
    setTelegramLink2(resData[0].Telegram2Link);
    setStartTime(resData[0].BetStartTime);
    setNoticeMessage(resData[0].SiteMessage);
  }




  // fetching the api and getting the data
  const fetchData = async () => {
    let res = await fetch("https://api.betcare.live/api/getSingleDataSports");
    let resData = await res.json();

    let matchStack = resData[0].MaxStackLimit;
    // console.log(matchStack);
    setMatchStack(matchStack);  // matchStack data

    let sessionStack = resData[0].SessionMaxStackLimit;
    //  console.log(sessionStack);
    setSessionStack(sessionStack);   // sessionStack data


    // setting data of limit and delay

    // Odds data setting
    let minOdd = resData[0].MinOddLimit;
    setMinOdds(minOdd);

    let maxOdd = resData[0].MaxOddLimit;
    setMaxOdds(maxOdd);

    let delayOdd = resData[0].BetDelayTime;
    setDelayOdds(delayOdd);


    // BookMaker data setting
    let minBookMaker = resData[0].MinBookmakerLimit;
    setMinBookmaker(minBookMaker);

    let maxBookMaker = resData[0].MaxBookmakerLimit;
    setMaxBookmaker(maxBookMaker);

    let delayBookMaker = resData[0].BookmakerDelayTime;
    setDelayBookmaker(delayBookMaker);


    // Session data setting
    let minSession = resData[0].MinFancyLimit;
    setMinSession(minSession);

    let maxSession = resData[0].MaxFancyLimit;
    setMaxSession(maxSession);

    let delaySession = resData[0].FancyDelayTime;
    setDelaySession(delaySession);

  }



  const updateCurentCurrency = async (e) => {

    e.preventDefault();

    try {
      let res = await fetch(`https://api.betcare.live/api/updateStatusOfCurrency/currency/${currency}`, { method: 'PUT' });
      let resData = await res.json();

      if (res.status !== 200) {
        toast.error(`${resData}`, {
          autoClose: 1500,
          theme: "colored",
        });
        return;
      }
      //  SetCurrentCurrency(currency);
      toast.success(`Data has been saved!`, {
        autoClose: 1500,
        theme: "colored",
      });

      setDataLoad(prev => !prev);
      setPageLoad(prev => !prev);


    } catch (error) {
      console.log(error);
    }



  }




  const handleOnChange = (e) => {
    let inputValue = e.target.value;
    let dataName = e.target.name;


    if (dataName == "matchStack") {
      setMatchStack(inputValue);
      // return;
    }

    if (dataName == "sessionStack") {
      setSessionStack(inputValue);
      // return;
    }


    // UPDATING DATA OF ODDS
    if (dataName == "minOdd") {
      setMinOdds(inputValue)
    }


    if (dataName == "maxOdd") {
      setMaxOdds(inputValue)
    }


    if (dataName == "delayOdd") {
      setDelayOdds(inputValue)
    }


    // UPDATING DATA OF BOOKMAKER
    if (dataName == "minBookMaker") {
      setMinBookmaker(inputValue)
    }


    if (dataName == "maxBookMaker") {
      setMaxBookmaker(inputValue)
    }


    if (dataName == "delayBookMaker") {
      setDelayBookmaker(inputValue)
    }


    // UPDATING DATA OF SESSION
    if (dataName == "minSession") {
      setMinSession(inputValue)
    }


    if (dataName == "maxSession") {
      setMaxSession(inputValue)
    }


    if (dataName == "delaySession") {
      setDelaySession(inputValue)
    }


    // whatsapp links
    if (dataName == "WhatsAppLink1") {
      setWhatsAppLink1(inputValue)
    }

    if (dataName == "WhatsAppLink2") {
      setWhatsAppLink2(inputValue)
    }


    // telegram links
    if (dataName == "TelegramLink1") {
      setTelegramLink1(inputValue)
    }

    if (dataName == "TelegramLink2") {
      setTelegramLink2(inputValue)
    }


    // other settings
    if (dataName == "startTime") {
      setStartTime(inputValue)
    }

    if (dataName == "noticeMessage") {
      setNoticeMessage(inputValue)
    }
  }




  // for testing purpose - // console.log(`value of matchStack = ${MatchStack} and value of sessionStack = ${SessionStack}`);


  // checking if input value is not negative
  const disableKey = (e) => {
    let key1 = "-";
    let key2 = "+";

    if (e.key.toLowerCase() == key1 || e.key.toLowerCase() == key2) {
      e.preventDefault();
      // Swal.fire({
      //   position: "top-end",
      //   icon: "warning",
      //   title: "Value can't be negative !! use positive number",
      //   showConfirmButton: false,
      //   timer: 1500
      // });
      // notify();

      toast.error(`Value can't be negative !! Use Positive number`, {
        autoClose: 1500,
        theme: "colored",
      });
    }
  }




  // handling changes in logo file
  const handleLogoFileChange = (e) => {
    const selectedFile = e.target.files[0];

    console.log(selectedFile.size);

    if (selectedFile.size > 300 * 110) {
      toast.warning(`File size is large, select img smaller than 185*45`, {
        autoClose: 1500,
        theme: "colored",
      });
      return;
    }
    setSiteLogoImg(selectedFile);

  }

  // handling changes in fav icon
  const handleFavIconFileChange = (e) => {

    const selectedFile = e.target.files[0];

    if (selectedFile.size > 100 * 100) {
      toast.warning(`File size is large, select img smaller than 32*32 `, {
        autoClose: 1500,
        theme: "colored",
      });
      return;
    }
    setSiteIconImg(selectedFile);

  }




  // function to upload the logo to backend
  const SavingSiteLogo = async () => {

    if (SiteLogoImg) {
      const formData = new FormData();
      formData.append('image', SiteLogoImg);

      try {
        const response = await fetch('https://api.betcare.live/api/uploadSiteLogo', {
          method: 'POST',
          body: formData,
        });

        console.log(response);

        if (response.ok) {
          const imgElement = document.getElementById('SiteLogoUrl');
          const timestamp = new Date().getTime();

          // Update the image src to refresh it
          imgElement.src = `https://ag.betcare.live/uploads/panel-logo.png?${timestamp}`;

          // Update the favicon link to refresh it
          const logoElement = document.getElementById("headerSiteLogo");
          logoElement.src = `https://ag.betcare.live/uploads/panel-logo.png?${timestamp}`;

          toast.success(`Logo Changed..`, {
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          throw new Error('Failed to upload image');
        }

      } catch (error) {
        console.error('Error during file upload:', error);
      }
    } else {
      toast.warning(`No File is selected !!`, {
        autoClose: 1500,
        theme: "colored",
      });
      console.warn('No file selected.');
    }
  }




  // function to upload the favIcon to backend
  const SavingSiteFavIcon = async () => {
    if (SiteIconImg) {
      const formData = new FormData();
      formData.append('image', SiteIconImg);

      try {
        const response = await fetch('https://api.betcare.live/api/uploadSiteFavIcon', {
          method: 'POST',
          body: formData,
        });

        console.log(response);

        if (response.ok) {
          const imgElement = document.getElementById('FavIconUrl');
          const timestamp = new Date().getTime();

          // Update the image src to refresh it
          imgElement.src = `https://ag.betcare.live/uploads/favicon.png?${timestamp}`;

          // Update the favicon link to refresh it
          const faviconElement = document.querySelector("link[rel='icon']");
          faviconElement.href = `https://ag.betcare.live/uploads/favicon.png?${timestamp}`;

          toast.success(`FavIcon Changed..`, {
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          throw new Error('Failed to upload image');
        }
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    } else {
      toast.warning(`No File is selected!`, {
        autoClose: 1500,
        theme: "colored",
      });
      console.warn('No file selected.');
    }
  }





  const updateStackData = async () => {
    try {

      let res = await fetch(`https://api.betcare.live/api/updateStacks/${MatchStack}/${SessionStack}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Additional headers if needed
        }
      });
    }
    catch (error) {
      console.log("error in connecting the api");
    }
    toast.success(`Your work has been saved !!`, {
      autoClose: 1500,
      theme: "colored",
    });
    setDataUpdated((prev) => !prev);
  }


  // updating limit and delay settings
  const updateLimitDelaySetting = async () => {

    try {

      let res = await fetch(`https://api.betcare.live/api/updateLimitAndDelay/${MinOdds}/${MaxOdds}/${DelayOdds}/${MinBookmaker}/${MaxBookmaker}/${DelayBookmaker}/${MinSession}/${MaxSession}/${DelaySession}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Additional headers if needed
        }
      });
    }
    catch (error) {
      console.log("error in connecting the api");
    }

    toast.success(`Your work has been saved !!`, {
      autoClose: 1500,
      theme: "colored",
    });
    setDataUpdated((prev) => !prev);
  }


  // updating contact details 
  const UpdatingContactDetail = async () => {
    try {

      let res = await fetch(`https://api.betcare.live/api/updateContactDetail/${WhatsAppLink1}/${WhatsAppLink2}/${TelegramLink1}/${TelegramLink2}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    }
    catch (error) {
      console.log("error in connecting the api");
    }
    toast.success(`Your work has been saved !!`, {
      autoClose: 1500,
      theme: "colored",
    });
    setDataUpdated((prev) => !prev);
    // })
  }

  // updating contact details 
  const UpdatingOtherSetting = async () => {

    try {

      let res = await fetch(`https://api.betcare.live/api/updateOtherSetting/${startTime}/${noticeMessage}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Additional headers if needed
        }
      });
    }
    catch (error) {
      console.log("error in connecting the api");
    }

    toast.success(`Your work has been saved !!`, {
      autoClose: 1500,
      theme: "colored",
    });
    setDataUpdated((prev) => !prev);
    // })
  }


  const logoutSetDataFunc = async () => {
    let res = await fetch(`https://api.betcare.live/api/setLoggedOutTime/userstatus/${userStatusId}`);
  }


  useEffect(() => {
    fetchData();
    fetchMasterData();

    (async () => {
      let res = await fetch(`https://api.betcare.live/api/getAllCurrencyType/currency`);
      let resResponse = await res.json();
      setCurrencydata(resResponse);
      console.log(resResponse);
    })()


      ; (async () => {
        let res = await fetch(`https://api.betcare.live/api/getActiveCurrency/currency`);
        let resData = await res.json();
        setCurrency(resData[0].CurrencyName);
      })()

  }, [dataUpdated, dataLoad])


  // useEffect(() => {

  //   const fetchUserStatusCheck = async () => {
  //     let res = await fetch(`https://api.betcare.live/api/isUserIsLoggedIn/userstatus/${userStatusId}`);
  //     let resdata = await res.json();
  //     let userIsLoggedIn = resdata.message;
  //     console.log(userIsLoggedIn + " hey boyy");

  //     if (userIsLoggedIn == "false") {
  //       localStorage.removeItem('isLoggedIn');
  //       localStorage.removeItem('RoleId');
  //       localStorage.removeItem('UserName');
  //       localStorage.removeItem("currentDashboard");
  //       localStorage.removeItem("currentUserPages");
  //       // localStorage.removeItem("CurrentCurrency");
  //       setIsLoggedIn(false);
  //       logoutSetDataFunc();
  //       navigate("/login");
  //     }
  //   };

  //   fetchUserStatusCheck();

  // }, [])



  return (
    <>
      <main role="main" className="pb-3">
        {/* <div className="download-list">
          <div className="container mxw-100">
            <div className="news-box align-items-center">
              <div className="news-title">
                <i className="fa fa-microphone ml-1 me-1" aria-hidden="true" /> News
              </div>
              <div className="news-content">
                <marquee> Welcome to Big Bash League 2023-24 </marquee>
              </div>
            </div>
          </div>
        </div> */}
        <div className="inner-pages banking">
          <div className="container-fluid">
            <div className="page-title" style={{ fontSize: 16 }}>
              <h6 className="my-2">Global Setting</h6>
            </div>
            <input type="hidden" id="SiteLogoDiv" defaultValue="panel-logo.png" />
            <input type="hidden" id="FavIconDiv" defaultValue="favicon.ico" />
            <input type="hidden" id="ApkFileDiv" />
            <input
              type="hidden"
              id="WhatsappLogoDiv"
              defaultValue="icons8-whatsapp-48.png"
            />
            <input
              type="hidden"
              id="InstagramLogoDiv"
              defaultValue="icons8-instagram-48.png"
            />
            <input
              type="hidden"
              id="TelegramLogoDiv"
              defaultValue="icons8-online-support-60.png"
            />
            <input
              type="hidden"
              id="FacebookLogoDiv"
              defaultValue="icons8-sent-48.png"
            />
            <input
              type="hidden"
              id="TwitterLogoDiv"
              defaultValue="icons8-skype-48.png"
            />
            <div className="ng-untouched ng-pristine ng-valid">
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Currency </div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Currency</td>
                        <td>
                          <select name="currency" value={currency} onChange={(e) => { setCurrency(e.target.value) }} id="">
                            {
                              currencyData.map((e) => {
                                return (
                                  <option value={e.CurrencyName} key={e.Id}>{e.CurrencyName}</option>
                                )
                              })
                            }
                          </select>
                          {/* <input
                          type="number"
                          formcontrolname="commission"
                          id="commission"
                          name="commission"
                          placeholder="Commmision"
                          className="table-input ng-untouched ng-pristine ng-valid"
                          defaultValue={1}
                        /> */}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <button
                            style={{ fontSize: 14 }}
                            type="button"
                            onClick={updateCurentCurrency}
                            className="btn btnblk btn-sm w-auto"
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Stack Button </div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Match Stack</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            onKeyDown={disableKey}
                            value={MatchStack}
                            onChange={handleOnChange}
                            formcontrolname="match_stack"
                            id="MaxStackLimit"
                            name="matchStack"
                            placeholder="Odds"
                            className="table-input ng-untouched ng-pristine ng-valid"
                          />
                        </td>
                        <td rowSpan={2} style={{ verticalAlign: "middle" }}>
                          <button
                            style={{ fontSize: 14 }}
                            type="button"
                            className="btn btnblk btn-sm w-auto"
                            onClick={updateStackData}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Session Stack</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            formcontrolname="fancy_stack"
                            id="SessionMaxStackLimit"
                            name="sessionStack"
                            placeholder="Session"
                            className="table-input ng-untouched ng-pristine ng-valid"
                            value={SessionStack}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Limit &amp; Delay Setting </div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="20%" />
                        <th width="20%">Min</th>
                        <th width="20%">Max</th>
                        <th width="20%">Delay</th>
                        <th width="20%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Odds</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={MinOdds}
                            onChange={handleOnChange}
                            onKeyDown={disableKey}
                            formcontrolname="match_min_bet"
                            id="odds_min"
                            name="minOdd"
                            placeholder="Min"
                            className="table-input ng-untouched ng-pristine ng-valid"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={MaxOdds}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="match_max_bet"
                            id="odds_max"
                            name="maxOdd"
                            placeholder="Max"
                            className="table-input ng-untouched ng-pristine ng-valid"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={DelayOdds}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="match_delay"
                            id="odds_delay"
                            name="delayOdd"
                            placeholder="In Seconds"
                            className="table-input ng-untouched ng-pristine ng-valid"
                            defaultValue={3}
                          />
                        </td>
                        <td rowSpan={3} style={{ verticalAlign: "middle" }}>
                          <button
                            style={{ fontSize: 14 }}
                            type="button"
                            className="btn btnblk btn-sm w-auto"
                            onClick={updateLimitDelaySetting}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Bookmaker</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={MinBookmaker}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="bookmaker_min_bet"
                            id="bookmaker_min"
                            name="minBookMaker"
                            placeholder="Min"
                            className="table-input ng-untouched ng-pristine ng-valid"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={MaxBookmaker}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="bookmaker_max_bet"
                            id="bookmaker_max"
                            name="maxBookMaker"
                            placeholder="Max"
                            className="table-input ng-untouched ng-pristine ng-valid"
                            defaultValue={5000}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={DelayBookmaker}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="bookmaker_delay"
                            id="bookmaker_delay"
                            name="delayBookMaker"
                            placeholder="In Seconds"
                            className="table-input ng-untouched ng-pristine ng-valid"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Session</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={MinSession}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="session_min_bet"
                            id="fancy_min"
                            name="minSession"
                            placeholder="Min"
                            className="table-input ng-untouched ng-pristine ng-valid"
                            defaultValue={2000}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={MaxSession}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="session_max_bet"
                            id="fancy_max"
                            name="maxSession"
                            placeholder="Max"
                            className="table-input ng-untouched ng-pristine ng-valid"
                            defaultValue={5000}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={DelaySession}
                            onKeyDown={disableKey}
                            onChange={handleOnChange}
                            formcontrolname="session_delay"
                            id="fancy_delay"
                            name="delaySession"
                            placeholder="In Seconds"
                            className="table-input ng-untouched ng-pristine ng-valid"
                            defaultValue={3}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Website Logo </div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <label htmlFor="SiteLogo">Site logo</label>
                          <input
                            type="file"
                            id="SiteLogo"
                            accept='.png'
                            onChange={handleLogoFileChange}
                            className="form-control"
                          />
                        </td>
                        <td width="45%" className="text-center">
                          <div className="text-start d-inline-block">
                            <label htmlFor="">Ex:(185*45, PNG) </label>
                            <div className="global-ex-logo">
                              <img
                                src="https://ag.betcare.live/uploads/panel-logo.png"
                                id="SiteLogoUrl"
                                width={185}
                                height={60}
                              />
                            </div>
                          </div>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <button
                            type="submit"
                            onClick={SavingSiteLogo}
                            className="btn btnblk btn-sm w-auto"
                          >
                            Upload
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Fav Icon </div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <label htmlFor="FavIcon">Fav Icon</label>
                          <input
                            type="file"
                            id="FavIcon"
                            className="form-control"
                            accept='.ico'
                            onChange={handleFavIconFileChange}
                          />
                        </td>
                        <td width="45%" className="text-center">
                          <div className="text-start d-inline-block">
                            <label htmlFor="">Ex:(32*32, ICO) </label>
                            <div>
                              <img
                                width={50}
                                height={50}
                                src="https://ag.betcare.live/uploads/favicon.png"
                                id="FavIconUrl"
                              />
                            </div>
                          </div>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <button
                            type="submit"
                            onClick={SavingSiteFavIcon}
                            className="btn btnblk btn-sm w-auto"
                          >
                            Upload
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Contact Details </div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="25%" />
                        <th width="25%">Link 1</th>
                        <th width="25%">Link 2</th>
                        <th width="25%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label>Whatsapp</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            formcontrolname="wa_link"
                            id="WhatsappLink1"
                            name="WhatsAppLink1"
                            value={WhatsAppLink1}
                            maxLength={200}
                            onChange={handleOnChange}
                            className="form-control ng-untouched ng-pristine ng-valid"
                            defaultValue="www.Whatsapp.com"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formcontrolname="wa_link2"
                            id="WhatsappLink2"
                            name="WhatsAppLink2"
                            value={WhatsAppLink2}
                            maxLength={200}
                            onChange={handleOnChange}
                            className="form-control ng-untouched ng-pristine ng-valid"
                            defaultValue="www.Whatsapp.com"
                          />
                        </td>
                        <td rowSpan={2} style={{ verticalAlign: "middle" }}>
                          <button
                            type="submit"
                            onClick={UpdatingContactDetail}
                            className="btn btnblk btn-sm w-auto"
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Telegram</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            formcontrolname="te_link"
                            id="TelegramLink1"
                            name="TelegramLink1"
                            value={TelegramLink1}
                            onChange={handleOnChange}
                            className="form-control ng-untouched ng-pristine ng-valid"
                            defaultValue="www.Telegram.com"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formcontrolname="te_link2"
                            id="TelegramLink2"
                            name="TelegramLink2"
                            value={TelegramLink2}
                            onChange={handleOnChange}
                            className="form-control ng-untouched ng-pristine ng-valid"
                            defaultValue="www.Telegram.com"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="global-setting-box mb-3">
                <div className="setting-titile"> Other Setting</div>
                <div className="setting-table profile-section">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <label>Bet Start Time (In Minutes)</label>
                        </td>
                        <td>
                          <input
                            type="number"
                            name="startTime"
                            value={startTime}
                            min={0}
                            onChange={handleOnChange}
                            onKeyDown={disableKey}
                            className="form-control ng-untouched ng-pristine ng-valid"
                            defaultValue=""
                          />
                        </td>
                        <td rowSpan={2} style={{ verticalAlign: "middle" }}>
                          <button
                            type="submit"
                            className="btn btnblk btn-sm w-auto"
                            onClick={UpdatingOtherSetting}
                          >
                            Save
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Slider Notice Message</label>
                        </td>
                        <td>
                          <textarea
                            className="form-control"
                            id="SiteMessage"
                            name="noticeMessage"
                            value={noticeMessage}
                            onChange={handleOnChange}
                            placeholder="Type your Notice Massage..."
                            defaultValue={"welcome to my world S9bet"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="donloadicon-list"></div>
      <div className="loader" style={{ display: "none" }}>
        <svg
          version="1.1"
          width={200}
          height={100}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx={50} cy={50} r={50} className="circle red one" />
          <circle cx={150} cy={50} r={50} className="circle blue two" />
          {/* These circles will be placed above the previous on second pass. */}
          <circle cx={150} cy={50} r={50} className="circle clear four" />
          <circle cx={50} cy={50} r={50} className="circle clear three" />
        </svg>
      </div>
    </>

  )
}

export default GlobalSetting
